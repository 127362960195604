import { ALL_COMPLAINTS } from "redux/constants";

const initialState = { allComplaints: [] };

const allComplaintsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_COMPLAINTS.STORE: {
      const { payload } = action;
      return { ...state, allComplaints: payload };
    }

    case ALL_COMPLAINTS.CHANGE_STATUS: {
      const { id, status } = action.payload;
      console.log("action", action.payload);
      const currentAdmin = state.allComplaints.find((admin) => admin._id === id);
      currentAdmin.status = status;
      return { ...state };
    }

    case ALL_COMPLAINTS.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allComplaints.findIndex(
        (user) => user._id === payload._id
      );
      state.allComplaints.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_COMPLAINTS.ADD_NEW: {
      const { payload } = action;

      return { ...state, allComplaints: [payload, ...state.allComplaints] };
    }

    default:
      return state;
  }
};

export default allComplaintsReducer;
