import { REGISTRATIONS } from "redux/constants";

const initialState = { allRegistrations: [] };

const registrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATIONS.STORE: {
      const { payload } = action;
      return { ...state, allRegistrations: payload.reverse() };
    }
    case REGISTRATIONS.APPROVED: {
      const { user } = action.payload;

      const index = state.allRegistrations.findIndex(
        (registration) => registration._id === user._id
      );
      if (index >= 0) {
        var us = state.allRegistrations.splice(index, 1);
      }
      console.log("Splicng index", index, us);
      return { ...state, allRegistrations: [...state.allRegistrations] };
    }
    default:
      return state;
  }
};

export default registrationsReducer;
