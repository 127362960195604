import { useEffect, useState } from "react";
import { Row, Col, message, Skeleton } from "antd";
import styled from "styled-components";
import { ReactComponent as DollarIcon } from "images/dollar.svg";
import { ReactComponent as InvoiceIcon } from "images/invoice.svg";
import { ReactComponent as UsersIcon } from "images/users.svg";
import FacebookIcon from "images/markting-dashboard/facebook-logo.png";
import LinkedInIcon from "images/markting-dashboard/linkedin-logo.png";
import WhatsappIcon from "images/markting-dashboard/whatsapp-logo.png";
import TwitterIcon from "images/markting-dashboard/twitter-logo.png";
import InstagramIcon from "images/markting-dashboard/instagram-logo.png";
import OthersIcon from "images/markting-dashboard/others-logo.png";
import LeadIcon from "images/lead.png";
import DemoIcon from "images/demo.png";
import SwitchableRangePicker from "components/input/SwitchableRangePicker";

const initialState = {
  totalLeads: [],
  facebookLeads: 0,
  linkedInLeads: 0,
  instagramLeads: 0,
  twitterLeads: 0,
  whatsappLeads: 0,
  othersLeads: 0,
};

const MarketingDashboardCard = (props) => {
  const {
    statsData,
    isLoading,
    switchableRangePickerState,
    setSelectedDate,
    selectedDate,
    type,
    setType,
  } = props;
  const [stats, setStats] = useState({ ...initialState });

  const statisticData = [
    {
      title: "Total Leads",
      key: stats.totalLeads,
      bgColor: "#c0ddff",
      iconColor: "#60d1e0",
      data: 5,
      icon: LeadIcon,
      span: 24,
    },
    {
      title: "Facebook",
      key: stats.facebookLeads,
      bgColor: "#3b5999",
      iconColor: "#3b5999",
      data: 5,
      icon: FacebookIcon,
      span: 8,
      chart: true,
    },
    {
      title: "LinkedIn",
      key: stats.linkedInLeads,
      bgColor: "#dde6ff",
      iconColor: "#0073b1",
      data: 5,
      icon: LinkedInIcon,
      span: 8,
    },
    {
      title: "Whatsapp",
      key: stats.whatsappLeads,
      bgColor: "#ffe8e8",
      iconColor: "#43c553",
      data: 5,
      icon: WhatsappIcon,
      span: 8,
    },
    {
      title: "Twitter",
      key: stats.twitterLeads,
      bgColor: "#f9e4ff",
      iconColor: "#1da1f3",
      data: 5,
      icon: TwitterIcon,
      span: 8,
    },
    {
      title: "Instagram",
      key: stats.instagramLeads,
      bgColor: "#f9e4ff",
      iconColor: "#eb005c",
      data: 5,
      icon: InstagramIcon,
      span: 8,
    },
    {
      title: "Others",
      key: stats.othersLeads,
      bgColor: "#f9e4ff",
      iconColor: "#c25fff",
      data: 5,
      icon: OthersIcon,
      span: 8,
    },
  ];

  useEffect(() => {
    if (statsData) {
      setStats({
        ...stats,
        facebookLeads: statsData?.facebookLeads?.length,
        instagramLeads: statsData?.instagramLeads?.length,
        linkedInLeads: statsData?.linkedInLeads?.length,
        totalQuotes: statsData?.totalQuotes?.length,
        totalIndividualUsers: statsData?.totalIndividualUsers?.length,
        totalCompanies: statsData?.totalCompanies?.length,
        totalLeads: statsData.totalLeads?.length,
      });
    }
  }, [statsData]);

  return (
    <StyledCard>
      <Row justify="space-between" align="middle">
        <Col>
          <h2>Statistics</h2>
        </Col>
        <Col>
          {switchableRangePickerState ? (
            <SwitchableRangePicker
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              type={type}
              setType={setType}
            />
          ) : null}
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        {statisticData.map((item, index) => {
          return (
            <StaticWrapper
              key={index}
              span={item.span}
              bgColor={item.bgColor}
              style={{ width: "100%" }}
            >
              <Skeleton active loading={isLoading} className="skeleton-wrap">
                <Row
                  // gutter={[20, 40]}
                  align="middle"
                  className="staticwrapper"
                  style={{
                    // boxShadow: `0 2px 10px -6px ${item.bgColor}`,
                    margin: 18,
                    borderLeft: `4px solid ${item.iconColor}`,
                  }}
                >
                  <Col>
                    <div
                      className="card-image"
                      style={{ background: "#fff", border: `1px solid ${item.iconColor}` }}
                    >
                      <img src={item.icon} width="100%" />
                    </div>
                  </Col>
                  <Col>
                    <p style={{ fontWeight: 600, marginBottom: 0, fontSize: 18 }}>
                      {item.title}
                    </p>
                    <div>
                      <b style={{ color: item.iconColor, fontSize: 20 }}>{item.key}</b>
                    </div>
                  </Col>
                </Row>
              </Skeleton>
            </StaticWrapper>
          );
        })}
      </Row>
      {/* </>
      )} */}
    </StyledCard>
  );
};

export default MarketingDashboardCard;

const StyledCard = styled.div`
  background-color: #fff;
  padding: 16px 32px 32px 32px;
  border-radius: 12px;
  box-shadow: 1px 1px solid red;

  .statsics {
    background-color: #d4f4e2;
    color: #28c76f;
    margin-right: 8px;
    padding: 2px 8px;
    border-radius: 4px;
  }

  .card-image {
    padding: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 50%;
    background-color: #d4f4e2;
    margin-right: 12px;
  }

  .skeleton-wrap {
    padding: 20px;
    /* width: 25px; */
    /* height: 60px; */
    .ant-skeleton-content {
      .ant-skeleton-title {
        /* width: 250px !important; */
        height: 85px;
        width: 100% !important;
        border-radius: 8px;
      }
      ul {
        display: none;
      }
    }
  }
`;

const StaticWrapper = styled(Col)`
  .staticwrapper {
    border: 1px solid rgb(218 218 253 / 65%);
    /* box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%); */
    border-radius: 8px;
    padding: 25px 12px;
    /* background-color: ${(props) => props.bgColor}; */
    background-color: #fff;
  }
`;
