import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col } from "antd";
import { REGISTRATIONS, SUB_ADMIN } from "redux/constants";
import { CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SelectWrapper, SwitchWrapper } from "components/input";
import CreateUserModal from "components/modals/createUserModal";
import { Switch } from "react-router-dom";
import SubAdminDetailModal from "components/modals/subAdminDetailModal";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const SubAdmins = () => {
  const dispatch = useDispatch();
  const registration = useRef();
  const filterRef = useRef(2);
  const user = useRef();
  const subAdminRef = useRef({});

  const { allSubAdmins } = useSelector((state) => state.subAdmins);

  const [detailModal, toggleDetailModal] = useState(false);
  const [registrationModal, toggleRegistrationModal] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [confimationDeleteModal, setConfimationDeleteModal] = useState({
    visible: false,
    userId: "",
  });

  const { mutateAsync: getAllsubAdmins, isLoading: getAllSubAdminLoading } = useMutation(
    Queries.getAllsubAdmins
  );

  const { mutateAsync: deleteRegistration, isLoading: deleteRegistrationLoading } = useMutation(
    Mutations.deleteRegistration
  );

  const handleDeleteRegistration = async () => {
    try {
      const { data } = await deleteRegistration({
        userId: confimationDeleteModal.userId,
      });
      setConfimationDeleteModal({ visible: false });
      dispatch({ type: REGISTRATIONS.STORE, payload: data.data });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const { mutateAsync: updateSubAdminStatus, isLoading: changeStatusLoading } = useMutation(
    Mutations.updateSubAdminStatus
  );

  const changeSubAdminStatus = async (status, adminId) => {
    try {
      await updateSubAdminStatus({ adminId, status });
      dispatch({ type: SUB_ADMIN.CHANGE_STATUS, payload: { adminId, status } });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    getAllsubAdmins()
      .then(({ data }) => {
        dispatch({ type: SUB_ADMIN.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  const getUserRole = (userRole) => {
    let userRoles = [
      { label: "Admin", value: 1 },
      { label: "Manager", value: 2 },
      { label: "Sales", value: 3 },
      { label: "Marketing", value: 4 },
    ].find((i) => i.value == userRole);
    return userRoles?.label;
  };

  return (
    <StyledTable>
      <Modal
        visible={confimationDeleteModal.visible}
        title={`Are you sure?`}
        footer={
          <ButtonWrapper
            style={{ width: "50%" }}
            onClick={handleDeleteRegistration}
            loading={deleteRegistrationLoading}
          >
            Delete
          </ButtonWrapper>
        }
        onCancel={() => setConfimationDeleteModal({ visible: false, userRole: null })}
      >
        <div style={{ display: "grid", textAlign: "center" }}>
          <ExclamationCircleOutlined
            style={{ fontSize: 54, color: "#ffbe04", marginBottom: 22 }}
          />
          <p style={{ margin: 0 }}>
            Are you sure you want to delete the registeration of this{" "}
            {filterRef.current == 1 ? "user" : "company"}
          </p>
        </div>
      </Modal>
      {createUserModal && (
        <CreateUserModal
          title="Create Sub-Admin"
          visible={createUserModal}
          modalState={createUserModal}
          onCancel={() => setCreateUserModal(false)}
        />
      )}

      <SubAdminDetailModal
        title="Sub Admin Details"
        visible={detailModal}
        onCancel={() => {
          toggleDetailModal(false);
        }}
        data={user.current}
        subAdmins={true}
      />

      <div>
        {/* <button onClick={buttonClick} className="button">
          Hello world.
        </button> */}
        <ContentHeader
          title="Sub Admins"
          tooltip="Add Sub Admin"
          onAdd={() => setCreateUserModal(true)}
        />
        <TableWrapper
          tableData={allSubAdmins}
          loading={getAllSubAdminLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone Number",
              accessor: "phoneNumber",
              sortable: false,
            },
            {
              Header: "Role",
              Cell: ({ original }) => <div>{getUserRole(original.userRole)}</div>,
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      user.current = original;
                      toggleDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Actions",
              Cell: ({ original }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SwitchWrapper
                    key={original._id}
                    loading={subAdminRef.current === original._id && changeStatusLoading}
                    checked={original.status === 1 ? true : false}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      const status = e ? 1 : 2;
                      subAdminRef.current = original._id;
                      changeSubAdminStatus(status, original._id);
                    }}
                  />
                  {original.status === 1 ? "Active" : "In-Active"}
                </div>
              ),
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default SubAdmins;
