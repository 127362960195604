export const Admin = {
  LOGIN: "LOGIN_AS_ADMIN",
  LOGOUT: "LOGOUT_ADMIN",
  UPDATE: "UPDATE_ADMIN_INFO",
};

export const REGISTRATIONS = {
  STORE: "STORE_ALL_REGISTRATIONS",
  APPROVED: "APPROVED_REGISTRATION",
};

export const USER = {
  STORE: "STORE_USER_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_USER_STATUS",
  SELECT_ALL: "SELECT_ALL_USERS_IN_CURRENT_PAGE",
  SELECT_ONE: "SELECT_ONE_USER",
  ADD_NEW: "ADD_NEW_USER_TO_REDUCER",
};

export const SUB_ADMIN = {
  STORE: "STORE_SUB_ADMIN_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_SUB_ADMIN_STATUS",
  ADD_NEW: "ADD_NEW_SUB_ADMIN",
  EDIT_DATA: "EDIT_SUB_ADMIN_DATA",
};

export const ALL_INQUIRY = {
  STORE: "STORE_INQUIRY_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_INQUIRY_STATUS",
  ADD_NEW: "ADD_NEW_INQUIRY",
  EDIT_DATA: "EDIT_INQUIRY_DATA",
};

export const ALL_FUTURE_INQUIRY = {
  STORE: "STORE_FUTURE_INQUIRY_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_FUTURE_INQUIRY_STATUS",
  ADD_NEW: "ADD_NEW_FUTURE_INQUIRY",
  EDIT_DATA: "EDIT_FUTURE_INQUIRY_DATA",
};

export const ALL_LEADS = {
  STORE: "STORE_LEADS_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_LEADS_STATUS",
  ADD_NEW: "ADD_NEW_LEADS",
  EDIT_DATA: "EDIT_LEADS_DATA",
};

export const ALL_DEMOS = {
  STORE: "STORE_DEMOS_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_DEMOS_STATUS",
  ADD_NEW: "ADD_NEW_DEMOS",
  EDIT_DATA: "EDIT_DEMOS_DATA",
};

export const ALL_SALES = {
  STORE: "STORE_SALES_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_SALES_STATUS",
  ADD_NEW: "ADD_NEW_SALES",
  EDIT_DATA: "EDIT_SALES_DATA",
};

export const ALL_MARKETING = {
  STORE: "STORE_MARKETING_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_MARKETING_STATUS",
  ADD_NEW: "ADD_NEW_MARKETING",
  EDIT_DATA: "EDIT_MARKETING_DATA",
};

export const ALL_QUOTES = {
  STORE: "STORE_QUOTES_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_QUOTES_STATUS",
  ADD_NEW: "ADD_NEW_QUOTES",
  EDIT_DATA: "EDIT_QUOTES_DATA",
};

export const USER_LOGS = {
  STORE: "STORE_USER_LOGS_IN_REDUCER",
};

export const INQUIRY_IN_MANAGER = {
  STORE: "STORE_INQUIRY_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_INQUIRY_STATUS",
  ADD_NEW: "ADD_NEW_INQUIRY",
  EDIT_DATA: "EDIT_INQUIRY_DATA",
};

export const ALL_RESALE = {
  STORE: "STORE_RESALE_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_RESALE_STATUS",
  ADD_NEW: "ADD_NEW_RESALE",
  EDIT_DATA: "EDIT_RESALE_DATA",
};

export const ALL_COMPLAINTS = {
  STORE: "STORE_COMPLAINTS_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_COMPLAINTS_STATUS",
  ADD_NEW: "ADD_NEW_COMPLAINTS",
  EDIT_DATA: "EDIT_COMPLAINTS_DATA",
};

export const ALL_PACKAGES = {
  STORE: "STORE_PACKAGES_DATA_IN_REDUCER",
  CHANGE_STATUS: "CHANGE_PACKAGES_STATUS",
  ADD_NEW: "ADD_NEW_PACKAGES",
  EDIT_DATA: "EDIT_PACKAGES_DATA",
};
