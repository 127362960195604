import { useEffect, useRef, useState } from "react";
import { Row, Col, Divider, Modal, message, Image, Button, Select, Form, Tooltip } from "antd";
import moment from "moment";
import { Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { IoCopyOutline } from "react-icons/io5";
import { FiCheck, FiEdit } from "react-icons/fi";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper } from "components/input";
import { Mutations } from "apis/mutations";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { ALL_LEADS, ALL_QUOTES, ALL_RESALE } from "redux/constants";
import { errorHandler } from "helpers/errorHandler";
import { HiCake, HiCheck } from "react-icons/hi";
import { BiCopy } from "react-icons/bi";

const { TabPane } = Tabs;

const initialState = {
  address: "-",
  country: "-",
  city: "-",
  gender: "-",
  companyName: "-",
  trainingType: "-",
  userType: "",
};

const quotesInitialState = {
  totalUserLicense: 0,
  totalInstructorLicense: 0,
  amount: 0,
  paymentLink: "",
  reason: "",
};

const DemoDetailModal = (props) => {
  const inputRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [editAbleFields, setEditAbleFields] = useState(initialState);
  const [quotesStates, setQuotesStates] = useState(quotesInitialState);
  const { title, visible, onCancel, data, copyLink, updateBtnHide, demoDetails, updateResale } =
    props;
  const [editMode, toggleEditMode] = useState(false);

  // ============UPDATE QUOTES API===================
  const { mutateAsync: updateQuotesMutate, isLoading: updateQuotesLoading } = useMutation(
    Mutations.updateQuote
  );
  const updateQuotes = async () => {
    try {
      let getResponse = await updateQuotesMutate({
        totalUserLicense: parseInt(quotesStates.totalUserLicense),
        totalInstructorLicense: parseInt(quotesStates.totalInstructorLicense),
        amount: parseInt(quotesStates.amount),
        quoteId: data.quotes.quoteId,
      });
      dispatch({ type: ALL_QUOTES.EDIT_DATA, payload: getResponse.data.data });
      message.success("Updated successfully");
      onCancel();
    } catch (err) {
      console.log("err", err);
      message.error(errorHandler(err));
    }
  };

  // ============UPDATE RESALE API===================
  const { isLoading: updateResaleByIdLoad, mutateAsync: updateResaleApi } = useMutation(
    Mutations.updateResaleById
  );

  const handleUpdateResaleById = async () => {
    try {
      let getResponse = await updateResaleApi({
        totalUserLicense: parseInt(quotesStates.totalUserLicense),
        totalInstructorLicense: parseInt(quotesStates.totalInstructorLicense),
        amount: parseInt(quotesStates.amount),
        resaleId: data.quotes.resaleId,
      });
      dispatch({ type: ALL_RESALE.EDIT_DATA, payload: getResponse.data.data });
      message.success("Updated Successfully");
      onCancel();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    if (visible) {
      toggleEditMode("");
      setEditAbleFields({
        address: data.address,
        country: data.country,
        city: data.city,
        gender: data.gender,
        companyName: data.companyName,
        companyEmail: data.companyEmail,
        companyWebsite: data.companyWebsite,
        companyPhone: data.companyPhone,
        trainingType: data.trainingType,
        userType: data.userType,
      });
      if (data.quotes) {
        setQuotesStates({
          totalUserLicense: data.quotes.totalUserLicense,
          totalInstructorLicense: data.quotes.totalInstructorLicense,
          amount: data.quotes.amount,
          paymentLink: data.quotes.paymentLink,
          reason: data.quotes.reason,
        });
      }
    }
  }, [visible]);

  const userKey = [
    { key: "fullName", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "phoneNumber", label: "Phone" },
    { key: "address", label: "Address", editAble: true },
    { key: "trainingType", label: "Training Type", editAble: true },
    { key: "userType", label: "Lead Type", editAble: true },
    { key: "companyName", label: "Company Name", editAble: true },
    { key: "companyEmail", label: "Company Email", editAble: true },
    { key: "companyPhone", label: "Company Phone", editAble: true },
    { key: "companyWebsite", label: "Company Website (URL)", editAble: true },
    { key: "gender", label: "Gender", editAble: true },
    { key: "country", label: "Country", editAble: true },
    { key: "city", label: "City", editAble: true },
    { key: "message", label: "Message" },
    { key: "demoFrom", label: "Lead Created From" },
    { key: "createdAt", label: "Created On" },
    {
      key: "reason",
      label: `Reason (${data.status == 1 ? "Approved" : "Not Approved"})`,
    },
  ];
  const quotesKey =
    data?.userType == "Company/Travel Agent"
      ? [
          { key: "totalUserLicense", label: "Total User License", editAble: true },
          { key: "totalInstructorLicense", label: "Total Instructor License", editAble: true },
          { key: "amount", label: "Amount", editAble: true },
          { key: "reason", label: "Reason" },
          { key: "paymentLink", label: "Payment Link" },
        ]
      : [
          { key: "amount", label: "Amount", editAble: true },
          { key: "reason", label: "Reason" },
          { key: "paymentLink", label: "Payment Link" },
        ];

  const keyList = userKey;

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={props.width ? props.width : 650}
      >
        <Row justify="space-between" gutter={[16, 0]}>
          <Tabs defaultActiveKey="1" type="card" size={"large"} centered>
            <TabPane tab={`Profile`} key="1">
              <Col span={24}>
                <Row>
                  {keyList.map((item) =>
                    (data.status == 0 && item.key == "reason") ||
                    (editAbleFields.userType == "Individual User" &&
                      (item.key == "companyName" ||
                        item.key == "companyEmail" ||
                        item.key == "companyWebsite" ||
                        item.key == "companyPhone")) ? null : (
                      <Col key={item.key} span={24}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div className="label" style={{ width: "50%" }}>
                            {item.label}:
                          </div>
                          <div className="values" style={{ width: "100%" }}>
                            {item.key == "createdAt" ? (
                              moment(data[item.key]).format("DD MMMM YYYY")
                            ) : item.key == "demoFrom" ? (
                              data["demoFrom"]?.demoCreatedBy
                            ) : item.key == "trainingType" ? (
                              <Select
                                bordered={false}
                                options={[
                                  { label: "Hajj", value: "Hajj" },
                                  { label: "Umrah", value: "Umrah" },
                                  { label: "Both", value: "Both" },
                                ]}
                                ref={inputRef}
                                defaultValue={data[item.key]}
                                style={{
                                  padding: 0,
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  height: 26,
                                  width: "100%",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e,
                                  })
                                }
                              />
                            ) : item.editAble == true ? (
                              <input
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              data[item?.key]
                            )}
                          </div>
                          {/* {item.editAble && data.status == 0 ? (
                          <div style={{ margin: "4px 0 0 auto" }}>
                            {editMode == item.key ? (
                              <FiCheck
                                style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                                onClick={() => toggleEditMode("")}
                              />
                            ) : (
                              <FiEdit
                                style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                                onClick={() => toggleEditMode(item.key)}
                              />
                            )}
                          </div>
                        ) : null} */}
                        </div>
                        <Divider style={{ margin: "2px 0" }} />
                      </Col>
                    )
                  )}
                </Row>
              </Col>
            </TabPane>

            {demoDetails ? (
              <TabPane tab={`Demo Details`} key="2">
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: "50%" }}>
                          Demo Client Feedback
                        </div>
                        <div className="values" style={{ width: "100%" }}>
                          {data?.demoDetails?.demoClientFeedback}
                        </div>
                      </div>

                      <Divider style={{ margin: "2px 0" }} />
                    </Col>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: "50%" }}>
                          Demo Date
                        </div>
                        <div className="values" style={{ width: "100%" }}>
                          {data?.demoDetails?.demoDate == ""
                            ? ""
                            : moment(data?.demoDetails?.demoDate).format("DD MMM YYYY")}
                        </div>
                      </div>
                      <Divider style={{ margin: "2px 0" }} />
                    </Col>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: "50%" }}>
                          Demo Duration
                        </div>
                        <div className="values" style={{ width: "100%" }}>
                          {data?.demoDetails?.demoDuration}
                        </div>
                      </div>
                      <Divider style={{ margin: "2px 0" }} />
                    </Col>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: "50%" }}>
                          Demo Link
                        </div>
                        <div className="values" style={{ width: "100%" }}>
                          {data?.demoDetails?.demoLink}
                        </div>
                      </div>
                      <Divider style={{ margin: "2px 0" }} />
                    </Col>

                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: "35%" }}>
                          Demo Reference Images
                        </div>
                        <Image.PreviewGroup>
                          {data.demoDetails?.demoReferenceImages?.map((item) => {
                            return (
                              <div style={{ margin: "10px 7px", height: 75, width: 75 }}>
                                <Image
                                  width={"100%"}
                                  height={"100%"}
                                  src={item.url}
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                            );
                          })}
                        </Image.PreviewGroup>
                      </div>
                    </Col>

                    {data.status == 0 ? (
                      <Col offset={16} span={8} style={{ marginTop: 10 }}>
                        <ButtonWrapper
                          disabled={props?.loading}
                          loading={props?.loading}
                          onClick={() => {
                            props.setDemoRef(data);
                            props.setDemoUpdateModal({ visible: true });
                          }}
                        >
                          Edit
                        </ButtonWrapper>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </TabPane>
            ) : null}

            {props.quotes ? (
              <TabPane tab={`Quotation`} key="3">
                <Col span={24}>
                  <Row>
                    {quotesKey.map((item) =>
                      data.status == 0 && item.key == "reason" ? null : (
                        <Col key={item.key} span={24}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <div className="label" style={{ width: "50%" }}>
                              {item.label}:
                            </div>
                            <div className="values" style={{ width: "100%" }}>
                              {item.editAble == true ? (
                                <span>
                                  {item.key == "amount" ? <span>$</span> : null}
                                  <input
                                    type={"number"}
                                    ref={inputRef}
                                    value={quotesStates[item.key]}
                                    style={{
                                      boxShadow: "none",
                                      border: "none",
                                      outline: "none",
                                      width: "98%",
                                      backgroundColor: "#fff",
                                    }}
                                    prefix={"$"}
                                    disabled={editMode == item.key ? false : true}
                                    maxLength={15}
                                    onChange={(e) =>
                                      setQuotesStates({
                                        ...quotesStates,
                                        [editMode]: e.target.value,
                                      })
                                    }
                                  />
                                </span>
                              ) : item.key == "paymentLink" ? (
                                <a
                                  href={quotesStates[item?.key]}
                                  target="_blank"
                                  style={{ color: "#135bed" }}
                                >
                                  {" "}
                                  {quotesStates[item?.key]}
                                </a>
                              ) : (
                                quotesStates[item?.key]
                              )}
                            </div>
                            {item.editAble &&
                            (data.quoteStatus == 0 || data.quoteStatus == 1) &&
                            data.paymentStatus == "pending" ? (
                              <div style={{ margin: "4px 0 0 auto" }}>
                                {editMode == item.key ? (
                                  <FiCheck
                                    style={{
                                      fontSize: 16,
                                      color: "#135bed",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => toggleEditMode("")}
                                  />
                                ) : (
                                  <FiEdit
                                    style={{
                                      fontSize: 16,
                                      color: "#135bed",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => toggleEditMode(item.key)}
                                  />
                                )}
                              </div>
                            ) : item.key == "paymentLink" ? (
                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => props?.copyToClipBoard(quotesStates.paymentLink)}
                              >
                                {copyLink ? (
                                  <Tooltip
                                    defaultOpen={true}
                                    mouseLeaveDelay={1.75}
                                    placement="right"
                                    title={"Copied"}
                                  >
                                    <HiCheck color="green" size={18} />
                                  </Tooltip>
                                ) : (
                                  <Tooltip placement="right" title={"Click to Copy"}>
                                    <BiCopy color="#135bed" size={18} />
                                  </Tooltip>
                                )}
                              </div>
                            ) : null}
                          </div>
                          <Divider style={{ margin: "2px 0" }} />
                        </Col>
                      )
                    )}
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: "35%" }}>
                          Payment Screenshot:
                        </div>
                        <Image.PreviewGroup>
                          {data.quotes?.paymentScreenshot?.map((item) => {
                            return (
                              <div style={{ margin: "10px 7px", height: 75, width: 75 }}>
                                <Image
                                  width={"100%"}
                                  height={"100%"}
                                  src={item.url}
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                            );
                          })}
                        </Image.PreviewGroup>
                      </div>
                    </Col>
                    {(data.quoteStatus == 0 || data.quoteStatus == 1) &&
                    data.paymentStatus == "pending" ? (
                      <Col offset={16} span={8} style={{ marginTop: 10 }}>
                        <ButtonWrapper
                          disabled={props.loading}
                          loading={updateQuotesLoading || updateResaleByIdLoad}
                          onClick={() => {
                            if (updateResale) {
                              handleUpdateResaleById();
                            } else {
                              updateQuotes();
                            }
                          }}
                        >
                          Update
                        </ButtonWrapper>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </TabPane>
            ) : null}
          </Tabs>
        </Row>
      </Modal>
    </>
  );
};

DemoDetailModal.defaultProps = {
  data: {},
  showForm: false,
};

export default DemoDetailModal;
