import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import allUsersReducer from "./allUsersReducer";
import subAdminReducer from "./subAdminsReducer";
import registrationsReducer from "./registrationsReducer";
import allInquiriesReducer from "./allInquiriesReducer";
import allLeadsReducer from "./allLeadsReducer";
import allDemosReducer from "./allDemosReducer";
import allQuotesReducer from "./allQuotesReducer";
import allSalesReducer from "./allSalesReducer";
import inquiriesInmanagerReducer from "./inquiriesInmanagerReducer";
import allResaleReducer from "./allResaleReducer";
import allComplaintsReducer from "./allComplaintsReducer";
import allMarketingReducer from "./allMarketingReducer";
import notificationCountReducer from "./notificationCountReducer";
import allFutureOpporInquiriesReducer from "./allFutureOpporInquiriesReducer";
import allPackagesReducer from "./allPackagesReducer";

export default combineReducers({
  admin: adminReducer,
  registrations: registrationsReducer,
  users: allUsersReducer,
  subAdmins: subAdminReducer,

  // sales
  inquiries: allInquiriesReducer,
  futureInquiries: allFutureOpporInquiriesReducer,
  leads: allLeadsReducer,
  demos: allDemosReducer,
  quotes: allQuotesReducer,
  sales: allSalesReducer,
  inquiryInManager: inquiriesInmanagerReducer,
  resale: allResaleReducer,
  complaints: allComplaintsReducer,
  marketing: allMarketingReducer,
  notificationCounter: notificationCountReducer,
  packages: allPackagesReducer,
});
