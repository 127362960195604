import { useRef, useState } from "react";
import { InputWrapper } from "components/input";
import { Modal, message } from "antd";
import { errorHandler } from "apis/config";
import { ButtonWrapper } from "components/buttons";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = { file: "", title: "", fileType: "" };

const UploadAppModal = (props) => {
  const { title, visible, onCancel, company, onComplete, fileData } = props;
  const inputRef = useRef();
  const [state, setState] = useState({ ...initialState });
  const [loading, toggleLoading] = useState(false);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", state.file);
    formData.append("title", state.title);
    formData.append("fileType", fileData.fileType);
    formData.append("companyAssociated", company._id);
    toggleLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_ADMIN_URL}/api/admin/uploadApplication`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data", Authorization: Cookies.get("token") },
    })
      .then(({ data }) => {
        const myApp = data.data[data.data.length - 1];
        onComplete(company._id, {
          ...company,
          [fileData.type]: myApp.filePath,
          [fileData.id]: myApp._id,
        });
        closeModal();
      })
      .catch(function (error) {
        console.log("Error: \n", error);
        message.error(errorHandler(error));
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files && files[0]) {
      setState({ ...state, file: files[0] });
    }
  };

  const closeModal = () => {
    onCancel();
    setState({ ...initialState });
    inputRef.current.value = null;
  };
  return (
    <Modal
      title={title}
      visible={visible}
      footer={null}
      onCancel={() => {
        if (!loading) {
          closeModal();
        }
      }}
      closable={false}
    >
      <InputWrapper
        placeholder="Title"
        value={state.title}
        onChange={(e) => setState({ ...state, title: e.target.value })}
      />

      <input
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        style={{ margin: "16px 0" }}
      />

      <ButtonWrapper loading={loading} onClick={handleSubmit}>
        Upload
      </ButtonWrapper>
    </Modal>
  );
};

export default UploadAppModal;
