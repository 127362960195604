import Cookies from "js-cookie";
import { Admin } from "../constants";

const initialState = {
  isLoggedIn:
    Cookies.get("token") !== null &&
    Cookies.get("token") !== undefined &&
    Cookies.get("token") !== "",
  data: { userRole: 0, firstName: "", lastName: "", phoneNo: "", email: "" },
  userRole: 0,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case Admin.LOGIN: {
      const { payload } = action;
      return { ...state, ...payload, isLoggedIn: true };
    }

    case Admin.LOGOUT: {
      // window.location.pathname = "/";
      return { data: { userRole: 0, firstName: "", lastName: "", phoneNo: "", email: "" } };
    }

    case Admin.UPDATE: {
      const { payload } = action;
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

export default adminReducer;
