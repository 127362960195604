import { useEffect, useState } from "react";
import { Row, Col, message, Skeleton } from "antd";
import styled from "styled-components";
import { ReactComponent as DollarIcon } from "images/dollar.svg";
import { ReactComponent as InvoiceIcon } from "images/invoice.svg";
import { ReactComponent as UsersIcon } from "images/users.svg";
import InquiryIcon from "images/inquiry.png";
import LeadIcon from "images/lead.png";
import DemoIcon from "images/demo.png";
import QuotationIcon from "images/quote.png";
import TravelAgentIcon from "images/travelAgent-65C18C.png";
import IndividualUserIcon from "images/individualUser-7793AF.png";
import LicenseIcon from "images/license.png";
import AmountIcon from "images/amount.png";

import { VscOrganization } from "react-icons/vsc";
import Icon, { ArrowDownOutlined, UserAddOutlined } from "@ant-design/icons";
import { Queries, useMutation, errorHandler } from "apis/config";
import ColumnPlot from "components/charts/columnPlot";
import SwitchableRangePicker from "components/input/SwitchableRangePicker";

const initialState = {
  totalInquiries: [],
  totalLeads: [],
  totalDemos: [],
  totalQuotes: [],
  totalIndividualUsers: [],
  totalTravelAgents: [],
  totalUserLicense: 0,
  totalPayments: 0,
};

const SaleDashboardCard = (props) => {
  const {
    statsData,
    isLoading,
    switchableRangePickerState,
    setSelectedDate,
    selectedDate,
    type,
    setType,
  } = props;
  const [stats, setStats] = useState({ ...initialState });

  const statisticData = [
    {
      title: "Total License",
      key: stats.totalUserLicense,
      bgColor: "#c0ddff",
      iconColor: "#60d1e0",
      data: 5,
      icon: LicenseIcon,
      span: 12,
    },
    {
      title: "Total Amount",
      key: stats.totalPayments,
      bgColor: "#dfdfdf",
      iconColor: "#f19c1e",
      data: 5,
      icon: AmountIcon,
      span: 12,
    },
    {
      title: "Inquiries",
      key: stats.totalInquiries,
      bgColor: "#f9f1ea",
      iconColor: "#4E31AA",
      data: 5,
      icon: InquiryIcon,
      span: 6,
      chart: true,
    },
    {
      title: "Leads",
      key: stats.totalLeads,
      bgColor: "#dde6ff",
      iconColor: "#fc5763",
      data: 5,
      icon: LeadIcon,
      span: 6,
    },
    {
      title: "Demos",
      key: stats.totalDemos,
      bgColor: "#ffe8e8",
      iconColor: "#A27B5C",
      data: 5,
      icon: DemoIcon,
      span: 6,
    },
    {
      title: "Quotations",
      key: stats.totalQuotes,
      bgColor: "#f9e4ff",
      iconColor: "#4E9F3D",
      data: 5,
      icon: QuotationIcon,
      span: 6,
    },
    // {
    //   title: "Individual Users",
    //   key: stats.totalIndividualUsers,
    //   bgColor: "#e1ecf7",
    //   iconColor: "#7793AF",
    //   data: 5,
    //   icon: IndividualUserIcon,
    // },
    // {
    //   title: "Travel Agents",
    //   key: stats.totalCompanies,
    //   bgColor: "#e6fff0",
    //   iconColor: "#65C18C",
    //   data: 5,
    //   icon: TravelAgentIcon,

    // },
  ];

  useEffect(() => {
    if (statsData) {
      setStats({
        ...stats,
        totalInquiries: statsData?.totalInquiries?.length,
        totalLeads: statsData?.totalLeads?.length,
        totalDemos: statsData?.totalDemos?.length,
        totalQuotes: statsData?.totalQuotes?.length,
        totalIndividualUsers: statsData?.totalIndividualUsers?.length,
        totalCompanies: statsData?.totalCompanies?.length,
        totalUserLicense: statsData?.totalUserLicense,
        totalPayments: `$${statsData?.totalPayments}`,
      });
    }
  }, [statsData]);

  return (
    <StyledCard>
      <Row justify="space-between" align="middle">
        <Col>
          <h2>Statistics</h2>
        </Col>
        <Col>
          {switchableRangePickerState ? (
            <SwitchableRangePicker
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              type={type}
              setType={setType}
            />
          ) : null}
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        {statisticData.map((item, index) => {
          return (
            <StaticWrapper
              key={index}
              span={item.span}
              bgColor={item.bgColor}
              style={{ width: "100%" }}
            >
              <Skeleton active loading={isLoading} className="skeleton-wrap">
                <Row
                  // gutter={[20, 40]}
                  align="middle"
                  className="staticwrapper"
                  style={{
                    // boxShadow: `0 2px 10px -6px ${item.bgColor}`,
                    margin: 18,
                    borderLeft: `4px solid ${item.iconColor}`,
                  }}
                >
                  <Col>
                    <div
                      className="card-image"
                      style={{ background: "#fff", border: `1px solid ${item.iconColor}` }}
                    >
                      <img src={item.icon} width="100%" />
                    </div>
                  </Col>
                  <Col>
                    <p style={{ fontWeight: 600, marginBottom: 0, fontSize: 18 }}>
                      {item.title}
                    </p>
                    <div>
                      <b style={{ color: item.iconColor, fontSize: 20 }}>{item.key}</b>
                    </div>
                  </Col>
                </Row>
              </Skeleton>
            </StaticWrapper>
          );
        })}
      </Row>
      {/* </>
      )} */}
    </StyledCard>
  );
};

export default SaleDashboardCard;

const StyledCard = styled.div`
  background-color: #fff;
  padding: 16px 32px 32px 32px;
  border-radius: 12px;
  box-shadow: 1px 1px solid red;

  .statsics {
    background-color: #d4f4e2;
    color: #28c76f;
    margin-right: 8px;
    padding: 2px 8px;
    border-radius: 4px;
  }

  .card-image {
    padding: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 50%;
    background-color: #d4f4e2;
    margin-right: 12px;
  }

  .skeleton-wrap {
    padding: 20px;
    /* width: 25px; */
    /* height: 60px; */
    .ant-skeleton-content {
      .ant-skeleton-title {
        /* width: 250px !important; */
        height: 85px;
        width: 100% !important;
        border-radius: 8px;
      }
      ul {
        display: none;
      }
    }
  }
`;

const StaticWrapper = styled(Col)`
  .staticwrapper {
    border: 1px solid rgb(218 218 253 / 65%);
    /* box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%); */
    border-radius: 8px;
    padding: 25px 12px;
    /* background-color: ${(props) => props.bgColor}; */
    background-color: #fff;
  }
`;
