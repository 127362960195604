import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, DatePicker } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper } from "components/input";
import { useMutation, Mutations, errorHandler, Queries } from "apis/config";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { REGISTRATIONS } from "redux/constants";
import { approveUser } from "redux/actions";
import moment from "moment";

const initialState = {
  saleId: "",
  month: "",
  leadTarget: 0,
  saleTarget: 0,
  paymentTarget: 0,
};

const KPIsetForMarketingModal = (props) => {
  const { title, visible, onCancel, data, closeParent } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  console.log(data);
  const [state, setState] = useState({ ...initialState });
  const [KPIDataBySaleId, setKPIDataBySaleId] = useState([]);

  const { isLoading, mutateAsync } = useMutation(Mutations.setKPI);

  const handleApprove = async () => {
    const params = {
      adminId: data._id,
      month: state.month,
      leadTarget: Number(state.leadTarget),
    };

    try {
      if (moment(state.month).format("YYYY-MM") >= moment().format("YYYY-MM")) {
        await mutateAsync(params);
        message.success("Updated successfully");
        onClose();
        closeParent();
      }
      // dispatch(approveUser(user));
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (KPIDataBySaleId) {
      let getFilteredData = KPIDataBySaleId?.target?.filter(
        (item) => item.month == state.month
      );
      if (getFilteredData?.length > 0) {
        const { leadTarget, saleTarget, paymentTarget, month } = getFilteredData[0];
        setState({
          ...state,
          leadTarget: leadTarget.toString(),
          month: month,
        });
        form.setFieldsValue({
          "Lead Target": leadTarget.toString(),
        });
      } else {
        setState({
          ...state,
          leadTarget: "",
          month: state.month,
        });
        form.setFieldsValue({
          "Lead Target": "",
        });
      }
    }
  }, [KPIDataBySaleId, state.month]);

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  const { mutateAsync: getKPIByAdminId, isLoading: getKPIBySaleIdLoading } = useMutation(
    Queries.getKPIByAdminId
  );

  useEffect(() => {
    setState({ ...state, month: moment().format("MMMM-YYYY") });
    // form.setFieldValue({
    //   Month: moment().format("MMMM-YYYY"),
    // });
    getKPIByAdminId({ adminId: data._id })
      .then(({ data }) => {
        setKPIDataBySaleId(data?.data);
      })
      .catch((err) => message.error(errorHandler(err)));
  }, [visible]);

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={400}
    >
      <Form form={form} onFinish={handleApprove} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={24}>
            <div className="label">Month</div>
            <Form.Item
              name="Month"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
              initialValue={moment(moment().format("MMMM-YYYY"), "MMMM-YYYY")}
            >
              <DatePicker
                style={{ width: "100%" }}
                name="month"
                size="large"
                format="MMMM-YYYY"
                value={state.month}
                defaultValue={moment(moment().format("MMMM-YYYY"), "MMMM-YYYY")}
                onChange={(e, date) =>
                  setState({ ...state, month: moment(date).format("MMMM-YYYY") })
                }
                picker="month"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Lead Target</div>
            <Form.Item
              name="Lead Target"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="leadTarget"
                placeholder={getKPIBySaleIdLoading ? "Loading..." : "Lead Target"}
                disabled={getKPIBySaleIdLoading}
                value={state.leadTarget}
                onChange={handleChange}
                type="number"
                min={1}
              />
            </Form.Item>
          </Col>

          {/* <Col span={24}>
            <div className="label">Sale Target</div>
            <Form.Item
              name="Sale Target"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="saleTarget"
                placeholder={getKPIBySaleIdLoading ? "Loading..." : "Sale Target"}
                disabled={getKPIBySaleIdLoading}
                value={state.saleTarget}
                onChange={handleChange}
                type="number"
                min={1}
              />
            </Form.Item>
          </Col> */}

          {/* <Col span={24}>
            <div className="label">Payment Target</div>
            <Form.Item
              name="Payment Target"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                prefix="$"
                name="paymentTarget"
                placeholder={getKPIBySaleIdLoading ? "Loading..." : "Payment Target"}
                value={state.paymentTarget}
                disabled={getKPIBySaleIdLoading}
                onChange={handleChange}
                type="number"
                min={1}
              />
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col span={12}></Col>
              <Col span={12}>
                <ButtonWrapper
                  htmlType="submit"
                  loading={isLoading}
                  disabled={
                    moment(state.month).format("YYYY-MM") < moment().format("YYYY-MM")
                      ? true
                      : false
                  }
                >
                  Update
                </ButtonWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default KPIsetForMarketingModal;

const StyledModal = styled(Modal)`
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
