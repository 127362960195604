import { ALL_MARKETING } from "redux/constants";

const initialState = { allMarketing: [] };

const allMarketingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_MARKETING.STORE: {
      const { payload } = action;
      return { ...state, allMarketing: payload };
    }

    case ALL_MARKETING.CHANGE_STATUS: {
      const { saleId, status } = action.payload;
      const currentAdmin = state.allMarketing.find((admin) => admin._id === saleId);
      currentAdmin.autoAssignTasks = status;
      return { ...state };
    }

    case ALL_MARKETING.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allMarketing.findIndex(
        (user) => user._id === payload._id
      );
      state.allMarketing.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_MARKETING.ADD_NEW: {
      const { payload } = action;

      return { ...state, allMarketing: [payload, ...state.allMarketing] };
    }

    default:
      return state;
  }
};

export default allMarketingReducer;
