import { useEffect, useRef, useState } from "react";
import { Row, Col, Divider, Modal, message } from "antd";
import moment from "moment";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const SubAdminDetailModal = (props) => {
  const { title, visible, onCancel, data } = props;
  const keyList = userKey;

  const getUserRole = (userRole) => {
    let userRoles = [
      { label: "Admin", value: 1 },
      { label: "Manager", value: 2 },
      { label: "Sales", value: 3 },
      { label: "Marketing", value: 4 },
    ].find((i) => i.value == userRole);
    return userRoles?.label;
  };

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        // width={showMore ? 900 : 600}
      >
        <Row justify="space-between" gutter={[16, 0]}>
          <Tabs defaultActiveKey="1" type="card" size={"large"} centered>
            <TabPane tab={`Profile`} key="1">
              <Col span={24}>
                <Row>
                  {keyList.map((item) => (
                    <Col key={item.key} span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: 190 }}>
                          {item.label}:
                        </div>
                        <div className="value">
                          {item.key === "userRole"
                            ? getUserRole(data[item.key])
                            : item.key == "createdAt"
                            ? moment(data[item.key]).format("DD MMMM YYYY")
                            : item.key == "time"
                            ? moment(data[item.key]).format("DD MMMM YYYY (hh:mm a)")
                            : item.key == "status"
                            ? data[item.key] == 1
                              ? "Active"
                              : "In-Active"
                            : data[item.key]}
                        </div>
                      </div>
                      <Divider style={{ margin: "2px 0" }} />
                    </Col>
                  ))}

                  <Col span={24} style={{ margin: "8px 0" }} />
                </Row>
              </Col>
            </TabPane>
          </Tabs>
        </Row>
      </Modal>
    </>
  );
};

SubAdminDetailModal.defaultProps = {
  data: {},
  showForm: false,
};

export default SubAdminDetailModal;

const userKey = [
  { key: "fullName", label: "Full Name" },
  { key: "email", label: "Email" },
  { key: "phoneNumber", label: "Phone" },
  { key: "userRole", label: "Admin Role (designation)" },
  { key: "createdAt", label: "Created On" },
  { key: "lastLogin", label: "Last Login" },
  { key: "status", label: "Status" },
];
