import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col, Tooltip, Form } from "antd";
import { ALL_QUOTES } from "redux/constants";
import styled from "styled-components";
import { HiCheck, HiX } from "react-icons/hi";
import DemoDetailModal from "components/modals/DemoDetailModal";
import { BiCopy } from "react-icons/bi";
import QuoteReasonModal from "components/modals/QuoteReasonModal";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .statsics {
    background-color: #fde5e9;
    color: #f55050;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
`;

const Quotes = () => {
  const filterRef = useRef(2);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allQuotes } = useSelector((state) => state.quotes);
  const { data } = useSelector((state) => state.admin);

  const [copyLink, setCopyLink] = useState(false);
  const [copyLinkState, setCopyLinkState] = useState("");
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [quoteRef, setQuoteRef] = useState("");
  const [leadDetailModal, toggleLeadDetailModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("viewAll");
  const [quoteUpdateModal, setquoteUpdateModal] = useState({
    visible: false,
    title: "",
  });

  const { mutateAsync: getAllQuotes, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllQuotes
  );

  const handleSearch = (val) => {
    const filteredOptions = allQuotes.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.country.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.gender.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  const closeModal = () => {
    setAddLinkModal(false);
    form.resetFields();
  };

  useEffect(() => {
    const filteredOptions = allQuotes.filter((option) => {
      if (option.status == filterByStatus) {
        return option;
      } else if (filterByStatus == "viewAll") {
        return allQuotes;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allQuotes);
  }, [allQuotes]);

  useEffect(() => {
    getAllQuotes()
      .then(({ data }) => {
        dispatch({ type: ALL_QUOTES.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
    dispatch({ type: "QUOTATION", payload: null });
  }, []);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopyLink(true);
      setTimeout(() => {
        setCopyLink(false);
      }, 2000);
    } catch (err) {
      message.error("Failed to copy!");
    }
  };

  const paymentStatus = (status) => {
    if (status == "succeeded") {
      return <span className="succeeded">{status}</span>;
    } else if (status == "pending") {
      return <span className="pending">{status}</span>;
    } else {
      return <span className="statsics">{status}</span>;
    }
  };

  return (
    <StyledTable>
      <DemoDetailModal
        quotes={true}
        demoDetails={true}
        updateResale={false}
        title="Quotation details"
        visible={leadDetailModal}
        onCancel={() => {
          toggleLeadDetailModal(false);
        }}
        data={quoteRef}
        showForm={true}
        copyToClipBoard={copyToClipBoard}
        copyLink={copyLink}
        width={750}
      />

      {quoteUpdateModal.visible && (
        <QuoteReasonModal
          title={`Reason for ${quoteUpdateModal.title}`}
          visible={quoteUpdateModal.visible}
          onCancel={() => {
            setquoteUpdateModal(false);
          }}
          quoteUpdateModal={quoteUpdateModal}
          data={quoteRef}
          showForm={true}
        />
      )}

      <div>
        <ContentHeader
          title="Quotes"
          count={allQuotes?.length}
          tooltip="Create your own Lead"
          onSearch={(e) => {
            handleSearch(e);
          }}
          onSelectChange={(e) => setFilterByStatus(e)}
          dropDownOptions={[
            { label: "View All", value: "viewAll" },
            { label: "Pending", value: 0 },
            { label: "Approved", value: 1 },
            { label: "Not Approved", value: 2 },
          ]}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.demoId.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.demoId.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              Cell: ({ original }) => <div>{original.demoId.phoneNumber}</div>,
              sortable: false,
            },

            {
              Header: "Total License",
              Cell: ({ original }) => (
                <div style={{ width: 75, textAlign: "center" }}>
                  {original.totalUserLicense}
                </div>
              ),
              sortable: false,
            },
            {
              Header: "Amount",
              Cell: ({ original }) => (
                <div style={{ width: 50, textAlign: "center" }}>${original.amount}</div>
              ),
              sortable: false,
            },
            {
              Header: "Payment Link",
              Cell: ({ original }) => (
                <div
                  style={{
                    width: 35,
                    height: 35,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (original.amount < 1) {
                      message.warning("Please set an amount first");
                    } else if (
                      original.totalUserLicense < 1 &&
                      original.userType == "Company/Travel Agent"
                    ) {
                      message.warning("Please assign licenses to this user first");
                    } else if (
                      original.totalInstructorLicense < 1 &&
                      original.userType == "Company/Travel Agent"
                    ) {
                      message.warning("Please assign licenses to this user first");
                    } else {
                      setCopyLinkState(original._id);
                      copyToClipBoard(original.paymentLink);
                    }
                  }}
                >
                  {copyLink && copyLinkState == original._id ? (
                    <Tooltip
                      defaultOpen={true}
                      mouseLeaveDelay={1.85}
                      placement="right"
                      title={"Copied"}
                    >
                      <HiCheck color="green" size={25} />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="right" title={"Click to Copy"}>
                      <BiCopy color="#135bed" size={25} />
                    </Tooltip>
                  )}
                </div>
              ),

              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      // quoteRef.current = original;
                      setQuoteRef({
                        ...original.demoId,
                        quoteStatus: original.status,
                        paymentStatus: original.paymentStatus,
                        quotes: {
                          amount: original.amount,
                          totalUserLicense: original.totalUserLicense,
                          totalInstructorLicense: original.totalInstructorLicense,
                          quoteId: original._id,
                          paymentLink: original.paymentLink,
                          paymentScreenshot: original.paymentScreenshot,
                          reason: original.reason,
                        },
                      });
                      toggleLeadDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Payment Status",
              Cell: ({ original }) => <div>{paymentStatus(original.paymentStatus)}</div>,
              sortable: false,
            },
            {
              Header: "Actions",
              Cell: ({ original }) => {
                return (
                  <div>
                    {original.status == 0 ? (
                      <div
                        style={{ width: 70, display: "flex", justifyContent: "space-between" }}
                      >
                        <div
                          className="delete-icon"
                          onClick={() => {
                            // quoteRef.current = original;
                            let { amount, totalUserLicense, totalInstructorLicense } = original;
                            if (
                              original.demoId.userType == "Company/Travel Agent" &&
                              (amount < 1 || totalUserLicense < 1 || totalInstructorLicense < 1)
                            ) {
                              message.warning(
                                "Please fill this candidate's information before approving"
                              );
                            } else if (
                              original.demoId.userType == "Individual User" &&
                              amount < 1
                            ) {
                              message.warning(
                                "Please fill this candidate's information before approving"
                              );
                            } else if (original.paymentStatus !== "succeeded") {
                              message.warning("Payment has not been made yet");
                            } else {
                              setQuoteRef(original);
                              setquoteUpdateModal({ visible: true, title: "Approving" });
                            }
                          }}
                        >
                          <Tooltip title="Approving" placement="bottom">
                            <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                          </Tooltip>
                        </div>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            // quoteRef.current = original;
                            setQuoteRef(original);
                            setquoteUpdateModal({ visible: true, title: "Not Approving" });
                          }}
                        >
                          <Tooltip title="Not Approving" placement="bottom">
                            <HiX style={{ fontSize: 20, color: "#f73838" }} />
                          </Tooltip>
                        </div>
                      </div>
                    ) : original.status == 1 ? (
                      <div>
                        <strong style={{ margin: 0, color: "#4fc06b" }}>Approved</strong>
                      </div>
                    ) : (
                      <div>
                        <strong style={{ margin: 0, color: "#f73838" }}>Not Approved</strong>
                      </div>
                    )}
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Quotes;
