import { Admin, USER, REGISTRATIONS } from "../constants";
import Cookies from "js-cookie";

export const loginAdminAction = (data) => (dispatch) => {
  const token = data.jwtToken?.jwtToken;
  const payload = {
    data: {
      userRole: data.userRole,
      fullName: `${data.firstName} ${data.lastName}`,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNo: data.phoneNumber,
      email: data.email,
      deviceId: data.deviceId,
      _id: data._id,
    },
    userRole: data.userRole,
  };

  Cookies.set("token", token);
  Cookies.set("_W_U_DATA_", JSON.stringify(payload.data), { expires: 60 });

  dispatch({ type: Admin.LOGIN, payload });
};

export const logoutAction = () => (dispatch) => {
  Cookies.remove("token");
  Cookies.remove("_W_U_DATA_");

  dispatch({ type: Admin.LOGOUT });
};

export const approveUser = (user) => (dispatch) => {
  dispatch({ type: REGISTRATIONS.APPROVED, payload: { user } });
  dispatch({ type: USER.ADD_NEW, payload: { user } });
};
