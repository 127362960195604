import { store } from "./redux/configStore";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "react-phone-number-input/style.css";
import "./App.scss";
import Routes from "./Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";

const query = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false,
      enabled: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={query}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
