import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col, Tooltip } from "antd";
import { ALL_INQUIRY, REGISTRATIONS } from "redux/constants";
import { CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import InquiryDetailModal from "components/modals/InquiryDetailModal";
import { HiCheck, HiX } from "react-icons/hi";
import CreateInquiryModal from "components/modals/createInquiryModal";
import InquiryReasonModal from "components/modals/inquiryReasonModal";
import { hanldeMessage } from "Routes";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const Inquiries = () => {
  const dispatch = useDispatch();
  const { allInquiries } = useSelector((state) => state.inquiries);
  const { data } = useSelector((state) => state.admin);

  const [createInquiryModal, setCreateInquiryModal] = useState(false);
  const [registrationModal, toggleRegistrationModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("viewAll");
  const [inquiryReasonModal, setInquiryReasonModal] = useState({
    visible: false,
    title: "",
  });

  const inquiriesRef = useRef();
  const filterRef = useRef(2);

  const { mutateAsync: getAllInquiries, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllInquiries
  );

  // ===============UPDATE INQUIRY===============
  const { isLoading, mutateAsync } = useMutation(Mutations.updateInquiry);
  const updateLeadsDetail = async (variables) => {
    console.log(variables);
    try {
      let getResponse = await mutateAsync(variables);
      dispatch({ type: ALL_INQUIRY.EDIT_DATA, payload: getResponse.data.data });
      message.success("Updated successfully");
      toggleRegistrationModal(false);
      setInquiryReasonModal(false);
      if (variables.status == 1) {
        let notificationData = {
          notification: {
            title: "Updated Successfully",
            body: "Please check the details in lead tab",
          },
          data: { notificationType: "Lead" },
        };
        hanldeMessage(notificationData, dispatch);
      }
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleSearch = (val) => {
    const filteredOptions = allInquiries.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  useEffect(() => {
    const filteredOptions = allInquiries.filter((option) => {
      if (option.status == filterByStatus || option.inquiryFrom.adminId == filterByStatus) {
        return option;
      } else if (filterByStatus == "viewAll") {
        return allInquiries;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allInquiries);
  }, [allInquiries]);

  useEffect(() => {
    getAllInquiries()
      .then(({ data }) => {
        dispatch({ type: ALL_INQUIRY.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
    dispatch({ type: "INQUIRY", payload: null });
  }, []);

  return (
    <StyledTable>
      <CreateInquiryModal
        title="Create your own Inquiry"
        visible={createInquiryModal}
        onCancel={() => {
          setCreateInquiryModal(false);
        }}
        data={inquiriesRef.current}
      />

      <InquiryDetailModal
        title="Inquiry Details"
        visible={registrationModal}
        updateDetail={updateLeadsDetail}
        isLoading={isLoading}
        onCancel={() => {
          toggleRegistrationModal(false);
        }}
        data={inquiriesRef.current}
        modalFrom={false}
      />

      {inquiryReasonModal.visible && (
        <InquiryReasonModal
          title={`Reason (${inquiryReasonModal.title})`}
          visible={inquiryReasonModal}
          updateDetail={updateLeadsDetail}
          isLoading={isLoading}
          onCancel={() => {
            setInquiryReasonModal(false);
          }}
          inquiryReasonModal={inquiryReasonModal}
          data={inquiriesRef.current}
          modalFrom={false}
        />
      )}

      <div>
        <ContentHeader
          title="Inquiries"
          count={allInquiries?.length}
          tooltip="Create your own Inquiry"
          onSearch={(e) => {
            handleSearch(e);
          }}
          onAdd={() => setCreateInquiryModal(true)}
          onSelectChange={(e) => setFilterByStatus(e)}
          dropDownOptions={[
            { label: "View All", value: "viewAll" },
            { label: "Pending", value: 0 },
            { label: "Interested", value: 1 },
            { label: "Not Interested", value: 2 },
            // { label: "My Inquiries", value: data._id },
          ]}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              accessor: "phoneNumber",
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      inquiriesRef.current = original;
                      toggleRegistrationModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Actions",
              Cell: ({ original }) => {
                return (
                  <div>
                    {original.status == 0 ? (
                      <div
                        style={{ width: 70, display: "flex", justifyContent: "space-between" }}
                      >
                        <div
                          className="delete-icon"
                          onClick={() => {
                            if(original.fullName !== "" && original.email !== "" && original.phoneNumber !== ""){
                              inquiriesRef.current = original;
                              setInquiryReasonModal({ visible: true, title: "Interested" });
                            }
                            else{
                              message.warn("Please fill this candidate's information before approving")
                            }
                          }}
                        >
                          <Tooltip title="Interested (Move to Leads)" placement="bottom">
                            <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                          </Tooltip>
                        </div>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            inquiriesRef.current = original;
                            setInquiryReasonModal({ visible: true, title: "Not Interested" });
                          }}
                        >
                          <Tooltip title="Not Interested" placement="bottom">
                            <HiX style={{ fontSize: 20, color: "#f73838" }} />
                          </Tooltip>
                        </div>
                      </div>
                    ) : original.status == 1 ? (
                      <div>
                        <strong style={{ margin: 0, color: "#4fc06b" }}>Interested</strong>
                      </div>
                    ) : (
                      <div>
                        <strong style={{ margin: 0, color: "#f73838" }}>Not Interested</strong>
                      </div>
                    )}
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Inquiries;
