import { USER, REGISTRATIONS } from "redux/constants";

const initialState = { allUsers: [], selectedUsers: [], selectedUsersId: [] };

const allUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER.STORE: {
      const { payload } = action;
      return { ...state, allUsers: payload.reverse() };
    }

    case USER.ADD_NEW: {
      const { user } = action.payload;
      return { ...state, allUsers: [user, ...state.allUsers] };
    }

    case USER.CHANGE_STATUS: {
      const { userId, status } = action.payload;
      const currentUser = state.allUsers.find((user) => user._id === userId);
      currentUser.status = status;
      return { ...state };
    }

    case USER.SELECT_ALL: {
      const { checked } = action.payload;
      if (checked) {
        state.allUsers.forEach((user) => {
          if (!state.selectedUsersId.includes(user._id)) {
            state.selectedUsers.push(user);
            state.selectedUsersId.push(user._id);
          }
        });
      } else {
        state.allUsers.forEach((user) => {
          const index = state.selectedUsersId.findIndex((userId) => userId === user._id);
          if (index >= 0) {
            state.selectedUsers.splice(index, 1);
            state.selectedUsersId.splice(index, 1);
          }
        });
      }

      const selectedUsers = state.selectedUsers;
      const selectedUsersId = state.selectedUsersId;

      return { ...state, selectedUsers, selectedUsersId };
    }

    case USER.SELECT_ONE: {
      const { user, checked } = action.payload;
      let selectedUsers, selectedUsersId;

      if (checked) {
        selectedUsers = state.selectedUsers.concat(user);
        selectedUsersId = state.selectedUsersId.concat(user._id);
      } else {
        const index = state.selectedUsersId.findIndex((id) => id === user._id);
        if (index >= 0) {
          state.selectedUsers.splice(index, 1);
          state.selectedUsersId.splice(index, 1);
        }
        selectedUsers = state.selectedUsers;
        selectedUsersId = state.selectedUsersId;
      }

      return { ...state, selectedUsers, selectedUsersId };
    }

    default:
      return state;
  }
};

export default allUsersReducer;
