import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import ChartContainer from "./chartContainer";
import moment from "moment";
import { Badge, Row, Col } from "antd";

const ColumnPlot = (props) => {
  const {
    heading,
    data,
    xField,
    yField,
    color,
    icon,
    sale,
    bgColor,
    dodgePadding,
    isGroup,
    monthStyle,
  } = props;

  const config = {
    month: monthStyle,
    data,
    color,
    xField,
    yField,
    dodgePadding,
    isGroup,
    height: 300,
    legend: false,
    maxColumnWidth: 14,
    // seriesField:"month",
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    columnBackground: {
      style: {
        fill: "#f3f3f3",
        radius: [20, 20, 0, 0],
      },
    },
  };

  return (
    <ChartContainer>
      <h2 style={{ marginBottom: 32, fontSize: 18 }}>
        {heading}{" "}
        <Badge
          showZero
          className="site-badge-count-109"
          count={props.totalCount}
          style={{
            backgroundColor: "#135bed",
            marginLeft: 5,
          }}
        />
      </h2>
      <Column
        {...config}
        meta={{ month: { formatter: (val) => moment(val).format("DD-MMM-YYYY") } }}
      />
    </ChartContainer>
  );
};

ColumnPlot.defaultProps = {
  data: [],
  xField: "",
  yField: "",
};

export default ColumnPlot;
