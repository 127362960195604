import { ALL_FUTURE_INQUIRY } from "redux/constants";

const initialState = { allFutureInquiries: [] };

const allFutureOpporInquiriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_FUTURE_INQUIRY.STORE: {
      const { payload } = action;
      return { ...state, allFutureInquiries: payload };
    }

    case ALL_FUTURE_INQUIRY.CHANGE_STATUS: {
      const { adminId, status } = action.payload;
      const currentAdmin = state.allFutureInquiries.find((admin) => admin._id === adminId);
      currentAdmin.status = status;
      return { ...state };
    }

    case ALL_FUTURE_INQUIRY.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allFutureInquiries.findIndex(
        (user) => user._id === payload._id
      );
      state.allFutureInquiries.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_FUTURE_INQUIRY.ADD_NEW: {
      const { payload } = action;

      return { ...state, allFutureInquiries: [payload, ...state.allFutureInquiries] };
    }

    default:
      return state;
  }
};

export default allFutureOpporInquiriesReducer;
