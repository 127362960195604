import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import ChartContainer from "./chartContainer";
import moment from "moment";
import { Badge, Row, Col, Empty, Spin, Skeleton } from "antd";

const SaleColumnPlot = (props) => {
  const { heading, achievedData, isLoading, targetData } = props;
  const data = targetData?.concat(achievedData);

  const config = {
    data,
    xField: "month",
    yField: "value",
    seriesField: "name",
    dodgePadding: 2,
    isGroup: true,
    color: ({ name }) => {
      if (name === "Achieved") {
        return "#33ca76";
      }
      return "#28b2ea";
    },
    month: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    columnStyle: {
      radius: [5, 5, 0, 0],
    },
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    appendPadding: 20,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
  };

  const GraphLoader = () => (
    <div
      style={{
        height: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      {[1, 2, 3, 4, 5, 6, 7].map((item) => {
        return <Skeleton key={item} active className="skeleton-wrap" />;
      })}
    </div>
  );

  return (
    <ChartContainer>
      <h2 style={{ marginBottom: 32 }}>
        {heading}{" "}
        <Badge
          showZero
          className="site-badge-count-109"
          count={props.totalCount}
          style={{
            backgroundColor: "#135bed",
            marginLeft: 5,
          }}
        />
      </h2>
      {data?.length > 0 ? (
        <>{isLoading ? <GraphLoader /> : <Column {...config} />}</>
      ) : (
        <Empty />
      )}
    </ChartContainer>
  );
};

SaleColumnPlot.defaultProps = {
  data: [],
  xField: "",
  yField: "",
};

export default SaleColumnPlot;
