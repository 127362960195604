import { css } from "styled-components";

const style = css`
  //Should make global variables in theme for row height, box-shadows, borders and others, also have to take into account sorting through simple props
  & {
    margin-top: 25px;
    width: 100%;
    overflow: auto;
    border: none !important;
    border-radius: 5px;
    //box-shadow: 0 2px 32px -10px rgba(0,0,0,0.15) !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  }

  // .rt-table {
  //   border-top-right-radius: 15px;
  //   border-top-left-radius: 15px;
  // }

  //min-width will depend on number of columns and their widths
  .rt-thead {
    min-width: 500px !important;
    position: relative;
    /* z-index: 100; */
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    height: 60px;
  }

  .rt-thead .rt-tr {
    background-color: #135bed;
    color: #fff;

    .rt-th {
      color: #fff;
    }
  }

  .rt-th {
    font-size: 15px;
    font-weight: 600;
    word-break: initial !important;
    margin: auto;
  }

  .rt-th:first-child,
  .rt-td:first-child {
    margin-left: 16px !important;
  }

  //Row height must be read from theme
  .rt-tr {
    background-color: white;
    height: 50px !important;
  }

  .rt-th,
  .rt-td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: normal !important;
    word-break: break-all;
    // padding: 15px !important;
    border: none !important;
    color: #1d194d;
  }

  .rt-td {
    font-size: 12px;
    font-weight: 500;
  }

  //The following should be app-specific as well

  .rt-tbody {
    font-weight: 600;
    background-color: rgb(249, 249, 249, 1);
  }

  .rt-tr-group:last-child {
    margin-bottom: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05) !important;
  }

  .pagination-bottom {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .-pageJump input {
    border: 1px solid #135bed !important;
    border-radius: 4px;
  }
  .-pagination {
    height: 65px !important;
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: none !important;
    background-color: #fff;
  }

  .-previous,
  .-center,
  .-next {
    flex: unset !important;
  }

  .-previous button,
  .-next button {
    font-size: 1.1rem !important;
    text-align: center !important;
    color: white !important;
    padding: 6px 12px !important;
    height: 28px !important;
    background-color: #135bed !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .-center input {
    height: 40px !important;
    width: 60px !important;
    padding: 0 !important;
  }

  .-center input[type="number"] {
    -moz-appearance: textfield;
  }

  .-center input::-webkit-outer-spin-button,
  .-center input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export default style;
