import { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import DonutPlot from "components/charts/donutPlot";
import ContentHeader from "components/header/contentHeader";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import moment from "moment";
import SaleDashboardCard from "components/cards/saleDashboardCard";
import { useSelector } from "react-redux";
import InquiryIcon from "images/inquiry-F8CBA6.png";
import SaleColumnPlot from "components/charts/SaleColumnPlot";

const initialState = {
  totalInquiries: [],
  totalLeads: [],
  totalDemos: [],
  totalQuotes: [],
  salesGraphData: [],
  totalTravelAgents: [],
  totalUserLicense: 0,
  totalPayments: 0,
};

const Dashboard = () => {
  const { data } = useSelector((state) => state.admin);
  const [type, setType] = useState("month");
  const [selectedDate, setSelectedDate] = useState("");

  const [statsData, setStatsData] = useState("");
  const [filteredStatsData, setFilteredStatsData] = useState({ ...initialState });
  const [graphsData, setGraphsData] = useState({ ...initialState });

  const { mutateAsync, isLoading } = useMutation(Queries.getManagerDashboard, {
    onSuccess: ({ data }) => {
      setStatsData(data.data);

      const totalInquiries = mapData(data.data.totalInquiries);
      const totalLeads = mapData(data.data.totalLeads);
      const totalDemos = mapData(data.data.totalDemos);
      const totalQuotes = mapData(data.data.totalQuotes);
      const totalUserLicense = [];
      setGraphsData({
        ...graphsData,
        totalInquiries,
        totalLeads,
        totalDemos,
        totalQuotes,
        totalUserLicense,
      });
    },
    onError: (err) => {
      message.error(errorHandler(err));
    },
  });

  useEffect(() => {
    console.log("statsData", statsData);
  }, [statsData]);

  const checkCompareDate = (createdAt) => {
    if (type == "month") {
      let endWeekDate = moment(selectedDate).endOf("month").format("YYYY-MM-DD");
      let getCompareDate = moment(createdAt).isBetween(selectedDate, endWeekDate);

      return getCompareDate;
    } else {
      let endWeekDate = moment(selectedDate).endOf("week").format("YYYY-MM-DD");
      let getCompareDate = moment(createdAt).isBetween(selectedDate, endWeekDate);
      return getCompareDate;
    }
  };

  // =============FILTER BY DATES=============
  useEffect(() => {
    if (statsData) {
      const filteredData = statsData?.totalQuotes?.filter((item) => {
        if (item.paymentStatus == "succeeded" && item.status == 1) {
          return item;
        }
      });
      if (type == "all") {
        // //For TOTAL PYAMENTS
        const totalPayments = statsData?.totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.amount;
          } else return 0;
        }, 0);

        // //For totalUserLicense
        const totalUserLicense = statsData.totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.totalUserLicense;
          } else return 0;
        }, 0);

        let saleOverviewData = saleOverview(filteredData);
        setFilteredStatsData({
          ...filteredStatsData,
          totalInquiries: statsData.totalInquiries,
          totalLeads: statsData.totalLeads,
          totalDemos: statsData.totalDemos,
          totalQuotes: statsData.totalQuotes,
          salesGraphData: saleOverviewData,
          totalUserLicense,
          totalPayments,
        });
      } else if (type == "week") {
        //For Inquiry
        const totalInquiries = statsData.totalInquiries.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalLeads
        const totalLeads = statsData.totalLeads.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalDemos
        const totalDemos = statsData.totalDemos.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalQuotes
        const totalQuotes = statsData.totalQuotes.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        // //For TOTAL PYAMENTS
        const totalPayments = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.amount;
          } else return 0;
        }, 0);

        // //For totalUserLicense
        const totalUserLicense = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.totalUserLicense;
          } else return 0;
        }, 0);

        setFilteredStatsData({
          ...filteredStatsData,
          totalInquiries,
          totalLeads,
          totalDemos,
          totalQuotes,
          saleOverviewData: [],
          totalUserLicense,
          totalPayments,
        });
      } else if (type == "month") {
        //For Inquiry
        const totalInquiries = statsData.totalInquiries.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalLeads
        const totalLeads = statsData.totalLeads.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalDemos
        const totalDemos = statsData.totalDemos.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalQuotes
        const totalQuotes = statsData.totalQuotes.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        // //For TOTAL PYAMENTS
        const totalPayments = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.amount;
          } else return 0;
        }, 0);

        // //For totalUserLicense
        const totalUserLicense = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.totalUserLicense;
          } else return 0;
        }, 0);

        let saleOverviewData = saleOverview(filteredData);
        setFilteredStatsData({
          ...filteredStatsData,
          totalInquiries,
          totalLeads,
          totalDemos,
          totalQuotes,
          salesGraphData: saleOverviewData,
          totalUserLicense,
          totalPayments,
        });
      }
    }
  }, [statsData, selectedDate, type]);

  useEffect(() => {
    mutateAsync();
  }, []);

  return (
    <div>
      <ContentHeader title="Dashboard" selectWidth={200} />

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <SaleDashboardCard
            switchableRangePickerState={(e) => {}}
            setSelectedDate={setSelectedDate}
            type={type}
            setType={setType}
            statsData={filteredStatsData}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;

const mapData = (data) => {
  const dataObject = data.reduce((initial, user) => {
    const createdAt = moment(user.createdAt).format("YYYY-MM-DD");

    if (initial[createdAt]) {
      initial[createdAt] += 1;
    } else {
      initial[createdAt] = 1;
    }

    return initial;
  }, {});

  let graphData = [];

  for (let key in dataObject) {
    graphData.push({
      month: key,
      value: dataObject[key],
    });
  }
  graphData.sort((a, b) => (a.month > b.month ? 1 : -1));
  return graphData;
};

const saleOverview = (data) => {
  let saleOveriewGraphData = [];
  let saleOveriewData = [];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let key in months) {
    let getFilterByMonth = data?.filter(
      (item) => moment(item.createdAt).format("MMM") == months[key]
    );

    saleOveriewData.push({ [months[key]]: getFilterByMonth });
  }

  saleOveriewData.map((item, index) => {
    saleOveriewGraphData.push({
      name: "Achieved",
      month: months[index],
      value: item[months[index]]?.length,
    });
  });
  return saleOveriewGraphData;
};
