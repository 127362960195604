import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col, Tooltip, Form } from "antd";
import { ALL_DEMOS, ALL_INQUIRY, ALL_LEADS, REGISTRATIONS } from "redux/constants";
import { CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import InquiryDetailModal from "components/modals/InquiryDetailModal";
import { HiCheck, HiX } from "react-icons/hi";
import CreateInquiryModal from "components/modals/createInquiryModal";
import { MdOutlineAddLink, MdOutlineLinkOff } from "react-icons/md";
import { InputWrapper } from "components/input";
import { BsInfoCircleFill } from "react-icons/bs";
import DemoDetailModal from "components/modals/DemoDetailModal";
import DemoUpdateModal from "components/modals/DemoUpdateModal";
import DemoReasonModal from "components/modals/DemoReasonModal";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const Demo = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { allDemos } = useSelector((state) => state.demos);
  const { data } = useSelector((state) => state.admin);

  const [demoLinkState, setDemoLinkState] = useState("");
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [demoRef, setDemoRef] = useState("");
  const [createLeadModal, setCreateLeadModal] = useState(false);
  const [leadDetailModal, toggleLeadDetailModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("viewAll");
  const [demoUpdateModal, setDemoUpdateModal] = useState({
    visible: false,
    title: "",
  });
  const [demoReasonModal, setDemoReasonModal] = useState({
    visible: false,
    title: "",
  });

  // const demoRef = useRef();
  const filterRef = useRef(2);

  const { mutateAsync: getAllDemos, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllDemos
  );

  const handleSearch = (val) => {
    const filteredOptions = allDemos.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.country.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.gender.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  const closeModal = () => {
    setAddLinkModal(false);
    form.resetFields();
  };

  useEffect(() => {
    const filteredOptions = allDemos.filter((option) => {
      if (option.status == filterByStatus) {
        return option;
      } else if (filterByStatus == "viewAll") {
        return allDemos;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allDemos);
  }, [allDemos]);

  useEffect(() => {
    getAllDemos()
      .then(({ data }) => {
        dispatch({ type: ALL_DEMOS.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
    dispatch({ type: "DEMO", payload: null });
  }, []);

  return (
    <StyledTable>
      <Modal visible={addLinkModal} title="Add Demo Link" footer={null} onCancel={closeModal}>
        <Form
          form={form}
          onFinish={() => {
            setAddLinkModal(false);
          }}
          validateTrigger="onFinish"
        >
          <Form.Item
            name="Demo Link"
            rules={[{ required: true, message: "Enter a valid URL", type: "url" }]}
          >
            <InputWrapper
              name="meetingLink"
              suffix={
                <Tooltip title="Make sure to add Https or Http before the link">
                  <BsInfoCircleFill style={{ color: "blue" }} />
                </Tooltip>
              }
              placeholder="https://zoom.us/"
              onChange={(e) => {
                setDemoLinkState(e.target.value);
              }}
            />
          </Form.Item>
          <ButtonWrapper htmlType="submit" style={{ marginTop: 32 }}>
            Add
          </ButtonWrapper>
        </Form>
      </Modal>

      <DemoDetailModal
        title="Demo details"
        demoDetails={true}
        visible={leadDetailModal}
        onCancel={() => {
          toggleLeadDetailModal(false);
        }}
        demoUpdateModal={demoUpdateModal}
        setDemoUpdateModal={setDemoUpdateModal}
        setDemoRef={setDemoRef}
        data={demoRef}
        showForm={true}
      />

      {demoUpdateModal.visible && (
        <DemoUpdateModal
          title={`Update Demo Details`}
          visible={demoUpdateModal.visible}
          onCancel={() => {
            setDemoUpdateModal(false);
          }}
          toggleLeadDetailModal={() => toggleLeadDetailModal(false)}
          demoUpdateModal={demoUpdateModal}
          demoLinkState={demoLinkState}
          data={demoRef}
          showForm={true}
        />
      )}

      {demoReasonModal.visible && (
        <DemoReasonModal
          title={`Reason for ${demoReasonModal.title}`}
          visible={demoReasonModal.visible}
          onCancel={() => {
            setDemoReasonModal(false);
          }}
          demoReasonModal={demoReasonModal}
          data={demoRef}
          showForm={true}
        />
      )}

      <div>
        <ContentHeader
          title="Demos"
          count={allDemos?.length}
          tooltip="Create your own Lead"
          onSearch={(e) => {
            handleSearch(e);
          }}
          onSelectChange={(e) => setFilterByStatus(e)}
          dropDownOptions={[
            { label: "View All", value: "viewAll" },
            { label: "Pending", value: 0 },
            { label: "Approved", value: 1 },
            { label: "Not Approved", value: 2 },
            // { label: "My Leads", value: data._id },
          ]}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              accessor: "phoneNumber",
              sortable: false,
            },

            {
              Header: "Country",
              accessor: "country",
              sortable: false,
            },
            {
              Header: "Training Type",
              accessor: "trainingType",
              sortable: false,
            },
            // {
            //   Header: () => <div>Demo Link</div>,
            //   Cell: ({ original }) => (
            //     <div style={{ minWidth: 80, textAlign: "center" }}>
            //       {original?.demoDetails.demoLink !== "" &&
            //       original?.demoDetails.demoLink !== null ? (
            //         <MdOutlineLinkOff
            //           onClick={() => {
            //             // user.current = original;
            //             // editMeetingLink();
            //           }}
            //           style={{ fontSize: 22, color: "blue", cursor: "pointer" }}
            //         />
            //       ) : (
            //         <MdOutlineAddLink
            //           onClick={() => {
            //             // user.current = original;
            //             setAddLinkModal(true);
            //           }}
            //           style={{ fontSize: 22, color: "blue", cursor: "pointer" }}
            //         />
            //       )}
            //     </div>
            //   ),
            //   sortable: false,
            // },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      // demoRef.current = original;
                      setDemoRef(original);
                      toggleLeadDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Actions",
              Cell: ({ original }) => {
                return (
                  <div>
                    {original.status == 0 ? (
                      <div
                        style={{ width: 70, display: "flex", justifyContent: "space-between" }}
                      >
                        <div
                          className="delete-icon"
                          onClick={() => {
                            // demoRef.current = original;
                            let {
                              demoDate,
                              demoDuration,
                              demoLink,
                              demoClientFeedback,
                              demoReferenceImages,
                            } = original.demoDetails;
                            if (
                              demoDate == "" ||
                              demoDuration == "" ||
                              demoLink == "" ||
                              demoClientFeedback == "" ||
                              demoReferenceImages == []
                            ) {
                              message.warning(
                                "Please fill this candidate's demo information before approving"
                              );
                            } else {
                              setDemoRef(original);
                              setDemoReasonModal({ visible: true, title: "Interested" });
                            }
                          }}
                        >
                          <Tooltip title="Interested (for quote)" placement="bottom">
                            <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                          </Tooltip>
                        </div>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            // demoRef.current = original;
                            setDemoRef(original);
                            setDemoReasonModal({
                              visible: true,
                              title: "Not Interested",
                            });
                          }}
                        >
                          <Tooltip title="Not Interested (for quotes)" placement="bottom">
                            <HiX style={{ fontSize: 20, color: "#f73838" }} />
                          </Tooltip>
                        </div>
                      </div>
                    ) : original.status == 1 ? (
                      <div>
                        <strong style={{ margin: 0, color: "#4fc06b" }}>
                          Approved (for quote)
                        </strong>
                      </div>
                    ) : (
                      <div>
                        <strong style={{ margin: 0, color: "#f73838" }}>
                          Not Approved (for quote)
                        </strong>
                      </div>
                    )}
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Demo;
