import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoIosApps } from "react-icons/io";
import { AiOutlineUser } from "react-icons/ai";
import { GiOrganigram } from "react-icons/gi";
import {
  MdOutlineAppRegistration,
  MdVideoLibrary,
  MdDashboard,
  MdOutlineUploadFile,
} from "react-icons/md";
import Icon from "@ant-design/icons";
import { RiAdminFill, RiAdminLine } from "react-icons/ri";
import { connect, useSelector } from "react-redux";

const SidebarContent = ({ collapsed }) => {
  const { data } = useSelector((state) => state.admin);
  const location = useLocation();

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      selectedKeys={location.pathname}
    >
      <Menu.Item key="/" icon={<Icon component={MdDashboard} />}>
        <Link to="/" style={{ color: "#fff" }}>
          Dashboard
        </Link>
      </Menu.Item>
      {data?.userRole == 1 ? (
        <Menu.Item key="/sub-admins" icon={<Icon component={RiAdminLine} />}>
          <Link to="/sub-admins" style={{ color: "#fff" }}>
            Sub Admins
          </Link>
        </Menu.Item>
      ) : null}
      <Menu.Item key="/registrations" icon={<Icon component={MdOutlineAppRegistration} />}>
        <Link to="/registrations" style={{ color: "#fff" }}>
          Registrations
        </Link>
      </Menu.Item>
      <Menu.Item key="/individual-users" icon={<Icon component={AiOutlineUser} />}>
        <Link to="/individual-users" style={{ color: "#fff" }}>
          Individual Users
        </Link>
      </Menu.Item>
      <Menu.Item key="/companies" icon={<Icon component={GiOrganigram} />}>
        <Link to="/companies" style={{ color: "#fff" }}>
          Companies
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="/upload-application" icon={<Icon component={MdOutlineUploadFile} />}>
        <Link to="/upload-application" style={{ color: "#fff" }}>
          Upload applications
        </Link>
      </Menu.Item> */}
      <Menu.Item key="/knowledge-center" icon={<Icon component={MdVideoLibrary} />}>
        <Link to="/knowledge-center" style={{ color: "#fff" }}>
          Knowledge Center
        </Link>
      </Menu.Item>

      <Menu.Item key="/applications" icon={<Icon component={IoIosApps} />}>
        <Link to="/applications" style={{ color: "#fff" }}>
          Applications
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default SidebarContent;
