import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal } from "antd";
import { REGISTRATIONS } from "redux/constants";
import { CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const Registrations = () => {
  const dispatch = useDispatch();
  const { allRegistrations } = useSelector((state) => state.registrations);

  const [registrationModal, toggleRegistrationModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [confimationDeleteModal, setConfimationDeleteModal] = useState({
    visible: false,
    userId: "",
  });

  const registration = useRef();
  const filterRef = useRef(2);

  const { mutateAsync: getAllRegistrations, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllRegistrations
  );

  const { mutateAsync: deleteRegistration, isLoading: deleteRegistrationLoading } = useMutation(
    Mutations.deleteRegistration
  );

  const handleDeleteRegistration = async () => {
    try {
      const { data } = await deleteRegistration({
        userId: confimationDeleteModal.userId,
      });
      setConfimationDeleteModal({ visible: false });
      dispatch({ type: REGISTRATIONS.STORE, payload: data.data });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleFilter = (value) => {
    const filteredData = allRegistrations.filter((registration) => {
      return registration.userRole == value;
    });
    setTableData(filteredData);
  };

  useEffect(() => {
    handleFilter(filterRef.current);
  }, [allRegistrations]);

  useEffect(() => {
    getAllRegistrations()
      .then(({ data }) => {
        dispatch({ type: REGISTRATIONS.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  return (
    <StyledTable>
      <Modal
        visible={confimationDeleteModal.visible}
        title={`Are you sure?`}
        footer={
          <ButtonWrapper
            style={{ width: "50%" }}
            onClick={handleDeleteRegistration}
            loading={deleteRegistrationLoading}
          >
            Delete
          </ButtonWrapper>
        }
        onCancel={() => setConfimationDeleteModal({ visible: false })}
      >
        <div style={{ display: "grid", textAlign: "center" }}>
          <ExclamationCircleOutlined
            style={{ fontSize: 54, color: "#ffbe04", marginBottom: 22 }}
          />
          <p style={{ margin: 0 }}>
            Are you sure you want to delete the registeration of this{" "}
            {filterRef.current == 1 ? "user" : "company"}
          </p>
        </div>
      </Modal>

      <RegistrationDetailModal
        title="Registration form details"
        visible={registrationModal}
        onCancel={() => {
          toggleRegistrationModal(false);
        }}
        data={registration.current}
        showForm={true}
      />

      <div>
        <ContentHeader title="Registrations" />
        <MultiSwitchWrapper
          options={[
            { label: "Company", value: 2 },
            { label: "Individual User", value: 1 },
          ]}
          onChange={(val) => {
            handleFilter(val);
            filterRef.current = val;
          }}
          style={{ margin: "12px 0" }}
        />
        <TableWrapper
          tableData={tableData}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              accessor: "phoneNumber",
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      registration.current = original;
                      toggleRegistrationModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },

            {
              Header: "Delete",
              Cell: ({ original }) => {
                return (
                  <div style={{ width: 45, display: "flex", justifyContent: "center" }}>
                    <div
                      className="delete-icon"
                      title="Delete"
                      onClick={() => {
                        setConfimationDeleteModal({ visible: true, userId: original._id });
                      }}
                    >
                      <CgTrash style={{ fontSize: 18, color: "#135bed" }} />
                    </div>
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Registrations;
