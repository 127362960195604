import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, Radio } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper, TextAreaWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ALL_INQUIRY, ALL_LEADS, REGISTRATIONS, SUB_ADMIN } from "redux/constants";
import { approveUser } from "redux/actions";
import { PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { RadioGroup } from "components/input";
import ImageUploader from "components/uploader/ImageUploader";
import { CheckCircleFilled, FacebookFilled, LinkedinFilled } from "@ant-design/icons";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { MdOtherHouses } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { BsCheckCircleFill, BsLinkedin, BsTwitter } from "react-icons/bs";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { BiDotsHorizontalRounded } from "react-icons/bi";

const initialState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  age: "",
  address: "",
  gender: "",
  country: "",
  city: "",
  userType: "Individual User",
  trainingType: "",
  phoneError: "",
  socialSite: "",
  referenceImages: [],
  referenceText: "",
};

const CreateLeadModal = (props) => {
  const { title, visible, onCancel } = props;
  const { data } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [referenceValue, setReferenceValue] = useState(1);
  const [leadFromOtherValue, setLeadFromOtherValue] = useState("Facebook");

  const { isLoading, mutateAsync } = useMutation(Mutations.createLead);

  const handleAddSubAdmin = async () => {
    const getUserRole = (userRole) => {
      let userRoles = [
        { label: "Admin", value: 1 },
        { label: "Manager", value: 2 },
        { label: "Sales", value: 3 },
        { label: "Marketing", value: 4 },
      ].find((i) => i.value == userRole);
      return userRoles?.label;
    };

    const variables = {
      fullName: state.fullName,
      email: state.email,
      phoneNumber: state.phoneNumber,
      companyName: state.userType == "Individual User" ? "" : state.companyName,
      age: state.age,
      address: state.address,
      gender: state.gender,
      country: state.country,
      city: state.city,
      userType: state.userType,
      trainingType: state.trainingType,
      socialSite:
        leadFromOtherValue == "Others" ? `Others: ${state.socialSite}` : leadFromOtherValue,
      referenceText: state.referenceText,
      referenceImages: fileList,
    };
    if (isValidPhoneNumber(state.phoneNumber)) {
      try {
        console.log(variables);
        let getResponse = await mutateAsync(variables);
        dispatch({ type: ALL_LEADS.ADD_NEW, payload: getResponse.data.data });
        message.success("Created successfully");
        onClose();
      } catch (err) {
        message.error(errorHandler(err));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={700}
    >
      <Form form={form} onFinish={handleAddSubAdmin} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={12}>
            <div className="label">Full Name*</div>
            <Form.Item
              name="Full Name"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="fullName"
                placeholder="Full Name"
                value={state.fullName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Email*</div>
            <Form.Item
              name="Email"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="email"
                placeholder="Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Phone Number*</div>
            <Form.Item
              name="Phone Number"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <PhoneInputWrapper
                name="phoneNumber"
                autoComplete="new-password"
                value={state.phoneNumber}
                placeholder="Phone Number"
                onChange={handleChange}
                error={state.phoneError}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Training Type*</div>
            <Form.Item
              name="Training Type"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <SelectWrapper
                options={[
                  { label: "Hajj", value: "Hajj" },
                  { label: "Umrah", value: "Umrah" },
                  { label: "Both", value: "Both" },
                ]}
                size={"large"}
                name="trainingType"
                placeholder="Training Type"
                value={state.trainingType}
                onChange={(e) => setState({ ...state, trainingType: e })}
              />
            </Form.Item>
          </Col>
          <Col span={state.userType == "Individual User" ? 24 : 12}>
            <div className="label">User Type*</div>
            <Form.Item
              name="User Type"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <SelectWrapper
                options={[
                  { label: "Individual User", value: "Individual User" },
                  {
                    label: "Company/Travel Agent",
                    value: "Company/Travel Agent",
                  },
                ]}
                size={"large"}
                name="userType"
                placeholder="User Type"
                value={state.userType}
                onChange={(e) => setState({ ...state, userType: e })}
              />
            </Form.Item>
          </Col>

          {state.userType == "Individual User" ? null : (
            <Col span={12}>
              <div className="label">Company Name*</div>
              <Form.Item
                name="Company Name"
                rules={[{ required: true, message: "Required" }]}
                style={{ margin: 0 }}
              >
                <InputWrapper
                  name="companyName"
                  placeholder="Company Name"
                  value={state.companyName}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <div className="label">Age*</div>
            <Form.Item
              name="Age"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="age"
                placeholder="Age"
                value={state.age}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="label">Gender*</div>
            <Form.Item
              name="Gender"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <SelectWrapper
                options={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                size="large"
                name="gender"
                placeholder="Gender"
                value={state.gender}
                onChange={(e) => setState({ ...state, gender: e })}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Country*</div>
            <Form.Item
              name="Country"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="country"
                placeholder="Country"
                value={state.country}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="label">City*</div>
            <Form.Item
              name="City"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="city"
                placeholder="City"
                value={state.city}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="label">Address*</div>
            <Form.Item
              name="Address"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="address"
                placeholder="Address"
                value={state.address}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Reference</div>

            <RadioGroup
              onChange={(e) => setReferenceValue(e.target.value)}
              value={referenceValue}
            >
              <Radio value={1}>Text</Radio>
              <Radio value={2}>Image</Radio>
            </RadioGroup>
            <div style={{ marginTop: 10 }}>
              {referenceValue == 1 ? (
                <Form.Item
                  name="reference"
                  rules={[{ required: false, message: "Required" }]}
                  style={{ margin: 0 }}
                >
                  <TextAreaWrapper
                    rows={3}
                    name="referenceText"
                    value={state.referenceText}
                    placeholder="Provide a Reference here.."
                    onChange={handleChange}
                  />
                </Form.Item>
              ) : (
                <ImageUploader
                  uploadLength={5}
                  uploadPreset={"Inquiry_references"}
                  loading={loading}
                  setLoading={setLoading}
                  fileList={fileList}
                  setFileList={setFileList}
                />
              )}
            </div>
          </Col>

          <Col span={24}>
            <div className="label">Lead From</div>
            <Row justify="space-between">
              {[
                {
                  title: "Facebook",
                  id: 1,
                  icon: <FaFacebookF size={15} color="#fff" />,
                  bgColor: "#3b5998",
                },
                {
                  title: "LinkedIn",
                  id: 2,
                  icon: <BsLinkedin size={15} color="#fff" />,
                  bgColor: "#0072b1",
                },
                {
                  title: "Whatsapp",
                  id: 3,
                  icon: <FaWhatsapp size={15} color="#fff" />,
                  bgColor: "#25D366",
                },
                {
                  title: "Twitter",
                  id: 2,
                  icon: <BsTwitter size={15} color="#fff" />,
                  bgColor: "#1DA1F2",
                },
                {
                  title: "Instagram",
                  id: 3,
                  icon: <FaInstagram size={15} color="#fff" />,
                  bgColor: "#25D366",
                },
                {
                  title: "Others",
                  id: 4,
                  icon: <BiDotsHorizontalRounded size={15} color="#fff" />,
                  bgColor: "grey",
                },
              ].map((item, index) => {
                return (
                  <CardWrapper
                    span={3}
                    onClick={(e) => setLeadFromOtherValue(item.title)}
                    key={index}
                    selected={leadFromOtherValue == item.title}
                    bgColor={item.bgColor}
                    style={{
                      background:
                        item.title == "Instagram"
                          ? "radial-gradient(circle at -58% -90%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%)"
                          : item.bgColor,
                    }}
                  >
                    {leadFromOtherValue == item.title ? (
                      <BsCheckCircleFill
                        color="#135bed"
                        size={18}
                        style={{
                          position: "absolute",
                          right: -5,
                          top: -8,
                          background: "#fff",
                          borderRadius: 100,
                          borderColor: "#135bed",
                        }}
                      />
                    ) : null}
                    {item.icon}
                    <span>{item.title}</span>
                  </CardWrapper>
                );
              })}
            </Row>
            <div style={{ marginTop: 10 }}>
              {leadFromOtherValue == "Others" ? (
                <Form.Item
                  name="Social Site"
                  rules={[{ required: false, message: "Required" }]}
                  style={{ margin: 0 }}
                >
                  <TextAreaWrapper
                    rows={2}
                    name="socialSite"
                    value={state.socialSite}
                    placeholder="Please specify"
                    onChange={handleChange}
                  />
                </Form.Item>
              ) : null}
            </div>
          </Col>

          <Col span={24}>
            <ButtonWrapper
              style={{ width: "70%" }}
              htmlType="submit"
              disabled={isLoading || loading}
              loading={isLoading}
              onClick={() => {
                if (!isValidPhoneNumber(state.phoneNumber)) {
                  setState({
                    ...state,
                    phoneError: "Invalid phone number",
                  });
                } else {
                  setState({
                    ...state,
                    phoneError: "",
                  });
                }
              }}
            >
              Create
            </ButtonWrapper>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default CreateLeadModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;

const CardWrapper = styled(Col)`
  /* width: 80px; */
  /* height: 40px; */
  padding: 6px;
  border-radius: 6px;
  border: ${(props) => (props.selected ? "2px solid #135bed" : props.bgColor)};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: ${(props) => (props.bgColor ? props.bgColor : "grey")}; */
  position: relative;
  cursor: pointer;
  span {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 6px;
    color: #fff;
  }
`;
