import { adminModule, salesModule, managerModule, marketingModule } from "./config";
import Cookies from "js-cookie";
import Marketing from "views/marketing";

export const Queries = {
  getAllUsers: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllUsers`, { headers });
  },
  getAllRegistrations: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllUnregistered`, { headers });
  },
  getDashboardStats: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getDashboardStats`, { headers });
  },
  getAllCompanies: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get("/getAllCompanies", { headers });
  },
  getAllUsersOfCompany: ({ companyId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllUsersOfCompany/${companyId}`, { headers });
  },
  getAllInstructorsOfCompany: ({ companyId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllInstructorsOfCompany/${companyId}`, { headers });
  },
  getAllTutorials: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllTutorial`, { headers });
  },
  getAllsubAdmins: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllSubAdmin`, { headers });
  },

  getMyProfile: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getMyProfile`, { headers });
  },

  getCompanyPackages: (id) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllCompanyPackages/${id}`, { headers });
  },

  // ==============SALES=============
  getSalesDashboard: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getSalesDashboard`, { headers });
  },

  getMyKPI: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getMyKPI`, { headers });
  },

  // -------------Inquiry---------------
  getAllInquiries: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllInquiry`, { headers });
  },

  // -------------Inquiry---------------
  getAllOpportunity: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllOpportunity`, { headers });
  },

  // -------------leads---------------
  getAllLeads: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllLeads`, { headers });
  },

  // -------------demos---------------
  getAllDemos: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllDemos`, { headers });
  },

  // -------------Quotes---------------
  getAllQuotes: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllQuotes`, { headers });
  },

  // -------------Monitoring---------------
  getSaleAllUsers: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllUsers`, { headers });
  },

  // -------------Resale---------------
  getAllResale: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllResale`, { headers });
  },

  /**
   * START MANAGER
   */

  getAllSales: () => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getAllSales`, { headers });
  },

  getAllMarketing: () => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getAllMarketing`, { headers });
  },

  // -------------leads---------------
  getAllLeadsManager: () => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getAllLeads`, { headers });
  },

  // ==============DASHBOAR=============
  getManagerDashboard: () => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getManagerDashboard`, { headers });
  },

  getSaleStats: ({ saleId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getSaleStats/${saleId}`, { headers });
  },

  getSaleKPI: ({ saleId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getSaleKPI/${saleId}`, { headers });
  },

  // -------INQUIRIES---------
  getAllInquiriesManager: () => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getAllInquiry`, { headers });
  },

  // -------GET KIPI'S---------
  getKPIByAdminId: ({ adminId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getKPIByAdminId/${adminId}`, { headers });
  },

  getAllComplaints: () => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/getAllComplaints`, { headers });
  },

  /**
   * END MANAGER
   */

  // ===============Marketing===============

  // ==============DASHBOAR=============
  getMarketingDashboard: () => {
    const headers = { Authorization: Cookies.get("token") };
    return marketingModule.get(`/getMarketingDashboard`, { headers });
  },

  // -------------leads---------------
  getAllLeadsMarketing: () => {
    const headers = { Authorization: Cookies.get("token") };
    return marketingModule.get(`/getAllLeads`, { headers });
  },

  //-------------------------------------------------------------------------------------
  getAllsubAdminsRequest: ({ limit, page }) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllSubAdmins/${limit}/${page}`, { headers });
  },
  getAllUserLogs: ({ limit, page }) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllUserLogs/${limit}/${page}`, { headers });
  },
  getMessageList: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/findAllMyMessages`, { headers });
  },

  getMyManagerMessageList: () => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/findAllMyMessages`, { headers });
  },

  getManagerMessagesById: ({ messageId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.get(`/findAllMessageByMessageId/${messageId}`, { headers });
  },

  getMySaleMessageList: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/findAllMyMessages`, { headers });
  },

  getAllMyMessagesToManager: () => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/getAllMyMessagesToManager`, { headers });
  },

  getMessagesById: ({ messageId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/findAllMessageByMessageId/${messageId}`, { headers });
  },

  getSaleMessagesById: ({ messageId }) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.get(`/findAllMessageByMessageId/${messageId}`, { headers });
  },

  getAllApps: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllApplications`, { headers });
  },
  getAllCompanyUsers: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get(`/getAllCompanyUsers`, { headers });
  },
};
