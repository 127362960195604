import React, { useEffect, useState } from "react";
import { Badge, Menu } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InquirySidebar from "../../images/sidebar/inquiry-sidebar.png";
import DashboardSidebar from "../../images/sidebar/dashboard-sidebar.png";
import SalespersonSidebar from "../../images/sidebar/salesman-sidebar.png";
import FeedbackSidebar from "../../images/sidebar/feedback-sidebar.png";
import LeadSidebar from "../../images/sidebar/lead-sidebar.png";

import Icon from "@ant-design/icons";
import { useSelector } from "react-redux";

const SidebarContent = ({ collapsed }) => {
  const location = useLocation();
  const { allComplaints } = useSelector((state) => state.complaints);
  const { allLeads } = useSelector((state) => state.leads);
  const { allSales } = useSelector((state) => state.sales);
  const { allInquiries } = useSelector((state) => state.inquiries);

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      selectedKeys={location.pathname}
    >
      <Menu.Item
        key="/"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={18}
                src={DashboardSidebar}
              />
            )}
          />
        }
      >
        <Link to="/" style={{ color: "#fff" }}>
          Dashboard
        </Link>
      </Menu.Item>

      <Menu.Item
        key="/leads"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/leads" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={LeadSidebar}
              />
            )}
          />
        }
      >
        <Link to="/leads" style={{ color: "#fff" }}>
          Lead{" "}
          <Badge
            className="sidebar-badge"
            count={allLeads?.length}
            size={"small"}
            style={{
              background: "#012169",
              boxShadow: "none",
              fontSize: 10,
              color: location.pathname == "/leads" ? "#fff" : "#7787a0",
            }}
          />
        </Link>
      </Menu.Item>

      {/* <Menu.Item
        key="/feedback"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter:
                    location.pathname == "/feedback" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={FeedbackSidebar}
              />
            )}
          />
        }
      >
        <Link to="/feedback" style={{ color: "#fff" }}>
          Feedback / Complaint
          <Badge
            className="sidebar-badge"
            count={allComplaints?.length}
            size={"small"}
            style={{
              background: "#012169",
              boxShadow: "none",
              fontSize: 10,
              color: location.pathname == "/feedback" ? "#fff" : "#7787a0",
            }}
          />
        </Link>
      </Menu.Item> */}
    </Menu>
  );
};
export default SidebarContent;
