import { Row, Col, Divider, Modal } from "antd";
import moment from "moment";
import { Tabs } from "antd";

const UserDetailModal = (props) => {
  const { TabPane } = Tabs;
  const { title, visible, onCancel, data } = props;

  const keyList = data.userRole == 1 ? userKey : companyKey;
  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <Row>
        <Tabs defaultActiveKey="1" type="card" size={"large"} centered>
          <TabPane tab="Personal Profile" key="1">
            <Col span={24}>
              <Row>
                {keyList.map((item) => (
                  <Col key={item.key} span={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <div className="label" style={{ width: 190 }}>
                        {item.label}:
                      </div>
                      <div className="value">
                        {item.key === "dob"
                          ? moment(data[item.key]).format("DD MMMM YYYY")
                          : data[item.key]}
                      </div>
                    </div>
                    <Divider style={{ margin: "2px 0" }} />
                  </Col>
                ))}
              </Row>
            </Col>
          </TabPane>

          <TabPane tab="Hotel Details" key="2">
            <Col span={24}>
              <Row>
                {hotelKey.map((item) => (
                  <Col key={item.key} span={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <div className="label" style={{ width: 190 }}>
                        {item.label}:
                      </div>
                      <div className="value">
                        {item.type === "date"
                          ? moment(data.hotelDetails?.[item.key]).format("DD MMMM YYYY")
                          : data.hotelDetails?.[item.key]}
                      </div>
                    </div>
                    <Divider style={{ margin: "2px 0" }} />
                  </Col>
                ))}
              </Row>
            </Col>
          </TabPane>

          <TabPane tab="Flight Details" key="3">
            <Col span={24}>
              <Row>
                {flightKey.map((item) => (
                  <Col key={item.key} span={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <div className="label" style={{ width: 190 }}>
                        {item.label}:
                      </div>
                      <div className="value">
                        {item.type === "date"
                          ? moment(data.flightDetails?.[item.key]).format("DD MMMM YYYY")
                          : data.flightDetails?.[item.key]}
                      </div>
                    </div>
                    <Divider style={{ margin: "2px 0" }} />
                  </Col>
                ))}
              </Row>
            </Col>
          </TabPane>
        </Tabs>
      </Row>
    </Modal>
  );
};

UserDetailModal.defaultProps = {
  data: {},
  showForm: false,
};

export default UserDetailModal;

const companyKey = [
  // {key:"createdAt", label: "Registered at"}
  { key: "fullName", label: "Full Name" },
  { key: "email", label: "Email" },
  { key: "phoneNumber", label: "Phone" },
  { key: "gender", label: "Gender" },
  { key: "dob", label: "Date of birth" },
  { key: "city", label: "City" },
  { key: "country", label: "Country" },
  { key: "trainingType", label: "Training type" },
  { key: "address", label: "Address" },
  { key: "medicalHistory", label: "Medical history" },
  { key: "passportNumber", label: "Passport number" },
  { key: "visaNumber", label: "Visa" },
  { key: "bloodType", label: "Blood type" },
  // Related to company
  { key: "companyName", label: "Company name" },
  { key: "totalLicense", label: "Total license" },
];

const userKey = [
  { key: "fullName", label: "Full Name" },
  { key: "email", label: "Email" },
  { key: "phoneNumber", label: "Phone" },
  { key: "gender", label: "Gender" },
  { key: "dob", label: "Date of birth" },
  { key: "city", label: "City" },
  { key: "country", label: "Country" },
  { key: "trainingType", label: "Training type" },
  { key: "address", label: "Address" },
  { key: "medicalHistory", label: "Medical history" },
  { key: "passportNumber", label: "Passport number" },
  { key: "visaNumber", label: "Visa" },
  { key: "bloodType", label: "Blood type" },
  // Kin information
  { key: "kinName", label: "Kin name" },
  { key: "kinRelation", label: "Kin relation" },
  { key: "kinPhoneNumber", label: "Kin phone number" },
];

const hotelKey = [
  { key: "arrivalDateMakkah", label: "Arrival date (Makkah)", type: "date" },
  { key: "hotelAddressMakkah", label: "Hotel address (Makkah)" },
  { key: "arrivalDateMadina", label: "Arrival date (Madina)", type: "date" },
  { key: "hotelAddressMadina", label: "Hotel address (Madina)" },
  { key: "minnaTentNumber", label: "Minna tent number" },
];

const flightKey = [
  { key: "flightNumber", label: "Flight number" },
  { key: "departureDate", label: "Departure date", type: "date" },
  { key: "arrivalDate", label: "Arrival date", type: "date" },
  { key: "flyingFrom", label: "Flying from" },
  { key: "flyingTo", label: "Flying to" },
];
