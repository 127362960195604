import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Modal, Row, Col, message, Skeleton } from "antd";
import styled from "styled-components";
import ContentHeader from "components/header/contentHeader";
import { InputWrapper } from "components/input";
import { ButtonWrapper } from "components/buttons";
import Cookies from "js-cookie";
import { useMutation, Queries, errorHandler, Mutations } from "apis/config";
import { BiTrash } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";

const initialState = { file: "", title: "", fileType: "" };

const Apps = () => {
  const [addModal, toggleAddModal] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [loading, toggleLoading] = useState(false);
  const [appsData, setAppsData] = useState({});
  const appRef = useRef("");
  const inputRef = useRef();

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", state.file);
    formData.append("title", state.title);
    formData.append("fileType", state.fileType);
    toggleLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_ADMIN_URL}/api/admin/uploadApplication`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data", Authorization: Cookies.get("token") },
    })
      .then(({ data }) => {
        setAppsData(mapData(data.data));
        closeModal();
      })
      .catch(function (error) {
        console.log("Error: \n", error);
        message.error(errorHandler(error));
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files && files[0]) {
      setState({ ...state, file: files[0] });
    }
  };

  const { mutateAsync, isLoading } = useMutation(Queries.getAllApps);

  const { mutateAsync: deleteApp, isLoading: deleteAppLoading } = useMutation(
    Mutations.deleteApplication
  );

  useEffect(() => {
    mutateAsync()
      .then(({ data }) => {
        setAppsData(mapData(data.data));
      })
      .catch((err) => {
        message.error(errorHandler(err));
      });
  }, []);

  const handleDelete = async (appId) => {
    try {
      const { data } = await deleteApp({ applicationId: appId });
      setAppsData(mapData(data.data));
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => console.log("Apps Data: ", appsData), [appsData]);

  const closeModal = () => {
    toggleAddModal(false);
    setState({ ...initialState });
    inputRef.current.value = null;
  };

  return (
    <>
      <Modal
        title={state.type}
        visible={addModal}
        footer={null}
        onCancel={() => {
          if (!loading) {
            closeModal();
          }
        }}
        closable={false}
      >
        <InputWrapper
          placeholder="Title"
          value={state.title}
          onChange={(e) => setState({ ...state, title: e.target.value })}
        />

        <input
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          style={{ margin: "16px 0" }}
        />

        <ButtonWrapper loading={loading} onClick={handleSubmit}>
          Upload
        </ButtonWrapper>
      </Modal>

      <StyledContent>
        <ContentHeader title="Upload Applications" />

        <div className="white-background">
          {isLoading ? (
            <Skeleton />
          ) : (
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={12}>
                {appsData[`1`] ? (
                  <div className="box">
                    {deleteAppLoading && appRef.current === appsData[`1`]?._id ? (
                      <LoadingOutlined className="delete-icon" />
                    ) : (
                      <BiTrash
                        className="delete-icon"
                        onClick={() => {
                          appRef.current = appsData[`1`]?._id;
                          handleDelete(appsData[`1`]?._id);
                        }}
                      />
                    )}
                    {appsData[`1`].title}
                  </div>
                ) : (
                  <div
                    className="empty box"
                    onClick={() => {
                      toggleAddModal(true);
                      setState({ fileType: 1, type: "Upload self learning app" });
                    }}
                  >
                    Upload self learning app
                  </div>
                )}
              </Col>

              <Col span={12}>
                {appsData[`4`] ? (
                  <label className="box">
                    {deleteAppLoading && appRef.current === appsData[`4`]?._id ? (
                      <LoadingOutlined className="delete-icon" />
                    ) : (
                      <BiTrash
                        className="delete-icon"
                        onClick={() => {
                          appRef.current = appsData[`4`]?._id;
                          handleDelete(appsData[`4`]?._id);
                        }}
                      />
                    )}
                    {appsData[`4`].title}
                  </label>
                ) : (
                  <div
                    className="empty box"
                    onClick={() => {
                      toggleAddModal(true);
                      setState({ fileType: 4, type: "Upload labbaik app" });
                    }}
                  >
                    Upload labbaik app
                  </div>
                )}
              </Col>
            </Row>
          )}
        </div>
      </StyledContent>
    </>
  );
};

export default Apps;

const StyledContent = styled.div`
  .white-background {
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    border-radius: 6px;
  }

  .box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-radius: 4px;
    color: #fff;
    background-color: #135bed;
    user-select: none;
    :hover {
      background-color: #135bedcf;
    }
    &.empty {
      cursor: pointer;
      border: 1px solid #135bed;
      background-color: #fff;
      color: #135bed;
    }
  }

  .delete-icon {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s;
  }
`;

const mapData = (data) => {
  const mappedData = {};
  data.forEach((item) => {
    mappedData[item.fileType] = item;
  });
  return mappedData;
};
