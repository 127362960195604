import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { REGISTRATIONS } from "redux/constants";
import { useMutation, errorHandler, Queries } from "apis/config";
import MessageList from "components/message";
/**
 * Header, Footer and Sidebar
 */
import SidebarWrapper from "../../components/saleSidebar/sidebarWrapper";
import FooterWrapper from "../../components/footer/footerWrapper";
// import HeaderWrapper from "../../components/header/headerWrapper";
/**
 * Views
 */
import Dashboard from "./Dashboard.jsx";
import Users from "./Users";
import Demos from "./Demos";
import Inquiries from "./Inquiries";
import CompanyUsers from "./CompanyUsers";
import CompanyInstructors from "./CompanyInstructors";
import KnowledgeCenter from "./Quotes";
import InstructorUsers from "./InstructorUsers";
import MessageThread from "./MessageThread";
import Apps from "./Apps";
import UploadApplication from "./UploadApplication";
import Leads from "./Leads";
import Quotes from "./Quotes";
import MySales from "./MySales";
import Monetering from "./Monitoring";
import DeadLeads from "./DeadLeads";
import Resale from "./Resale";
import Profile from "views/Profile";
import SalesMessageList from "components/message/salesMessageList";
import FutureOpportunity from "./FutureOpportunity";

const { Content } = Layout;

const Sales = () => {
  const dispatch = useDispatch();
  const [collapsed, toggleCollapsed] = useState(false);
  const { mutateAsync: getAllRegistrations, isIdle } = useMutation(Queries.getAllRegistrations);
  const allRegistrations = useSelector((state) => state.registrations.allRegistrations);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const { data } = await getAllRegistrations();
  //       return data.data;
  //     } catch (err) {
  //       message.error(errorHandler(err));
  //     }
  //   };

  //   // getData().then((response) => {
  //   //   dispatch({ type: REGISTRATIONS.STORE, payload: response });
  //   // });

  //   // Set interval to get new registrations in every 1 minute
  //   const myInterval = setInterval(() => {
  //     getData().then((response) => {
  //       console.log("allRegistrations---------->", allRegistrations.length);
  //       console.log("response------------------>", response.length);
  //       if (response.length >= 1) {
  //         notification.open({
  //           type: "info",
  //           message: "New Registration",
  //           description:
  //             "There is a new registration pending... Please check the registration tab.",
  //         });
  //         dispatch({ type: REGISTRATIONS.STORE, payload: response });
  //       }
  //     });
  //   }, 1000 * 60 * 5);

  //   return () => clearInterval(myInterval);
  // }, []);

  return (
    <div>
      <SalesMessageList chatWithManager={true} />
      <Layout>
        <div style={{ maxHeight: "100vh" }}>
          <SidebarWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </div>

        <Layout>
          {/* <HeaderWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} /> */}
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              backgroundColor: "#F4F6FB",
            }}
          >
            <section style={{ minHeight: "calc(100vh - 66px)", overflow: "auto" }}>
              <Content style={{ padding: 44, backgroundColor: "#F4F6FB" }}>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/inquiries">
                    <Inquiries loading={isIdle} />
                  </Route>
                  <Route exact path="/leads" component={Leads} />
                  <Route exact path="/demos" component={Demos} />
                  <Route exact path="/quotes" component={Quotes} />
                  <Route exact path="/sales" component={MySales} />
                  <Route exact path="/monitoring" component={Monetering} />
                  <Route exact path="/inactive-clients" component={DeadLeads} />
                  <Route exact path="/resale" component={Resale} />
                  <Route exact path="/opportunity" component={FutureOpportunity} />

                  <Route exact path="/profile" component={Profile} />

                  <Route exact path="/:company/:companyId/users" component={CompanyUsers} />
                  <Route exact path="/:company/users/:instructor" component={InstructorUsers} />
                  <Route
                    exact
                    path="/:company/:companyId/instructors"
                    component={CompanyInstructors}
                  />
                  {/* <Route exact path="/upload-application" component={UploadApplication} /> */}
                  <Route exact path="/message/:messageId" component={MessageThread} />
                  <Route exact path="/applications" component={Apps} />
                </Switch>
              </Content>
            </section>
            <FooterWrapper />
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default Sales;

const AccessDenied = () => {
  return (
    <div>
      <h1>You cannot access this functionality due to insufficient rights.</h1>
    </div>
  );
};
