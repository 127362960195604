import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, Radio, DatePicker, Tooltip } from "antd";
import { ButtonWrapper } from "components/buttons";
import {
  DatePickerWrapper,
  InputWrapper,
  SelectWrapper,
  TextAreaWrapper,
} from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { ALL_DEMOS, ALL_INQUIRY, ALL_LEADS, REGISTRATIONS, SUB_ADMIN } from "redux/constants";
import { approveUser } from "redux/actions";
import { PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { RadioGroup } from "components/input";
import ImageUploader from "components/uploader/ImageUploader";
import moment from "moment";
import { BsInfoCircleFill } from "react-icons/bs";

const initialState = {
  status: false,
  // reason: "",
  demoDate: "",
  demoDuration: "",
  demoClientFeedback: "",
  demoLink: "",
};

const DemoUpdateModal = (props) => {
  const { title, visible, onCancel, data, demoUpdateModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({ ...initialState });
  const { isLoading, mutateAsync } = useMutation(Mutations.updateDemo);

  const handleAddReasonInquiry = async () => {
    let getFormValues = form.getFieldsValue();
    const variables = {
      // reason: getFormValues.reason,
      demoDate: getFormValues.demoDate,
      demoDuration: getFormValues.demoDuration,
      demoClientFeedback: getFormValues.demoClientFeedback,
      demoLink: getFormValues.demoLink,
      demoReferenceImages: fileList,
      status: 0,
      demoId: data._id,
    };
    try {
      let getResponse = await mutateAsync(variables);
      dispatch({ type: ALL_DEMOS.EDIT_DATA, payload: getResponse.data.data });
      message.success("Updated Successfully");
      onClose();
      props.toggleLeadDetailModal();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    form.setFieldsValue({
      // reason: data.reason,
      demoDate: data.demoDetails?.demoDate ? moment(data.demoDetails?.demoDate) : "",
      demoDuration: data.demoDetails?.demoDuration,
      demoClientFeedback: data.demoDetails?.demoClientFeedback,
      demoLink: data.demoDetails?.demoLink,
    });

    setFileList(data.demoDetails?.demoReferenceImages);
  }, [visible]);

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={500}
    >
      <Form form={form} onFinish={handleAddReasonInquiry} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          {/* <Col span={24}>
            <p>
              <strong>Note:</strong> Please provide a detailed reason for{" "}
              {demoUpdateModal.title} this candidate
            </p>
            <Form.Item
              name="reason"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <TextAreaWrapper
                rows={3}
                name="reason"
                value={state.reason}
                placeholder="Reason"
                onChange={handleChange}
              />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <div className="label">Demo date*</div>

            <Form.Item
              name="demoDate"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <DatePicker
                value={state.demoDate}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                style={{
                  height: 40,
                  width: "100%",
                  borderRadius: 4,
                }}
                onChange={(e) =>
                  setState({ ...state, demoDate: moment(e).format("YYYY-MM-DD hh:mm a") })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="label">Demo duration Time*</div>
            <Form.Item
              name="demoDuration"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="demoDuration"
                placeholder="Demo duration Time"
                value={state.demoDuration}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="label">Demo Link*</div>
            <Form.Item
              name="demoLink"
              rules={[{ required: true, message: "Enter a valid URL", type: "url" }]}
            >
              <InputWrapper
                name="demoLink"
                suffix={
                  <Tooltip title="Make sure to add Https or Http before the link">
                    <BsInfoCircleFill style={{ color: "blue" }} />
                  </Tooltip>
                }
                placeholder="https://zoom.us/"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Client feedback*</div>

            <Form.Item
              name="demoClientFeedback"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <TextAreaWrapper
                rows={4}
                name="demoClientFeedback"
                value={state.demoClientFeedback}
                placeholder="Tell us what client said during the demo"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="label">Demo reference image* (Evidence)</div>
            <ImageUploader
              uploadLength={5}
              uploadPreset={"Demo_references"}
              loading={loading}
              setLoading={setLoading}
              fileList={fileList}
              setFileList={setFileList}
            />
          </Col>
          <Col span={24}>
            <ButtonWrapper
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
              disabled={loading || isLoading}
            >
              Save
            </ButtonWrapper>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default DemoUpdateModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
