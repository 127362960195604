import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pie } from "@ant-design/plots";
import ChartContainer from "./chartContainer";
import { Skeleton } from "antd";

const DonutPlot = (props) => {
  const { heading, data, achievedColor, targetColor, percentage, isLoading } = props;

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    color: ({ type }) => {
      if (type === "Achieved") {
        return achievedColor;
      }
      return targetColor;
    },
    radius: 1,
    innerRadius: 0.65,
    label: {
      type: "inner",
      offset: "-50%",
      content: "",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: `${percentage}%`,
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
  };

  return (
    <ChartContainer style={props.chartContainerStyle}>
      <h2 style={{ marginBottom: 32 }}>{heading}</h2>
      {isLoading ? (
        <Skeleton.Avatar active shape="circle" size={150} />
      ) : (
        <Pie {...config} style={{ height: 200, width: "100%" }} />
      )}
    </ChartContainer>
  );
};

export default DonutPlot;
