import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { REGISTRATIONS } from "redux/constants";
import { useMutation, errorHandler, Queries } from "apis/config";
import MessageList from "components/message";
/**
 * Header, Footer and Sidebar
 */
import SidebarWrapper from "../../components/sidebar/sidebarWrapper";
import FooterWrapper from "../../components/footer/footerWrapper";
// import HeaderWrapper from "../../components/header/headerWrapper";
/**
 * Views
 */
import Dashboard from "./Dashboard.jsx";
import Users from "./Users";
import Companies from "./Companies";
import Registrations from "./Registrations";
import CompanyUsers from "./CompanyUsers";
import CompanyInstructors from "./CompanyInstructors";
import KnowledgeCenter from "./KnowledgeCenter";
import InstructorUsers from "./InstructorUsers";
import MessageThread from "./MessageThread";
import Apps from "./Apps";
import UploadApplication from "./UploadApplication";
import UserManagement from "./SubAdmins";
import Profile from "views/Profile";
import CompanyPackages from "./CompanyPackages";

const { Content } = Layout;

const Shell = () => {
  const dispatch = useDispatch();
  const [collapsed, toggleCollapsed] = useState(false);
  const { mutateAsync: getAllRegistrations, isIdle } = useMutation(Queries.getAllRegistrations);
  const allRegistrations = useSelector((state) => state.registrations.allRegistrations);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getAllRegistrations();
        return data.data;
      } catch (err) {
        message.error(errorHandler(err));
      }
    };

    // getData().then((response) => {
    //   dispatch({ type: REGISTRATIONS.STORE, payload: response });
    // });

    // Set interval to get new registrations in every 1 minute
    const myInterval = setInterval(() => {
      getData().then((response) => {
        if (response.length >= 1) {
          notification.open({
            type: "info",
            message: "New Registration",
            description:
              "There is a new registration pending... Please check the registration tab.",
          });
          dispatch({ type: REGISTRATIONS.STORE, payload: response });
        }
      });
    }, 1000 * 60 * 5);

    return () => clearInterval(myInterval);
  }, []);

  return (
    <div>
      <MessageList />
      <Layout>
        <div style={{ maxHeight: "100vh" }}>
          <SidebarWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </div>

        <Layout>
          {/* <HeaderWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} /> */}
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              backgroundColor: "#F4F6FB",
            }}
          >
            <section style={{ minHeight: "calc(100vh - 66px)", overflow: "auto" }}>
              <Content style={{ padding: 44, backgroundColor: "#F4F6FB" }}>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/sub-admins" component={UserManagement} />
                  <Route exact path="/registrations">
                    <Registrations loading={isIdle} />
                  </Route>
                  <Route exact path="/individual-users" component={Users} />

                  <Route exact path="/companies" component={Companies} />
                  <Route exact path="/:company/:companyId/users" component={CompanyUsers} />
                  <Route exact path="/:company/users/:instructor" component={InstructorUsers} />
                  <Route exact path="/:company/:id/packages" component={CompanyPackages} />

                  <Route
                    exact
                    path="/:company/:companyId/instructors"
                    component={CompanyInstructors}
                  />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/knowledge-center" component={KnowledgeCenter} />
                  {/* <Route exact path="/upload-application" component={UploadApplication} /> */}
                  <Route exact path="/message/:messageId" component={MessageThread} />
                  <Route exact path="/applications" component={Apps} />
                </Switch>
              </Content>
            </section>
            <FooterWrapper />
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default Shell;

const AccessDenied = () => {
  return (
    <div>
      <h1>You cannot access this functionality due to insufficient rights.</h1>
    </div>
  );
};
