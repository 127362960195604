import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col, Tooltip } from "antd";
import { ALL_INQUIRY, ALL_LEADS, ALL_SALES, REGISTRATIONS } from "redux/constants";
import { CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import InquiryDetailModal from "components/modals/InquiryDetailModal";
import { HiCheck, HiX } from "react-icons/hi";
import CreateInquiryModal from "components/modals/createInquiryModal";
import LeadReasonModal from "components/modals/LeadReasonModal";
import LeadDetailModal from "components/modals/LeadDetailModal";
import CreateLeadModal from "components/modals/createLeadModal";
import AssignToSalepersonModal from "components/modals/AssignToSalepersonModal";
import { MdOutlineRotate90DegreesCcw } from "react-icons/md";
import SwitchableRangePicker from "components/input/SwitchableRangePicker";
import moment from "moment";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }

  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 6px 0px;
    width: 100px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
    text-align: center;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 6px 0px;
    width: 100px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
    text-align: center;
  }
`;

const Leads = () => {
  const dispatch = useDispatch();
  const { allLeads } = useSelector((state) => state.leads);
  const { data } = useSelector((state) => state.admin);

  // const [leadsRef, setLeadsRef] = useState("");
  const [createLeadModal, setCreateLeadModal] = useState(false);
  const [leadDetailModal, toggleLeadDetailModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("all");
  const [assignModal, setassignModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [type, setType] = useState("all");

  const [leadReasonModal, setLeadReasonModal] = useState({
    visible: false,
    title: "",
  });

  const leadsRef = useRef();
  const filterRef = useRef(2);

  const { mutateAsync: getAllLeads, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllLeadsManager
  );

  const { mutateAsync: getAllSales, isLoading: getAllSalesLoading } = useMutation(
    Queries.getAllSales
  );

  const handleSearch = (val) => {
    const filteredOptions = allLeads.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.country.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.gender.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  useEffect(() => {
    let startDate;
    let endDate;

    if (type !== "all") {
      startDate = moment(selectedDate).startOf(type).format("YYYY-MM-DD");
      endDate = moment(selectedDate).endOf(type).format("YYYY-MM-DD");
    }
    const filteredOptions = allLeads.filter((option) => {
      if (option.leadFrom.adminId == "" && filterByStatus == 0 && type !== "all") {
        return moment(option.createdAt).isBetween(startDate, endDate);
      } else if (option.leadFrom.adminId !== "" && filterByStatus == 1 && type !== "all") {
        return moment(option.createdAt).isBetween(startDate, endDate);
      } else if (filterByStatus == "all" && type == "all") {
        return option;
      } else if (filterByStatus == "all" && type !== "all") {
        return moment(option.createdAt).isBetween(startDate, endDate);
      } else if (filterByStatus == 0 && option.leadFrom.adminId == "" && type == "all") {
        return option;
      } else if (filterByStatus == 1 && option.leadFrom.adminId !== "" && type == "all") {
        return option;
      } else {
        return false;
      }

      // if (option.status == filterByStatus || option.leadFrom.adminId == filterByStatus) {
      //   return option;
      // } else if (filterByStatus == "all") {
      //   return allLeads;
      // } else {
      //   return false;
      // }
    });
    setTableData(filteredOptions);
  }, [filterByStatus, type, selectedDate]);

  useEffect(() => {
    setTableData(allLeads);
  }, [allLeads]);

  useEffect(() => {
    getAllLeads()
      .then(({ data }) => {
        dispatch({ type: ALL_LEADS.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  useEffect(() => {
    getAllSales()
      .then(({ data }) => {
        dispatch({ type: ALL_SALES.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, [assignModal]);

  return (
    <StyledTable>
      <CreateLeadModal
        title="Create Lead"
        visible={createLeadModal}
        onCancel={() => {
          setCreateLeadModal(false);
        }}
        data={leadsRef.current}
      />

      <LeadDetailModal
        leadEditable={false}
        title="Leads Details"
        visible={leadDetailModal}
        onCancel={() => {
          toggleLeadDetailModal(false);
        }}
        data={leadsRef.current}
        showForm={true}
      />

      {leadReasonModal.visible && (
        <LeadReasonModal
          title={`Reason for ${leadReasonModal.title}`}
          visible={leadReasonModal.visible}
          onCancel={() => {
            setLeadReasonModal(false);
          }}
          leadReasonModal={leadReasonModal}
          data={leadsRef.current}
          showForm={true}
        />
      )}

      {assignModal && (
        <AssignToSalepersonModal
          title={`Assign to saleperson`}
          from="leadFrom"
          idKey="leadId"
          visible={assignModal}
          onCancel={() => {
            setassignModal(false);
          }}
          assignModal={assignModal}
          data={leadsRef.current}
          showForm={true}
        />
      )}

      <div>
        <ContentHeader
          marginBottom="0px"
          title="Leads"
          count={allLeads?.length}
          tooltip="Create your own Lead"
          onSearch={(e) => {
            handleSearch(e);
          }}
          // onAdd={() => setCreateLeadModal(true)}
          onSelectChange={(e) => setFilterByStatus(e)}
          dropDownOptions={[
            { label: "View All", value: "all" },
            { label: "Unassigned", value: 0 },
            { label: "Assigned", value: 1 },
          ]}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            paddingRight: 36,
          }}
        >
          <SwitchableRangePicker
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            type={type}
            setType={setType}
          />
        </div>

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              accessor: "phoneNumber",
              sortable: false,
            },
            {
              Header: "Lead Type",
              accessor: "userType",
              sortable: false,
            },

            {
              Header: "Created At",
              Cell: ({ original }) => {
                return (
                  <p style={{ margin: 0 }}>
                    {moment(original?.createdAt).format("DD-MMM-YYYY")}
                  </p>
                );
              },
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      leadsRef.current = original;
                      // setLeadsRef(original);
                      toggleLeadDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Status",
              Cell: ({ original }) => {
                console.log(original);
                return (
                  <div>
                    {original.leadFrom.adminId == "" ? (
                      <div
                        style={{ width: 140, display: "flex", justifyContent: "space-between" }}
                      >
                        <span className="pending">Unassigned</span>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            leadsRef.current = original;
                            setassignModal(true);
                          }}
                        >
                          <MdOutlineRotate90DegreesCcw
                            title="Assign to saleperson"
                            style={{ fontSize: 20, color: "#135bed" }}
                          />
                        </div>
                        {/* <div
                          className="delete-icon"
                          onClick={() => {
                            leadsRef.current = original;
                            setInquiryReasonModal({ visible: true, title: "Not Interested" });
                          }}
                        >
                          <Tooltip title="Not Interested" placement="bottom">
                            <HiX style={{ fontSize: 20, color: "#f73838" }} />
                          </Tooltip>
                        </div> */}
                      </div>
                    ) : (
                      <div
                        style={{ width: 140, display: "flex", justifyContent: "space-between" }}
                      >
                        <span className="succeeded">Assigned</span>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            leadsRef.current = original;
                            setassignModal(true);
                          }}
                        >
                          <MdOutlineRotate90DegreesCcw
                            title="Assign to another saleperson"
                            style={{ fontSize: 20, color: "#135bed" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              },
              sortable: false,
            },
            // {
            //   Header: "Actions",
            //   Cell: ({ original }) => {
            //     return (
            //       <div>
            //         {original.status == 0 ? (
            //           <div
            //             style={{ width: 70, display: "flex", justifyContent: "space-between" }}
            //           >
            //             <div
            //               className="delete-icon"
            //               onClick={() => {
            //                 // leadsRef.current = original;
            //                 let {
            //                   address,
            //                   companyName,
            //                   gender,
            //                   country,
            //                   city,
            //                   trainingType,
            //                   userType,
            //                 } = original;
            //                 if (
            //                   address == "" ||
            //                   (userType == "Travel Agent" && companyName == "") ||
            //                   gender == "" ||
            //                   country == "" ||
            //                   city == "" ||
            //                   trainingType == ""
            //                 ) {
            //                   message.warning(
            //                     "Please fill this candidate's information before approving"
            //                   );
            //                 } else {
            //                   setLeadsRef(original);
            //                   setLeadReasonModal({ visible: true, title: "Interested" });
            //                 }
            //               }}
            //             >
            //               <Tooltip title="Interested (for demo)" placement="bottom">
            //                 <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
            //               </Tooltip>
            //             </div>
            //             <div
            //               className="delete-icon"
            //               onClick={() => {
            //                 // leadsRef.current = original;
            //                 setLeadsRef(original);
            //                 setLeadReasonModal({ visible: true, title: "Not Interested" });
            //               }}
            //             >
            //               <Tooltip title="Not Interested (for demo)" placement="bottom">
            //                 <HiX style={{ fontSize: 20, color: "#f73838" }} />
            //               </Tooltip>
            //             </div>
            //           </div>
            //         ) : original.status == 1 ? (
            //           <div>
            //             <strong style={{ margin: 0, color: "#4fc06b" }}>
            //               Approved (for demo)
            //             </strong>
            //           </div>
            //         ) : (
            //           <div>
            //             <strong style={{ margin: 0, color: "#f73838" }}>
            //               Not Approved (for demo)
            //             </strong>
            //           </div>
            //         )}
            //       </div>
            //     );
            //   },
            //   sortable: false,
            // },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Leads;
