import { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import DashboardCard from "components/cards/dashboardCard";
import RangePickerWrapper from "components/input/rangePicker";
import AreaPlot from "components/charts/areaPlot";
import ColumnPlot from "components/charts/columnPlot";
import DonutPlot from "components/charts/donutPlot";
import ContentHeader from "components/header/contentHeader";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import moment from "moment";

const Dashboard = () => {
  const [individualUsers, setIndividualUsers] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);

  const { mutateAsync: getAllCompanyUsers, isLoading: getCompanUsersLoading } = useMutation(
    Queries.getAllCompanyUsers
  );
  const { mutateAsync: getAllUsers, isLoading } = useMutation(Queries.getAllUsers);

  useEffect(() => {
    /**
     * Get all companies
     */
    getAllUsers()
      .then(({ data }) => {
        const mappedData = mapData(data.data);
        setIndividualUsers(mappedData);
      })
      .catch((err) => message.error(errorHandler(err)));

    /**
     * Get all users
     */
    getAllCompanyUsers()
      .then(({ data }) => {
        const mappedData = mapData(data.data);
        setCompanyUsers(mappedData);
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  return (
    <div>
      <ContentHeader title="Dashboard" />

      <DashboardCard />
      {/* <div style={{ margin: "32px 0" }}>
        <RangePickerWrapper size="large" />
      </div> */}

      <div style={{ margin: "32px 0" }}>
        <Row gutter={[24, 24]}>
          {/* <Col span={12}>
            <ColumnPlot
              heading="Total number of users."
              columnStyleRadius={[20, 20, 20, 20]}
              isGroup={true}
              xField="city"
              yField="value"
              seriesField="type"
              maxColumnWidth={12}
              dodgePadding={12}
              dataSheet={dataSheet}
              colorField="type" // or seriesField in some cases
              color={["#E0E6F6", "#135bed"]}
              meta={{ value: { type: "linear" } }}
            />
          </Col> */}

          <Col span={12}>
            <ColumnPlot
              heading="Individual users"
              xField="month"
              yField="value"
              color={"#F46A35"}
              data={individualUsers}
              // meta={{ value: { formatter: (val) => `${val}K`, type: "linear" } }}
            />
          </Col>

          <Col span={12}>
            <ColumnPlot
              heading="Company users"
              xField="month"
              yField="value"
              color={"#6F42C1"}
              data={companyUsers}
              // meta={{ value: { formatter: (val) => `${val}K`, type: "linear" } }}
            />
          </Col>

          {/* <Col span={14}>
            <AreaPlot
              heading="Total amount of transaction between all users."
              color="#00CFE8"
              dataSheet={Total_amount_of_transaction_between_all_users}
              meta={{ value: { formatter: (val) => `${val}K` } }}
            />
          </Col> */}

          {/* <Col span={12}>
            <DonutPlot heading="Total number of invoices generated vs paid" />
          </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;

const mapData = (data) => {
  const dataObject = data.reduce((initial, user) => {
    const createdAt = moment(user.createdAt).format("YYYY-MM-DD");

    if (initial[createdAt]) {
      initial[createdAt] += 1;
    } else {
      initial[createdAt] = 1;
    }

    return initial;
  }, {});

  let graphData = [];

  for (let key in dataObject) {
    graphData.push({
      month: key,
      value: dataObject[key],
    });
  }
  graphData.sort((a, b) => (a.month > b.month ? 1 : -1));
  return graphData;
};
