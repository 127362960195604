import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { errorHandler } from "helpers/errorHandler";
import { Queries } from "./queries";
import { Mutations } from "./mutations";

const adminModule = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADMIN_URL + "/api/admin",
  // baseURL: `http://192.168.88.8:5000/api/admin`,
});

const salesModule = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADMIN_URL + "/api/sales",
  // baseURL: `http://192.168.88.8:5000/api/sales`,
});

const managerModule = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADMIN_URL + "/api/manager",
  // baseURL: `http://192.168.88.8:5000/api/manager`,
});

const marketingModule = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADMIN_URL + "/api/marketing",
  // baseURL: `http://192.168.88.8:5000/api/marketing`,
});

const wsModule =
  // baseURL: process.env.REACT_APP_BACKEND_ADMIN_URL + "/api/marketing",
  `ws://192.168.1.123:5000/chat`;

export {
  useMutation,
  useQuery,
  errorHandler,
  adminModule,
  Queries,
  Mutations,
  salesModule,
  managerModule,
  marketingModule,
  wsModule,
};
