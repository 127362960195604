import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ContentHeader from "components/header/contentHeader";

const StyledDiv = styled.div`
  .white-background {
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    min-height: 60vh;
    border-radius: 6px;
  }
`;

const UploadApplication = () => {
  return (
    <StyledDiv>
      <ContentHeader title="Upload Applications" />
      <div className="white-background"></div>
    </StyledDiv>
  );
};

export default UploadApplication;
