import React from "react";
import { Route, Switch } from "react-router-dom";
// Register and Login Screen
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import { connect, useDispatch } from "react-redux";
import AdminShell from "./views/Shell";
import Sales from "views/sales";
import Manager from "views/manager";

import Cookies from "js-cookie";
import Marketing from "views/marketing";
import { messaging, onMessageListener } from "firebaseConfig";
import firebase from "./firebase";
import "firebase/messaging";
import { useState, useEffect } from "react";
import { message, notification } from "antd";

export const hanldeMessage = (payload, dispatch) => {
  notification.success({
    duration: 60,
    maxCount: 8,
    message: `${payload.notification.title}`,
    description: payload.notification.body,
    onClick: () => {
      console.log("Notification Clicked!");
    },
  });
  dispatch({ type: payload.data.notificationType.toUpperCase(), payload: payload });
};

const Routes = (props) => {
  const { isLoggedIn, data } = props;
  const dispatch = useDispatch();
  // let userRole = 4;
  const renderView = () => {
    if (isLoggedIn && data?.userRole == 1) {
      return <AdminShell />;
    } else if (isLoggedIn && data?.userRole == 2) {
      return <Manager />;
    } else if (isLoggedIn && data?.userRole == 3) {
      return <Sales />;
    } else if (isLoggedIn && data?.userRole == 4) {
      return <Marketing />;
    } else {
      return <Login />;
    }
  };

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      hanldeMessage(payload, dispatch);
      // dispatch({ type: payload.data.notificationType.toUpperCase(), payload: payload });
    });
  }

  return (
    <div className="App">
      <Switch>
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route path="/">{renderView()}</Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.admin.isLoggedIn,
    data: state.admin.data,
  };
};

export default connect(mapStateToProps)(Routes);
