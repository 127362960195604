import { useState } from "react";
import { Row, Col, Modal, message, Form } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { REGISTRATIONS } from "redux/constants";
import { approveUser } from "redux/actions";

const initialState = {
  transactionId: "",
  amount: "",
  totalInstructorLicense: "",
  totalUserLicense: "",
  ipAddress: "",
};

const ApproveModal = (props) => {
  const { title, visible, onCancel, user, closeParent } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({ ...initialState });

  const { isLoading, mutateAsync } = useMutation(Mutations.approveRegistration);

  const handleApprove = async () => {
    const companyVariables = {
      userId: user._id,
      transactionId: state.transactionId,
      amount: Number(state.amount),
      totalInstructorLicense: Number(state.totalInstructorLicense),
      totalUserLicense: Number(state.totalUserLicense),
      ipAddress: state.ipAddress,
    };

    const userVariables = {
      userId: user._id,
      transactionId: Number(state.transactionId),
      amount: Number(state.amount),
    };
    const variables = user.userRole === 1 ? userVariables : companyVariables;
    try {
      await mutateAsync(variables);
      onClose();
      closeParent();
      dispatch(approveUser(user));
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={400}
    >
      <Form form={form} onFinish={handleApprove} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={24}>
            <div className="label">Amount</div>
            <Form.Item
              name="Amount"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="amount"
                placeholder="Amount"
                value={state.amount}
                onChange={handleChange}
                type="number"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Transaction ID</div>
            <Form.Item
              name="Transaction ID"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="transactionId"
                placeholder="Transaction ID"
                value={state.transactionId}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          {user.userRole === 2 ? (
            <>
              <Col span={24}>
                <div className="label">Total instructor license</div>
                <Form.Item
                  name="Total instructor license"
                  rules={[{ required: true, message: "Required" }]}
                  style={{ margin: 0 }}
                >
                  <InputWrapper
                    name="totalInstructorLicense"
                    placeholder="Total instructor license"
                    value={state.totalInstructorLicense}
                    onChange={handleChange}
                    type="number"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <div className="label">Total user license</div>
                <Form.Item
                  name="Total user license"
                  rules={[{ required: true, message: "Required" }]}
                  style={{ margin: 0 }}
                >
                  <InputWrapper
                    name="totalUserLicense"
                    placeholder="Total user license"
                    value={state.totalUserLicense}
                    onChange={handleChange}
                    type="number"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <div className="label">IP Address</div>
                <Form.Item name="IP Address" style={{ margin: 0 }}>
                  <InputWrapper
                    name="ipAddress"
                    placeholder="IP Address"
                    value={state.ipAddress}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}

          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col span={12}></Col>
              <Col span={12}>
                <ButtonWrapper htmlType="submit" loading={isLoading}>
                  Done
                </ButtonWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default ApproveModal;

const StyledModal = styled(Modal)`
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
