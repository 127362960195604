import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, Radio, Tooltip, DatePicker } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper, TextAreaWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ALL_FUTURE_INQUIRY, ALL_INQUIRY, REGISTRATIONS, SUB_ADMIN } from "redux/constants";
import { approveUser } from "redux/actions";
import { PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { RadioGroup } from "components/input";
import ImageUploader from "components/uploader/ImageUploader";
import { BsInfoCircleFill } from "react-icons/bs";
import moment from "moment";
import { countryList } from "helpers/countries";

const initialState = {
  fullName: "",
  email: "",
  phoneNo: "",
  phoneError: "",
  country: "",
  companyWebsite: "",
  stage: "Prospect",
  createdAt: moment().format("YYYY-MM-DD"),
  message: "",
  inquiryFrom: "",
  referenceImages: [],
  referenceText: "",
};

const FutureOpportuniyCreateInquiryModal = (props) => {
  const { title, visible, onCancel } = props;
  const { data } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [referenceValue, setReferenceValue] = useState(1);

  const { isLoading, mutateAsync } = useMutation(Mutations.createOpportunity);

  const handleAddSubAdmin = async () => {
    const variables = {
      fullName: state.fullName,
      email: state.email,
      phoneNumber: state.phoneNo,
      country: state.country,
      companyWebsite: state.companyWebsite,
      createdAt: state.createdAt,
      stage: state.stage,
      message: state.message,
      referenceText: state.referenceText,
      referenceImages: fileList,
    };
    if (isValidPhoneNumber(state.phoneNo)) {
      try {
        let getResponse = await mutateAsync(variables);
        dispatch({ type: ALL_FUTURE_INQUIRY.ADD_NEW, payload: getResponse.data.data });
        message.success("Created successfully");
        onClose();
      } catch (err) {
        message.error(errorHandler(err));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  const countryNames = countryList
    .map((item) => {
      return Object.keys(item).map((country) => {
        return { label: country, value: country };
      });
    })
    .flat();

  let cityNames = [];
  if (state.country !== "") {
    cityNames = countryList
      .map((item) => {
        return item[state?.country]?.map((city) => {
          return { label: city, value: city };
        });
      })
      .flat();
  }

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={500}
    >
      <Form form={form} onFinish={handleAddSubAdmin} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={12}>
            <div className="label">Full Name*</div>
            <Form.Item
              name="Full Name"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="fullName"
                placeholder="Full Name"
                value={state.fullName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Phone Number*</div>
            <Form.Item
              name="Phone Number"
              rules={[{ required: true, message: "" }]}
              style={{ margin: 0 }}
            >
              <PhoneInputWrapper
                name="phoneNo"
                autoComplete="new-password"
                value={state.phoneNo}
                placeholder="Phone Number"
                onChange={handleChange}
                error={state.phoneError}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Email*</div>
            <Form.Item
              name="Email"
              rules={[{ required: true, message: "Required", type: "email" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="email"
                placeholder="Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Country*</div>
            <Form.Item
              name="Country"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <SelectWrapper
                showSearch={true}
                size="large"
                value={state.country}
                options={countryNames}
                onChange={(val) => setState({ ...state, country: val })}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Stages</div>
            <Form.Item
              name="Stages"
              rules={[{ required: false, message: "Select stage", type: "string" }]}
              style={{ margin: 0 }}
            >
              <SelectWrapper
                size="large"
                defaultValue="Prospect"
                value={state.stage}
                options={[
                  { label: "Prospect", value: "Prospect" },
                  { label: "First Contact", value: "First Contact" },
                  { label: "Demonstration ", value: "Demonstration " },
                  { label: "Interested", value: "Interested" },
                  { label: "Future Opportunity", value: "Future Opportunity" },
                ]}
                onChange={(e) => setState({ ...state, stage: e })}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Created At*</div>
            <Form.Item
              name="createdAt"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
              initialValue={moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            >
              <DatePicker
                value={state.createdAt}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current > moment(customDate, "YYYY-MM-DD");
                }}
                style={{
                  height: 40,
                  width: "100%",
                  borderRadius: 4,
                }}
                onChange={(e) =>
                  setState({ ...state, createdAt: moment(e).format("YYYY-MM-DD") })
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Company Website (URL)</div>
            <Form.Item
              name="Company website"
              rules={[{ required: false, message: "Enter a valid URL", type: "url" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="companyWebsite"
                value={state.companyWebsite}
                suffix={
                  <Tooltip title="Make sure to add Https or Http before the link">
                    <BsInfoCircleFill style={{ color: "blue" }} />
                  </Tooltip>
                }
                placeholder="Company website (URL)"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Message</div>
            <Form.Item
              name="Message"
              rules={[{ required: false, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <TextAreaWrapper
                rows={5}
                name="message"
                value={state.message}
                placeholder="Client's Message"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="label">Reference</div>

            <RadioGroup
              onChange={(e) => setReferenceValue(e.target.value)}
              value={referenceValue}
            >
              <Radio value={1}>Text</Radio>
              <Radio value={2}>Image</Radio>
            </RadioGroup>
            <div style={{ marginTop: 10 }}>
              {referenceValue == 1 ? (
                <Form.Item
                  name="reference"
                  rules={[{ required: false, message: "Required" }]}
                  style={{ margin: 0 }}
                >
                  <TextAreaWrapper
                    rows={3}
                    name="referenceText"
                    value={state.referenceText}
                    placeholder="Provide a Reference here.."
                    onChange={handleChange}
                  />
                </Form.Item>
              ) : (
                <ImageUploader
                  uploadLength={5}
                  uploadPreset={"opportunity_references"}
                  loading={loading}
                  setLoading={setLoading}
                  fileList={fileList}
                  setFileList={setFileList}
                />
              )}
            </div>
          </Col>

          <Col span={24}>
            <ButtonWrapper
              style={{ width: "100%" }}
              htmlType="submit"
              disabled={isLoading || loading}
              loading={isLoading}
              onClick={() => {
                if (!isValidPhoneNumber(state.phoneNo)) {
                  setState({
                    ...state,
                    phoneError: "Invalid phone number",
                  });
                } else {
                  setState({
                    ...state,
                    phoneError: "",
                  });
                }
              }}
            >
              Create
            </ButtonWrapper>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default FutureOpportuniyCreateInquiryModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
