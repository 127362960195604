import { SUB_ADMIN } from "redux/constants";

const initialState = { allSubAdmins: [] };

const subAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_ADMIN.STORE: {
      const { payload } = action;
      console.log("payload", payload);
      return { ...state, allSubAdmins: payload.reverse() };
    }

    case SUB_ADMIN.CHANGE_STATUS: {
      const { adminId, status } = action.payload;
      const currentAdmin = state.allSubAdmins.find((admin) => admin._id === adminId);
      currentAdmin.status = status;
      return { ...state };
    }

    case SUB_ADMIN.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allSubAdmins.findIndex(
        (user) => user._id === payload._id
      );
      state.allSubAdmins.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case SUB_ADMIN.ADD_NEW: {
      const { payload } = action;

      return { ...state, allSubAdmins: [payload, ...state.allSubAdmins] };
    }

    default:
      return state;
  }
};

export default subAdminReducer;
