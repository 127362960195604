import React, { useEffect, useState } from "react";
import { Select } from "antd";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  display: block;
  min-width: 150px;
  text-align: left;

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: #d1d1d1 !important;
    border-radius: 4px;
    outline: none !important;
    box-shadow: none !important;
  }

  /* &.ant-select-single .ant-select-selector .ant-select-selection-search {
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
  } */

  .ant-select,
  .ant-select-selector {
    outline: none !important;
    border-radius: 4px !important;
    border: ${(props) => (props.bordered == false ? "none" : "1px solid #d9d9d9")} !important;
    box-shadow: ${(props) => (props.bordered == false ? "none" : "none")} !important;
    font-size: 14px !important;
    font-weight: 500;
    ::placeholder {
      color: #d0d0d0;
      font-weight: 500;
      user-select: none;
    }
    &:hover,
    &:focus,
    &:active {
      transition: 0.3s ease;
      border: ${(props) => (props.bordered == false ? "none" : "1px solid #d9d9d9")} !important;
    }
  }
`;

const { Option } = Select;

const SelectWrapper = (props) => {
  const { placeholder, onChange, type, disabled, autoComplete } = props;
  const [options, setOptions] = useState(props.options);

  const handleSearch = (val) => {
    const filteredOptions = props.options.filter((option) => {
      return option.value.toLowerCase().includes(val.toLowerCase());
    });
    setOptions(filteredOptions);
  };

  const countrySearch = (val) => {
    const filteredOptions = props.options.filter((option) => {
      return option[`${type.label}`].toLowerCase().includes(val.toLowerCase());
    });
    setOptions(filteredOptions);
  };

  useEffect(() => setOptions(props.options), [props.options]);

  return (
    <StyledSelect
      onChange={onChange}
      showSearch={props.showSearch}
      disabled={props.disabled ? props.disabled : false}
      onSearch={(e) => {
        if (type.custom) {
          countrySearch(e);
        } else {
          handleSearch(e);
        }
      }}
      placeholder={placeholder}
      size={props.size}
      filterOption={false}
      value={props.value == "" ? null : props.value}
      defaultValue={props.defaultValue}
      loading={props.loading}
      className={props.className}
      style={props.style}
      bordered={props.bordered}
    >
      {type.custom
        ? options.map((option, index) => (
            <Option key={index} value={option[`${type?.value}`]}>
              {option[`${type?.label}`]}
            </Option>
          ))
        : options.map((option, index) => (
            <Option key={index} value={option?.value}>
              {option?.label}
            </Option>
          ))}
    </StyledSelect>
  );
};

SelectWrapper.defaultProps = {
  placeholder: "Select",
  options: [],
  onChange: () => {},
  type: {},
  autoComplete: "new-password",
  showSearch: false,
  size: "large",
};

export default SelectWrapper;
