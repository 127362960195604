import Cookies from "js-cookie";
import { Admin } from "../constants";

const initialState = {
  inquiry: 0,
  lead: 0,
  demo: 0,
  quotation: 0,
  sales: 0,
  monitoring: 0,
  resale: 0,
};

const notificationCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INQUIRY": {
      const { payload } = action;
      return { ...state, inquiry: payload ? state.inquiry + 1 : 0 };
    }

    case "LEAD": {
      const { payload } = action;
      return { ...state, lead: payload ? state.lead + 1 : 0 };
    }

    case "DEMO": {
      const { payload } = action;
      return { ...state, demo: payload ? state.demo + 1 : 0 };
    }

    case "QUOTATION": {
      const { payload } = action;
      return { ...state, quotation: payload ? state.quotation + 1 : 0 };
    }

    case "SALES": {
      const { payload } = action;
      return { ...state, sales: payload ? state.sales + 1 : 0 };
    }

    case "MONITORING": {
      const { payload } = action;
      return { ...state, monitoring: payload ? state.monitoring + 1 : 0 };
    }

    case "RESALE": {
      const { payload } = action;
      return { ...state, resale: payload ? state.resale + 1 : 0 };
    }

    default:
      return state;
  }
};

export default notificationCountReducer;
