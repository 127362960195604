import { adminModule, managerModule, marketingModule, salesModule } from "./config";
import Cookies from "js-cookie";

export const Mutations = {
  login: (body) => {
    return adminModule.post("/login", body);
  },
  forgotPassword: (body) => {
    return adminModule.post("/forgetPassword", body);
  },
  resetPassword: (body) => {
    return adminModule.post("/changePasswordAfterForget", body);
  },
  logout: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/logout", body, { headers });
  },
  approveRegistration: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/approve", body, { headers });
  },
  updateMeetingLink: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/updateCompanyProfile", body, { headers });
  },
  addTutorial: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/addTutorial", body, { headers });
  },
  deleteTutorial: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/deleteTutorial", body, { headers });
  },
  sendMessage: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/replyToUser", body, { headers });
  },
  replyToSale: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.post("/replyToSale", body, { headers });
  },
  addSubAdmin: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/createSubAdmin", body, { headers });
  },
  updateSubAdminStatus: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/updateSubAdmin", body, { headers });
  },

  updatePackageById: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/updatePackage", body, { headers });
  },

  // ------------UPDATE PROFILE----------------------
  updateMyProfile: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/updateMyProfile", body, { headers });
  },

  // ------------changel password----------------------
  changePassword: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/changePassword", body, { headers });
  },

  // ----------INQUIRIES--------------
  createInquiry: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/createInquiry", body, { headers });
  },

  // ----------FUTURE INQUIRIES--------------
  createOpportunity: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/createOpportunity", body, { headers });
  },

  updateInquiry: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/updateInquiry", body, { headers });
  },

  updateOpportunity: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/updateOpportunity", body, { headers });
  },

  // ------------LEADS----------------------
  updateLead: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/updateLead", body, { headers });
  },

  // ------------DEMO----------------------
  updateDemo: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/updateDemo", body, { headers });
  },

  // ------------QUOTE----------------------
  updateQuote: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/updateQuote", body, { headers });
  },

  // ------------Moitoring----------------------
  updateUserById: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/updateUserById", body, { headers });
  },

  // ===============RESALE============

  // -------------create resale---------------
  createResale: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post(`/createResale`, body, { headers });
  },

  // -------------UPDATE RESALE---------------
  updateResaleById: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post(`/updateResaleById`, body, { headers });
  },

  messageToManager: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return salesModule.post("/messageToManager", body, { headers });
  },

  // ------------MANAGER SECTION----------------------

  // ------------USER----------------------
  updateSaleById: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.post("/updateSaleById", body, { headers });
  },

  // ------------ASSIGN INQUIRY----------------------
  assignInquiryManually: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.post("/assignInquiryOrLead", body, { headers });
  },

  // ------------KPI SET----------------------
  setKPI: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.post("/setKPI", body, { headers });
  },

  // ------------UPDATE COMPLAINT----------------------
  updateComplaintById: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return managerModule.post("/updateComplaintById", body, { headers });
  },

  // ===========MARKETING===========
  // ----------leads--------------
  createLead: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return marketingModule.post("/createLead", body, { headers });
  },
  // --------------------------------------------------------------------
  changeUserStatusRequest: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/changeUserStatus", body, { headers });
  },
  editSubAdmin: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/updateSubAdminProfile", body, { headers });
  },

  exportUsersData: () => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.get("/exportAllUsers", { headers });
  },
  deleteApplication: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/deleteApplication", body, { headers });
  },
  updateIpAddress: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/updateCompanyIPAddress", body, { headers });
  },
  deleteRegistration: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/deleteRegisteration", body, { headers });
  },
  deleteIndividualUser: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/deleteIndividualUser", body, { headers });
  },
  deleteCompanyInstructor: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/deleteCompanyInstructor", body, { headers });
  },
  deleteCompanyUser: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/deleteCompanyUser", body, { headers });
  },
  deleteCompany: (body) => {
    const headers = { Authorization: Cookies.get("token") };
    return adminModule.post("/deleteCompany", body, { headers });
  },
};
