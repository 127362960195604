import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col, Tooltip } from "antd";
import { ALL_INQUIRY, ALL_LEADS, REGISTRATIONS } from "redux/constants";
import { CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import InquiryDetailModal from "components/modals/InquiryDetailModal";
import { HiCheck, HiX } from "react-icons/hi";
import CreateInquiryModal from "components/modals/createInquiryModal";
import LeadReasonModal from "components/modals/LeadReasonModal";
import LeadDetailModal from "components/modals/LeadDetailModal";
import CreateLeadModal from "components/modals/createLeadModal";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const Leads = () => {
  const dispatch = useDispatch();
  const { allLeads } = useSelector((state) => state.leads);
  const { data } = useSelector((state) => state.admin);

  const [leadsRef, setLeadsRef] = useState("");
  const [createLeadModal, setCreateLeadModal] = useState(false);
  const [leadDetailModal, toggleLeadDetailModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("viewAll");
  const [leadReasonModal, setLeadReasonModal] = useState({
    visible: false,
    title: "",
  });

  // const leadsRef = useRef();
  const filterRef = useRef(2);

  const { mutateAsync: getAllLeads, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllLeadsMarketing
  );

  const handleSearch = (val) => {
    const filteredOptions = allLeads.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.country.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.gender.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  useEffect(() => {
    const filteredOptions = allLeads.filter((option) => {
      if (option.status == filterByStatus || option.leadFrom.adminId == filterByStatus) {
        return option;
      } else if (filterByStatus == "viewAll") {
        return allLeads;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allLeads);
  }, [allLeads]);

  useEffect(() => {
    getAllLeads()
      .then(({ data }) => {
        dispatch({ type: ALL_LEADS.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  return (
    <StyledTable>
      <CreateLeadModal
        title="Create Lead"
        visible={createLeadModal}
        onCancel={() => {
          setCreateLeadModal(false);
        }}
        data={leadsRef}
      />

      <LeadDetailModal
        leadEditable={false}
        title="Leads Details"
        visible={leadDetailModal}
        onCancel={() => {
          toggleLeadDetailModal(false);
        }}
        data={leadsRef}
        showForm={true}
      />

      {leadReasonModal.visible && (
        <LeadReasonModal
          title={`Reason for ${leadReasonModal.title}`}
          visible={leadReasonModal.visible}
          onCancel={() => {
            setLeadReasonModal(false);
          }}
          leadReasonModal={leadReasonModal}
          data={leadsRef}
          showForm={true}
        />
      )}

      <div>
        <ContentHeader
          title="Leads"
          count={allLeads?.length}
          tooltip="Create your own Lead"
          onSearch={(e) => {
            handleSearch(e);
          }}
          onAdd={() => setCreateLeadModal(true)}
          // onSelectChange={(e) => setFilterByStatus(e)}
          // dropDownOptions={[
          //   { label: "View All", value: "viewAll" },
          //   { label: "Pending", value: 0 },
          //   { label: "Approved", value: 1 },
          //   { label: "Not Approved", value: 2 },
          //   // { label: "My Leads", value: data._id },
          // ]}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              accessor: "phoneNumber",
              sortable: false,
            },
            {
              Header: "Lead Type",
              accessor: "userType",
              sortable: false,
            },
            {
              Header: "Country",
              accessor: "country",
              sortable: false,
            },
            {
              Header: "Training Type",
              accessor: "trainingType",
              sortable: false,
            },
            {
              Header: "Lead From",
              // accessor: "trainingType",
              Cell: ({ original }) => {
                return <div>{original.leadFrom?.socialSite}</div>;
              },
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      // leadsRef.current = original;
                      setLeadsRef(original);
                      toggleLeadDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            // {
            //   Header: "Actions",
            //   Cell: ({ original }) => {
            //     return (
            //       <div>
            //         {original.status == 0 ? (
            //           <div
            //             style={{ width: 70, display: "flex", justifyContent: "space-between" }}
            //           >
            //             <div
            //               className="delete-icon"
            //               onClick={() => {
            //                 // leadsRef.current = original;
            //                 let {
            //                   address,
            //                   companyName,
            //                   gender,
            //                   country,
            //                   city,
            //                   trainingType,
            //                   userType,
            //                 } = original;
            //                 if (
            //                   address == "" ||
            //                   (userType == "Travel Agent" && companyName == "") ||
            //                   gender == "" ||
            //                   country == "" ||
            //                   city == "" ||
            //                   trainingType == ""
            //                 ) {
            //                   message.warning(
            //                     "Please fill this candidate's information before approving"
            //                   );
            //                 } else {
            //                   setLeadsRef(original);
            //                   setLeadReasonModal({ visible: true, title: "Interested" });
            //                 }
            //               }}
            //             >
            //               <Tooltip title="Interested (for demo)" placement="bottom">
            //                 <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
            //               </Tooltip>
            //             </div>
            //             <div
            //               className="delete-icon"
            //               onClick={() => {
            //                 // leadsRef.current = original;
            //                 setLeadsRef(original);
            //                 setLeadReasonModal({ visible: true, title: "Not Interested" });
            //               }}
            //             >
            //               <Tooltip title="Not Interested (for demo)" placement="bottom">
            //                 <HiX style={{ fontSize: 20, color: "#f73838" }} />
            //               </Tooltip>
            //             </div>
            //           </div>
            //         ) : original.status == 1 ? (
            //           <div>
            //             <strong style={{ margin: 0, color: "#4fc06b" }}>
            //               Approved (for demo)
            //             </strong>
            //           </div>
            //         ) : (
            //           <div>
            //             <strong style={{ margin: 0, color: "#f73838" }}>
            //               Not Approved (for demo)
            //             </strong>
            //           </div>
            //         )}
            //       </div>
            //     );
            //   },
            //   sortable: false,
            // },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Leads;
