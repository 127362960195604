import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, Radio } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper, TextAreaWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { ALL_INQUIRY, ALL_LEADS, REGISTRATIONS, SUB_ADMIN } from "redux/constants";
import { approveUser } from "redux/actions";
import { PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { RadioGroup } from "components/input";
import ImageUploader from "components/uploader/ImageUploader";
import { hanldeMessage } from "Routes";

const initialState = {
  status: false,
  reason: "",
};

const LeadReasonModal = (props) => {
  const { title, visible, onCancel, data, leadReasonModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({ ...initialState });
  const { isLoading, mutateAsync } = useMutation(Mutations.updateLead);

  const handleAddReasonInquiry = async () => {
    const variables = {
      reason: state.reason,
      status: leadReasonModal.title == "Interested" ? 1 : 2,
      leadId: data._id,
    };
    try {
      let getResponse = await mutateAsync(variables);
      dispatch({ type: ALL_LEADS.EDIT_DATA, payload: getResponse.data.data });
      // message.success("Updated Successfully");
      let notificationData = {
        notification: {
          title: "Updated Successfully",
          body: "Please check the details in demo tab",
        },
        data: { notificationType: "Demo" },
      };
      hanldeMessage(notificationData, dispatch);
      onClose();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={500}
    >
      <Form form={form} onFinish={handleAddReasonInquiry} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={24}>
            <p>
              <strong>Note: </strong>
              Please provide a detailed reason for why you think this candidate is{" "}
              {leadReasonModal.title} for demo
            </p>
            <Form.Item
              name="Reason"
              rules={[{ required: false, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <TextAreaWrapper
                rows={5}
                name="reason"
                value={state.reason}
                placeholder="Reason"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <ButtonWrapper style={{ width: "100%" }} htmlType="submit" loading={isLoading}>
              Save
            </ButtonWrapper>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default LeadReasonModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
