import { ALL_QUOTES } from "redux/constants";

const initialState = { allQuotes: [] };

const allQuotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_QUOTES.STORE: {
      const { payload } = action;
      return { ...state, allQuotes: payload };
    }

    case ALL_QUOTES.CHANGE_STATUS: {
      const { adminId, status } = action.payload;
      const currentAdmin = state.allQuotes.find((admin) => admin._id === adminId);
      currentAdmin.status = status;
      return { ...state };
    }

    case ALL_QUOTES.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allQuotes.findIndex((user) => user._id === payload._id);
      state.allQuotes.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_QUOTES.ADD_NEW: {
      const { payload } = action;

      return { ...state, allQuotes: [payload, ...state.allQuotes] };
    }

    default:
      return state;
  }
};

export default allQuotesReducer;
