import { ALL_RESALE } from "redux/constants";

const initialState = { allResale: [] };

const allResaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_RESALE.STORE: {
      const { payload } = action;
      return { ...state, allResale: payload };
    }

    case ALL_RESALE.CHANGE_STATUS: {
      const { saleId, status } = action.payload;
      const currentAdmin = state.allResale.find((admin) => admin._id === saleId);
      currentAdmin.autoAssignTasks = status;
      return { ...state };
    }

    case ALL_RESALE.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allResale.findIndex((user) => user._id === payload._id);

      console.log("payload", currentAdminIndex);
      state.allResale.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_RESALE.ADD_NEW: {
      const { payload } = action;

      return { ...state, allResale: [payload, ...state.allResale] };
    }

    default:
      return state;
  }
};

export default allResaleReducer;
