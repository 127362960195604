import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, errorHandler, Queries } from "apis/config";
import MessageList from "components/message";
/**
 * Header, Footer and Sidebar
 */
import SidebarWrapper from "../../components/marketingSidebar/sidebarWrapper";
import FooterWrapper from "../../components/footer/footerWrapper";
// import HeaderWrapper from "../../components/header/headerWrapper";
/**
 * Views
 */
import Dashboard from "./Dashboard.jsx";
import Inquiries from "./Inquiries";
import SalesPersons from "./SalesPersons";
import ManagerViewSalesStatistics from "./ManagerViewSalesStatistics";
import Profile from "views/Profile";
import Feedback from "./Feedback";
import ManagerMessageList from "components/message/managerMessageList";
import MessageThread from "./MessageThread";
import Leads from "./Leads";
import MarketingMessageList from "components/message/marketingMessageList";

const { Content } = Layout;

const Marketing = () => {
  const dispatch = useDispatch();
  const [collapsed, toggleCollapsed] = useState(false);
  const { mutateAsync: getAllRegistrations, isIdle } = useMutation(Queries.getAllRegistrations);
  const allRegistrations = useSelector((state) => state.registrations.allRegistrations);

  return (
    <div>
      <MarketingMessageList />
      <Layout>
        <div style={{ maxHeight: "100vh" }}>
          <SidebarWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </div>

        <Layout>
          {/* <HeaderWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} /> */}
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              backgroundColor: "#F4F6FB",
            }}
          >
            <section style={{ minHeight: "calc(100vh - 66px)", overflow: "auto" }}>
              <Content style={{ padding: 44, backgroundColor: "#F4F6FB" }}>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/salesperson">
                    <SalesPersons loading={isIdle} />
                  </Route>
                  <Route exact path="/leads" component={Leads} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/feedback" component={Feedback} />
                  <Route
                    exact
                    path="/report/:fullName/:id"
                    component={ManagerViewSalesStatistics}
                  />
                  <Route exact path="/message/:messageId" component={MessageThread} />
                </Switch>
              </Content>
            </section>
            <FooterWrapper />
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default Marketing;

const AccessDenied = () => {
  return (
    <div>
      <h1>You cannot access this functionality due to insufficient rights.</h1>
    </div>
  );
};
