import { useState } from "react";
import { Row, Col, Modal, message, Form, Divider } from "antd";
import { AddButton, ButtonWrapper } from "components/buttons";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const ConfirmationModal = (props) => {
  const { title, visible, onCancel, data, closeParent } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isLoading, mutateAsync } = useMutation(Mutations.createResale);

  const handleApprove = async () => {
    try {
      let getResponse = await mutateAsync({ userId: data._id });
      message.success(`You can view ${data.fullName} in Resale tab`);
      // dispatch({ type: ALL_LEADS.EDIT_DATA, payload: getResponse.data.data });
      onClose();
    } catch (err) {
      onClose();
      message.error(errorHandler(err));
    }
  };

  const onClose = () => {
    onCancel();
  };

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={350}
    >
      <p align="center">
        Are you sure you want to resale <br /> new license(s) to{" "}
        <strong>{data?.fullName}</strong>?
      </p>

      <Divider />
      <Row justify={"space-between"}>
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          <ButtonWrapper onClick={() => onClose()}>Cancel</ButtonWrapper>
        </Col>
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          <ButtonWrapper onClick={() => handleApprove()} loading={isLoading}>
            Yes
          </ButtonWrapper>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ConfirmationModal;

const StyledModal = styled(Modal)`
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
