import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import UserDetailModal from "components/modals/userDetailModal";
import { MdOutlineLocationOff, MdOutlineLocationOn } from "react-icons/md";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { Tooltip, message } from "antd";
import { Queries, useMutation, errorHandler, Mutations } from "apis/config";
// import AddNewPackage from "components/modals/addPackageModal";
import { BiTrash } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { TiTick } from "react-icons/ti";
import { HiCheck, HiX } from "react-icons/hi";
import ViewPackage from "components/modals/packageViewModal";
import { ALL_PACKAGES } from "redux/constants";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const CompanyPackages = (props) => {
  const { loading } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  //   const [allPackages, setAllPackages] = useState([]);
  const [userModal, toggleUserModal] = useState(false);
  const [addPackageModal, toggleAddPackageModal] = useState(false);

  const packages = useRef();
  const mode = useRef("View Package");

  const { allPackages } = useSelector((state) => state.packages);

  const { mutateAsync: getCompanyPackages, isLoading: getPackagesLoading } = useMutation(
    Queries.getCompanyPackages
  );

  const { isLoading: updateLoading, mutateAsync: updatePackageById } = useMutation(
    Mutations.updatePackageById
  );

  useEffect(() => {
    getCompanyPackages(params.id)
      .then(({ data }) => {
        // setAllPackages(data.data);
        dispatch({ type: ALL_PACKAGES.STORE, payload: data.data });
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  const handleUpdate = async (packageId) => {
    try {
      let getResponse = await updatePackageById({
        packageId: packageId.id._id,
        status: packageId.status,
      });

      dispatch({ type: ALL_PACKAGES.EDIT_DATA, payload: getResponse.data.data });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledTable>
      <UserDetailModal
        title="User details"
        visible={userModal}
        onCancel={() => toggleUserModal(false)}
        data={packages.current}
      />
      <ViewPackage
        mode={mode}
        currentPackage={packages.current}
        visible={addPackageModal}
        onCancel={() => toggleAddPackageModal(false)}
      />
      <div>
        <ContentHeader
          title="Packages"
          //   onAdd={() => {
          //     if (allPackages.length == 2) {
          //       message.error("You can not create more than 2 packages");
          //     } else {
          //       mode.current = "Add Package";
          //       toggleAddPackageModal(true);
          //     }
          //   }}
        />
        <TableWrapper
          data={allPackages}
          loading={getPackagesLoading || updateLoading}
          columns={[
            {
              Header: "Package Name",
              Cell: ({ original }) => {
                return <div style={{}}>{original.packageName}</div>;
              },
              sortable: false,
            },
            {
              Header: "Package For",
              Cell: ({ original }) => {
                return <div style={{}}>{original.packageType}</div>;
              },
              sortable: false,
            },
            {
              Header: "Amount",
              Cell: ({ original }) => {
                return <div style={{}}>{original.price}</div>;
              },
              sortable: false,
            },
            {
              Header: "Duration",
              Cell: ({ original }) => {
                return <div style={{}}>{original.packageDuration}</div>;
              },
              sortable: false,
            },

            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableButton
                      onClick={() => {
                        packages.current = original;
                        mode.current = "View Package";
                        toggleAddPackageModal(true);
                      }}
                    >
                      view
                    </TableButton>

                    {/* <StyledDiv
                      onClick={() => {
                        packages.current = original;
                        deleteLoading ? null : handleDelete(original._id);
                      }}
                    >
                      {deleteLoading && original._id == packages.current._id ? (
                        <LoadingOutlined
                          title="Loading..."
                          style={{ fontSize: 24, color: "#135bed" }}
                        />
                      ) : (
                        <BiTrash title="Delete" style={{ fontSize: 24, color: "red" }} />
                      )}
                    </StyledDiv> */}
                  </div>
                );
              },
              sortable: false,
            },

            {
              Header: "Actions",
              Cell: ({ original }) => {
                return (
                  <div>
                    {original.status == 0 ? (
                      <div
                        style={{ width: 70, display: "flex", justifyContent: "space-between" }}
                      >
                        <div
                          className="delete-icon"
                          onClick={() => {
                            handleUpdate({ id: (packages.current = original), status: 1 });
                          }}
                        >
                          <Tooltip title="Approve" placement="bottom">
                            <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                          </Tooltip>
                        </div>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            handleUpdate({ id: (packages.current = original), status: 2 });
                          }}
                        >
                          <Tooltip title="Reject" placement="bottom">
                            <HiX style={{ fontSize: 20, color: "#f73838" }} />
                          </Tooltip>
                        </div>
                      </div>
                    ) : original.status == 1 ? (
                      <div>
                        <strong style={{ margin: 0, color: "#4fc06b" }}>Approved</strong>
                      </div>
                    ) : (
                      <div>
                        <strong style={{ margin: 0, color: "#f73838" }}>Rejected</strong>
                      </div>
                    )}
                  </div>
                );
              },
              sortable: false,
            },

            // {
            //   Header: "Status",
            //   Cell: ({ original }) => {
            //     if (original.status == 0) {
            //       return (
            //         <div>
            //           <StyledDiv>
            //             <TiTick style={{ color: "green", fontSize: 22 }} />
            //           </StyledDiv>
            //         </div>
            //       );
            //     } else if (original.status == 1) {
            //       return <div style={{ color: "#008000" }}>Approved</div>;
            //     } else {
            //       return <div style={{ color: "red" }}>Rejected</div>;
            //     }
            //   },
            //   sortable: false,
            // },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default CompanyPackages;
