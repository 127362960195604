import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { REGISTRATIONS, SUB_ADMIN } from "redux/constants";
import { approveUser } from "redux/actions";
import { PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";

const initialState = {
  userRole: 2,
  fullName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  password: "",
  phoneError: "",
};

const CreateUserModal = (props) => {
  const { title, visible, onCancel } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({ ...initialState });

  const { isLoading, mutateAsync } = useMutation(Mutations.addSubAdmin);

  const handleAddSubAdmin = async () => {
    const variables = {
      userRole: state.userRole,
      fullName: state.fullName,
      email: state.email,
      phoneNumber: state.phoneNo,
      password: state.password,
    };
    if (isValidPhoneNumber(state.phoneNo)) {
      try {
        let getResponse = await mutateAsync(variables);
        dispatch({ type: SUB_ADMIN.ADD_NEW, payload: getResponse.data.data });
        message.success("Created successfully");
        onClose();
      } catch (err) {
        message.error(errorHandler(err));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={400}
    >
      <Form form={form} onFinish={handleAddSubAdmin} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={24}>
            <div className="label">User Role</div>
            <SelectWrapper
              placeholder="Select"
              size="large"
              options={[
                { label: "Manager", value: 2 },
                { label: "Sales", value: 3 },
                { label: "Marketing", value: 4 },
              ]}
              onChange={(e) => setState({ ...state, userRole: e })}
            />
          </Col>
          <Col span={24}>
            <div className="label">Full Name</div>
            <Form.Item
              name="Full Name"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="fullName"
                placeholder="Full Name"
                value={state.fullName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <div className="label">Last Name</div>
            <Form.Item
              name="Last Name"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="lastName"
                placeholder="Last Name"
                value={state.lastName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <div className="label">Email</div>
            <Form.Item
              name="Email"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="email"
                placeholder="Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Phone Number</div>
            <Form.Item
              name="Phone Number"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <PhoneInputWrapper
                name="phoneNo"
                autoComplete="new-password"
                value={state.phoneNo}
                placeholder="Phone Number"
                onChange={handleChange}
                error={state.phoneError}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Password</div>
            <Form.Item
              name="Password"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper.Password
                name="password"
                placeholder="Password"
                value={state.password}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <ButtonWrapper
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
              onClick={() => {
                if (!isValidPhoneNumber(state.phoneNo)) {
                  setState({
                    ...state,
                    phoneError: "Invalid phone number",
                  });
                } else {
                  setState({
                    ...state,
                    phoneError: "",
                  });
                }
              }}
            >
              Create
            </ButtonWrapper>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default CreateUserModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
