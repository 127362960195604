import React, { useEffect, useRef, useState } from "react";
import { message, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { USER } from "redux/constants";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import styled from "styled-components";
import { CgTrash } from "react-icons/cg";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import RegistrationDetailModal from "components/modals/registrationDetailModal";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const Users = () => {
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.users);

  const [detailModal, toggleDetailModal] = useState(false);
  const [confimationDeleteModal, setConfimationDeleteModal] = useState({
    visible: false,
    userId: "",
  });

  const user = useRef();

  const { mutateAsync: getAllUsers, isLoading } = useMutation(Queries.getAllUsers, {
    onSuccess: ({ data }) => {
      console.log("Registrations Data: ", data.data);
      dispatch({ type: USER.STORE, payload: data.data });
    },
    onError: (err) => message.error(errorHandler(err)),
  });

  useEffect(() => {
    getAllUsers();
  }, []);

  const { mutateAsync: deleteIndividualUser, isLoading: deleteIndividualUserLoading } =
    useMutation(Mutations.deleteIndividualUser);

  const handleDeleteIndividualUser = async () => {
    try {
      const { data } = await deleteIndividualUser({
        userId: confimationDeleteModal.userId,
      });
      setConfimationDeleteModal({ visible: false });
      dispatch({ type: USER.STORE, payload: data.data });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledTable>
      <Modal
        visible={confimationDeleteModal.visible}
        title={`Are you sure?`}
        footer={
          <ButtonWrapper
            style={{ width: "50%" }}
            onClick={handleDeleteIndividualUser}
            loading={deleteIndividualUserLoading}
          >
            Delete
          </ButtonWrapper>
        }
        onCancel={() => setConfimationDeleteModal({ visible: false })}
      >
        <div style={{ display: "grid", textAlign: "center" }}>
          <ExclamationCircleOutlined
            style={{ fontSize: 54, color: "#ffbe04", marginBottom: 22 }}
          />
          <p style={{ margin: 0 }}>
            <strong>Note:</strong> All the history of this user will be deleted!
          </p>
        </div>
      </Modal>

      <RegistrationDetailModal
        title="User details"
        visible={detailModal}
        onCancel={() => toggleDetailModal(false)}
        data={user.current}
      />
      <div>
        <ContentHeader title="Individual Users" />

        <TableWrapper
          tableData={allUsers}
          loading={isLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              accessor: "phoneNumber",
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      user.current = original;
                      toggleDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Delete",
              Cell: ({ original }) => {
                return (
                  <div style={{ width: 45, display: "flex", justifyContent: "center" }}>
                    <div
                      className="delete-icon"
                      title="Delete"
                      onClick={() => {
                        setConfimationDeleteModal({ visible: true, userId: original._id });
                      }}
                    >
                      <CgTrash style={{ fontSize: 18, color: "#135bed" }} />
                    </div>
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Users;
