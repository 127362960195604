import { useEffect, useRef, useState } from "react";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper } from "components/input";
import styled from "styled-components";
import { Row, Col, message, Skeleton } from "antd";
import { SendOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MessageLoading from "components/skeleton/messageLoading";

const MessageThread = () => {
  const params = useParams();
  const [recipient, setRecipient] = useState({ fullName: "", pictureURL: "" });
  const [messageText, setMessageText] = useState("");
  const userRef = useRef();
  const [allMessages, setAllMessages] = useState([]);

  const { mutateAsync: getAllMessages, isLoading: getMessageLoading } = useMutation(
    Queries.getMessagesById
  );
  const { mutateAsync: getAllMessagesToManager } = useMutation(
    Queries.getAllMyMessagesToManager
  );

  useEffect(() => {
    if (params.messageId == "messageToManager") {
      getAllMessagesToManager()
        .then(({ data }) => {
          if (data.data) {
            userRef.current = data.data.managerId;
            setRecipient(userRef.current);
            const mappedData = mapMessageDataForManager(data.data);
            setAllMessages(mappedData);
          }
        })
        .catch((err) => message.error(errorHandler(err)));
    } else {
      getAllMessages({ messageId: params.messageId })
        .then(({ data }) => {
          const mappedData = mapMessageData(data.data[0]);
          userRef.current = data.data[0].userId;
          setRecipient({
            fullName: userRef.current.companyName,
            pictureURL: userRef.current.pictureURL,
          });
          setAllMessages(mappedData);
        })
        .catch((err) => message.error(errorHandler(err)));
    }
  }, [params]);

  const { mutateAsync: sendMessageMutation, isLoading } = useMutation(Mutations.sendMessage);
  const { mutateAsync: sendMessageToManager, isLoading: managerLoading } = useMutation(
    Mutations.messageToManager
  );

  const sendMessage = async () => {
    if (params.messageId == "messageToManager") {
      try {
        const { data } = await sendMessageToManager({
          // userId: userRef.current._id,
          message: messageText,
        });
        userRef.current = data.data.managerId;
        setRecipient(userRef.current);
        const mappedData = mapMessageDataForManager(data.data);
        setMessageText("");
        setAllMessages(mappedData);
      } catch (err) {
        message.error(errorHandler(err));
      }
    } else {
      try {
        const { data } = await sendMessageMutation({
          userId: userRef.current._id,
          message: messageText,
        });
        const mappedData = mapMessageData(data.data);
        setMessageText("");
        setAllMessages(mappedData);
      } catch (err) {
        message.error(errorHandler(err));
        console.log("Errrrrrr", err);
      }
    }
  };

  return (
    <StyledThread>
      <div className="white-background">
        <section className="thread-heading">
          {getMessageLoading ? (
            <Skeleton.Image active={true} />
          ) : (
            <>
              <img className="profile-image" src={recipient?.pictureURL?.url} />
              <div className="name">{recipient?.fullName}</div>
            </>
          )}
        </section>

        <section style={{ height: "calc(100vh - 380px)", overflow: "auto" }}>
          {getMessageLoading ? (
            <MessageLoading />
          ) : (
            <>
              {allMessages.map((item) => (
                <Row key={item._id} className="message-wrapper">
                  {item.user ? (
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                      <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                        <img src={item.pictureURL?.url} className="user-profile" />
                      </Col>
                      <Col span={23}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="name">{item.fullName}</div>
                          <div className="little-dot"></div>
                          <div className="message-time">
                            {moment(item.createdAt).format("ddd, DD MMM YY")}
                          </div>
                        </div>
                        <p style={{ borderTopLeftRadius: 0 }} className={`message-body`}>
                          {item.message}
                        </p>
                      </Col>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col span={23}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ textAlign: "end" }} className="message-time">
                            {moment(item.createdAt).format("ddd, DD MMM YY")}
                          </div>
                          <div className="little-dot"></div>
                          <div className="name">{item.fullName}</div>
                        </div>
                        <p
                          style={{ float: "right", borderTopRightRadius: 0 }}
                          className={`message-body blue`}
                        >
                          {item.message}
                        </p>
                      </Col>
                      <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                        <img src={item.pictureURL?.url} className="user-profile" />
                      </Col>
                    </div>
                  )}
                </Row>
              ))}
            </>
          )}
        </section>

        <div style={{ height: 88, backgorundColor: "#fff", borderTop: "1px solid #d9d9d9" }}>
          <section className="reply">
            <InputWrapper
              placeholder="Type your message"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  sendMessage();
                }
              }}
            />
            <ButtonWrapper
              size="large"
              style={{ width: 200, marginLeft: 12 }}
              disabled={isLoading || managerLoading || messageText.trim().length === 0}
              onClick={sendMessage}
            >
              Send{" "}
              {isLoading || managerLoading ? (
                <LoadingOutlined style={{ marginLeft: 16 }} />
              ) : (
                <SendOutlined style={{ marginLeft: 16 }} />
              )}
            </ButtonWrapper>
          </section>
        </div>
      </div>
    </StyledThread>
  );
};

export default MessageThread;

const StyledThread = styled.div`
  background-color: #fff;
  min-height: 600px;
  position: relative;

  .white-background {
    background-color: #fff;
    padding: 10px 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    // min-height: 60vh;
    border-radius: 6px;
  }

  .thread-heading {
    // height: 72px;
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #d9d9d9;
    font-size: 32px;
    font-weight: 600;

    .profile-image {
      height: 64px;
      width: 64px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .message-wrapper {
    margin: 12px;
    .user-profile {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      margin: 8px 12px 0 12px;
    }
    .name {
      font-weight: 600;
    }
    .little-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000000b0;
      margin: 0 6px;
    }
    .message-body {
      margin: 0;
      background-color: #f4f4f4;
      padding: 20px;
      border-radius: 20px;
      max-width: fit-content;
      min-width: 500px;
      &.blue {
        background-color: #135bed;
        color: #fff;
      }
    }
    .message-time {
      color: #977a7a;
      font-weight: 600;
      font-size: 10px;
    }
  }

  .reply {
    display: flex;
    align-items: center;
    padding: 24px 0px;
    border-top: 1px solid #d9d9d9;
  }

  .ant-skeleton-element .ant-skeleton-image {
    border-radius: 100%;
  }
`;

const mapMessageData = (data) => {
  const messages = data.messages || [];
  const adminId = data.adminId;
  const userId = data.userId;

  const mappedData = messages?.map((item) => ({
    id: item._id,
    fullName: item.user ? userId.companyName : adminId.fullName,
    pictureURL: item.user ? userId.pictureURL : adminId.pictureURL,
    createdAt: item.createdAt,
    message: item.message,
    user: item.user,
  }));

  return mappedData;
};

const mapMessageDataForManager = (data) => {
  const messages = data.messages || [];
  const managerId = data.managerId;
  const userId = data.saleId;

  const mappedData = messages?.map((item) => ({
    id: item._id,
    fullName: item.sale ? userId.fullName : managerId.fullName,
    pictureURL: item.sale ? userId.pictureURL : managerId.pictureURL,
    createdAt: item.createdAt,
    message: item.message,
    user: item.manager,
  }));

  return mappedData;
};
