import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col, Avatar, Form, message, Skeleton } from "antd";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { InputWrapper, SelectWrapper, SwitchWrapper, TextAreaWrapper } from "components/input";
import { ButtonWrapper } from "components/buttons";
import { useSelector } from "react-redux";
import { PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Queries } from "apis/queries";
import { errorHandler } from "helpers/errorHandler";
import { useMutation } from "react-query";
import { Mutations } from "apis/mutations";
import ImageUploader from "components/uploader/ImageUploader";

const StyledDiv = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 600;
  }
  .main-container {
    padding: 2% 10%;
    .white-area {
      width: 100%;
      background-color: #fff;
      padding: 5% 15%;
      border-radius: 8px;
      .label {
        margin-bottom: 6px;
        font-size: 13px;
        font-weight: 600;
      }
      .profile-pic {
        height: 120px;
        width: 120px;
        /* background: #222; */
        /* background: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5kyNQIKoyUGJL5ZKSoA5EuHz7rz55ADZ3njzd5VcVTAudAA8Yw9iUyzXsAbOOpUfsk_M&usqp=CAU"); */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
      }

      .plus {
        position: absolute;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 4px solid #fff;
        background-color: #999999;
        bottom: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 22px;
      }
    }
  }
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
`;

const initialState = {
  fullName: "",
  email: "",
  gender: "",
  phoneError: "",
  phoneNumber: "",
  companyName: "",
  bio: "",
  postalCode: "",
  country: "",
  city: "",
  pictureURL: [],
  showNotification: true,
};

const passwordInitialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const Profile = () => {
  const [form] = Form.useForm();
  const profileDataRef = useRef();
  const { data } = useSelector((state) => state.admin);

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [passwordState, setPasswordState] = useState({ ...passwordInitialState });
  const [switchWrapper, setSwitchWrapper] = useState(1);
  const { mutateAsync: getMyProfile, isLoading } = useMutation(Queries.getMyProfile);

  const { isLoading: updateProfileLoad, mutateAsync } = useMutation(Mutations.updateMyProfile);
  const handleUpdateProfile = async () => {
    if (isValidPhoneNumber(state.phoneNumber)) {
      setState({ ...state, phoneError: "" });
      let paramsData = {
        fullName: state.fullName,
        gender: state.gender,
        phoneError: state.phoneNumber,
        phoneNumber: state.phoneNumber,
        companyName: state.companyName,
        bio: state.bio,
        postalCode: state.postalCode,
        country: state.country,
        city: state.city,
        pictureURL: fileList.hasOwnProperty("url") ? fileList : state.pictureURL,
        showNotification: state.showNotification,
      };
      try {
        let getResponse = await mutateAsync(paramsData);
        if (getResponse) {
          message.success("Profile updated successfully");
          profileDataRef.current = getResponse.data.data;
          setState({ ...getResponse.data.data });
        }
      } catch (err) {
        message.error(errorHandler(err));
        setFileList([]);
      }
    } else {
      setState({ ...state, phoneError: "Please enter valid phone number" });
    }
  };

  const { isLoading: passwordLoading, mutateAsync: changePasswordFunc } = useMutation(
    Mutations.changePassword
  );

  const changePasswordFunction = async () => {
    let paramsData = {
      currentPassword: passwordState.currentPassword,
      newPassword: passwordState.newPassword,
    };
    try {
      let getResponse = await changePasswordFunc(paramsData);
      if (getResponse) {
        message.success("New password updated successfully");
        setPasswordState(passwordInitialState);
        form.setFieldsValue({
          "Current Password": "",
          "New Password": "",
          "Confirm Password": "",
        });
      }
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const passwordHandleChange = (e) => {
    const { name, value } = e.target;
    setPasswordState({ ...passwordState, [name]: value });
  };

  useEffect(() => {
    if (fileList?.hasOwnProperty("url")) {
      handleUpdateProfile();
    }
  }, [fileList]);

  useEffect(() => {
    getMyProfile()
      .then(({ data }) => {
        const { data: profileData } = data;
        profileDataRef.current = profileData;
        setState({ ...profileData });
        form.setFieldsValue({
          "Full Name": profileData.fullName,
          Email: profileData.email,
          "Phone Number": profileData.phoneNumber,
          Gender: profileData.gender,
          "Company Name": profileData.companyName,
          Country: profileData.country,
          City: profileData.city,
          PostalCode: profileData.postalCode,
          bio: profileData.bio,
        });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  const checkDesignation = () => {
    if (data.userRole == 1) {
      return "ADMIN";
    } else if (data.userRole == 2) {
      return "GENERAL MANAGER";
    } else if (data.userRole == 3) {
      return "SALES MANAGER";
    } else if (data.userRole == 4) {
      return "MARKETING MANAGER";
    }
  };

  return (
    <StyledDiv>
      <div className="main-container">
        <h1>Account</h1>
        <h3>Set your account settings down below</h3>
        <MultiSwitchWrapper
          options={[
            { label: "Profile", value: 1 },
            { label: "Password", value: 2 },
          ]}
          onChange={(val) => {
            setSwitchWrapper(val);
          }}
          style={{ margin: "3% 0" }}
        />
        <Form
          form={form}
          onFinish={switchWrapper == 1 ? handleUpdateProfile : changePasswordFunction}
          validateTrigger="onFinish"
        >
          <div className="white-area">
            {switchWrapper == 1 ? (
              <Row gutter={[40, 20]} justify={"center"}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  {updateProfileLoad || isLoading ? (
                    <Skeleton.Image active={true} className="profile-pic" />
                  ) : (
                    <div
                      title="Upload Profile Picture"
                      className="profile-pic"
                      style={{
                        backgroundImage: `url(${state?.pictureURL?.url}
                      )`,
                      }}
                    >
                      {/* <div className="plus"> */}{" "}
                      <ImageUploader
                        profileImg={true}
                        uploadLength={1}
                        uploadPreset={"profile_pictures"}
                        loading={loading}
                        setLoading={setLoading}
                        fileList={fileList}
                        setFileList={setFileList}
                        icon={<PlusOutlined style={{ color: "#fff" }} />}
                      />
                      {/* </div> */}
                    </div>
                  )}
                </div>
                <Col span={24} align="right">
                  <div style={{ display: "inline-block", textAlign: "start" }}>
                    <div className="label">
                      Designation: <span className="succeeded">{checkDesignation()}</span>
                    </div>
                    <div className="label" style={{ marginTop: 20 }}>
                      Push Notification:{" "}
                      <SwitchWrapper
                        loading={updateProfileLoad}
                        checked={state.showNotification}
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          setState({ ...state, showNotification: e });
                        }}
                      />
                      {state.showNotification === true ? (
                        <span style={{ color: "#28c76f" }}>Enabled</span>
                      ) : (
                        "Disabled"
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="label">Full Name</div>
                  <Form.Item
                    name="Full Name"
                    rules={[{ type: "string", required: true, message: "Required" }]}
                    style={{ margin: 0 }}
                  >
                    <InputWrapper
                      disabled={isLoading}
                      name="fullName"
                      placeholder="Full Name"
                      value={state.fullName}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="label">Email</div>
                  <Form.Item
                    name="Email"
                    rules={[{ type: "email", required: true, message: "Required" }]}
                    style={{ margin: 0 }}
                  >
                    <InputWrapper
                      disabled={true}
                      name="email"
                      placeholder="Email"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="label">Phone Number</div>
                  <Form.Item
                    name="Phone Number"
                    rules={[{ required: true, message: "Required" }]}
                    style={{ margin: 0 }}
                  >
                    <PhoneInputWrapper
                      disabled={isLoading}
                      name="phoneNumber"
                      autoComplete="new-password"
                      value={state.phoneNumber}
                      placeholder="Phone Number"
                      onChange={handleChange}
                      error={state.phoneError}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="label">Gender</div>
                  <Form.Item name="Gender" rules={[{ type: "string" }]} style={{ margin: 0 }}>
                    <SelectWrapper
                      disabled={isLoading}
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      size="large"
                      name="gender"
                      placeholder="Gender"
                      value={state.gender}
                      onChange={(e) => setState({ ...state, gender: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="label">Company Name</div>
                  <Form.Item
                    name="Company Name"
                    rules={[{ type: "string" }]}
                    style={{ margin: 0 }}
                  >
                    <InputWrapper
                      disabled={isLoading}
                      name="companyName"
                      placeholder="Company Name"
                      value={state.companyName}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="label">Country</div>
                  <Form.Item name="Country" rules={[{ type: "string" }]} style={{ margin: 0 }}>
                    <InputWrapper
                      disabled={isLoading}
                      name="country"
                      placeholder="Country"
                      value={state.country}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="label">City</div>
                  <Form.Item name="City" rules={[{ type: "string" }]} style={{ margin: 0 }}>
                    <InputWrapper
                      name="city"
                      disabled={isLoading}
                      placeholder="City"
                      value={state.city}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="label">Postal Code</div>
                  <Form.Item
                    name="PostalCode"
                    rules={[{ type: "string" }]}
                    style={{ margin: 0 }}
                  >
                    <InputWrapper
                      name="postalCode"
                      disabled={isLoading}
                      type="number"
                      placeholder="Postal code"
                      value={state.postalCode}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <div className="label">Bio</div>
                  <Form.Item
                    name="bio"
                    rules={[{ required: false, message: "Required" }]}
                    style={{ margin: 0 }}
                  >
                    <TextAreaWrapper
                      rows={8}
                      disabled={isLoading}
                      name="bio"
                      value={state.bio}
                      placeholder="About yourself..."
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <ButtonWrapper
                    htmlType="submit"
                    disabled={isLoading || updateProfileLoad}
                    loading={updateProfileLoad}
                    style={{ marginTop: 36, width: 400, height: 38, borderRadius: 4 }}
                  >
                    Save
                  </ButtonWrapper>
                </Col>
              </Row>
            ) : (
              <Row gutter={[40, 20]} style={{ padding: "0 15%" }} justify={"center"}>
                <Col span={24}>
                  <div className="label">Current Password</div>
                  <Form.Item
                    name="Current Password"
                    rules={[{ required: true, type: "string" }]}
                    style={{ margin: 0 }}
                  >
                    <InputWrapper.Password
                      name="currentPassword"
                      placeholder="Current Password"
                      value={passwordState.currentPassword}
                      onChange={passwordHandleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <div className="label">New Password</div>
                  <Form.Item
                    name="New Password"
                    rules={[{ required: true, type: "string" }]}
                    style={{ margin: 0 }}
                  >
                    <InputWrapper.Password
                      name="newPassword"
                      placeholder="New Password"
                      value={passwordState.newPassword}
                      onChange={passwordHandleChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <div className="label">Confirm Password</div>
                  <Form.Item
                    name="Confirm Password"
                    dependencies={["New Password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("New Password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Confirm password not match!"));
                        },
                      }),
                    ]}
                    style={{ margin: 0 }}
                  >
                    <InputWrapper.Password
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={passwordState.confirmPassword}
                      onChange={passwordHandleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <ButtonWrapper
                    loading={passwordLoading}
                    htmlType="submit"
                    style={{ marginTop: 36, width: 400, height: 38, borderRadius: 4 }}
                  >
                    Save
                  </ButtonWrapper>
                </Col>
              </Row>
            )}
          </div>
        </Form>
      </div>
    </StyledDiv>
  );
};

export default Profile;
