import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { ButtonWrapper, TableButton } from "components/buttons";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col, Tooltip } from "antd";
import { ALL_INQUIRY, ALL_SALES, INQUIRY_IN_MANAGER, REGISTRATIONS } from "redux/constants";
import { CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import InquiryDetailModal from "components/modals/InquiryDetailModal";
import { HiCheck, HiX } from "react-icons/hi";
import CreateInquiryModal from "components/modals/createInquiryModal";
import InquiryReasonModal from "components/modals/inquiryReasonModal";
import AssignToSalepersonModal from "components/modals/AssignToSalepersonModal";
import { MdOutlineRotate90DegreesCcw } from "react-icons/md";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 6px 0px;
    width: 100px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
    text-align: center;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 6px 0px;
    width: 100px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
    text-align: center;
  }
`;

const Inquiries = () => {
  const dispatch = useDispatch();
  const { allInquiries } = useSelector((state) => state.inquiries);
  const { data } = useSelector((state) => state.admin);

  const [createInquiryModal, setCreateInquiryModal] = useState(false);
  const [registrationModal, toggleRegistrationModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState(null);
  const [assignModal, setassignModal] = useState(false);

  const inquiriesRef = useRef();
  const filterRef = useRef(2);

  const { mutateAsync: getAllInquiriesManager, isLoading: getRegistrationsLoading } =
    useMutation(Queries.getAllInquiriesManager);

  const { mutateAsync: getAllSales, isLoading: getAllSalesLoading } = useMutation(
    Queries.getAllSales
  );

  const handleSearch = (val) => {
    const filteredOptions = allInquiries.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  useEffect(() => {
    const filteredOptions = allInquiries.filter((option) => {
      if (option.inquiryFrom.adminId == "" && filterByStatus == 0) {
        return option;
      } else if (option.inquiryFrom.adminId !== "" && filterByStatus == 1) {
        return option;
      } else if (filterByStatus == "all") {
        return allInquiries;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allInquiries);
  }, [allInquiries]);

  useEffect(() => {
    getAllInquiriesManager()
      .then(({ data }) => {
        dispatch({ type: INQUIRY_IN_MANAGER.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));

    getAllSales()
      .then(({ data }) => {
        dispatch({ type: ALL_SALES.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  useEffect(() => {
    getAllSales()
      .then(({ data }) => {
        dispatch({ type: ALL_SALES.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, [assignModal]);

  return (
    <StyledTable>
      <CreateInquiryModal
        title="Create your own Inquiry"
        visible={createInquiryModal}
        onCancel={() => {
          setCreateInquiryModal(false);
        }}
        data={inquiriesRef.current}
      />

      <InquiryDetailModal
        title="Inquiry Details"
        visible={registrationModal}
        onCancel={() => {
          toggleRegistrationModal(false);
        }}
        data={inquiriesRef.current}
        showForm={true}
      />

      {assignModal && (
        <AssignToSalepersonModal
          title={`Assign to saleperson`}
          visible={assignModal}
          onCancel={() => {
            setassignModal(false);
          }}
          assignModal={assignModal}
          data={inquiriesRef.current}
          showForm={true}
        />
      )}

      <div>
        <ContentHeader
          title="Inquiries"
          count={allInquiries?.length}
          tooltip="Create your own Inquiry"
          onSearch={(e) => {
            handleSearch(e);
          }}
          onAdd={() => setCreateInquiryModal(true)}
          onSelectChange={(e) => setFilterByStatus(e)}
          dropDownOptions={[
            { label: "View All", value: "all" },
            { label: "Unassigned", value: 0 },
            { label: "Assigned", value: 1 },
          ]}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              accessor: "phoneNumber",
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      inquiriesRef.current = original;
                      toggleRegistrationModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Status",
              Cell: ({ original }) => {
                console.log(original);
                return (
                  <div>
                    {original.inquiryFrom.adminId == "" ? (
                      <div
                        style={{ width: 140, display: "flex", justifyContent: "space-between" }}
                      >
                        <span className="pending">Unassigned</span>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            inquiriesRef.current = original;
                            setassignModal(true);
                          }}
                        >
                          <MdOutlineRotate90DegreesCcw
                            title="Assign to saleperson"
                            style={{ fontSize: 20, color: "#135bed" }}
                          />
                        </div>
                        {/* <div
                          className="delete-icon"
                          onClick={() => {
                            inquiriesRef.current = original;
                            setInquiryReasonModal({ visible: true, title: "Not Interested" });
                          }}
                        >
                          <Tooltip title="Not Interested" placement="bottom">
                            <HiX style={{ fontSize: 20, color: "#f73838" }} />
                          </Tooltip>
                        </div> */}
                      </div>
                    ) : (
                      <div
                        style={{ width: 140, display: "flex", justifyContent: "space-between" }}
                      >
                        <span className="succeeded">Assigned</span>
                        <div
                          className="delete-icon"
                          onClick={() => {
                            inquiriesRef.current = original;
                            setassignModal(true);
                          }}
                        >
                          <MdOutlineRotate90DegreesCcw
                            title="Assign to another saleperson"
                            style={{ fontSize: 20, color: "#135bed" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Inquiries;
