import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tooltip, message, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { BiTrash } from "react-icons/bi";
import { Mutations, useMutation, errorHandler } from "apis/config";
import Logo from "../../images/labbaik.png";
import { BsYoutube } from "react-icons/bs";

const VideoCard = (props) => {
  const { title, description, url, setKnowledgeData, _id } = props;
  const [showModal, setShowModal] = useState(false);
  const [play, setPlay] = useState(false);

  const { mutateAsync: deleteTutorial, isLoading } = useMutation(Mutations.deleteTutorial);
  const handledeleteTutorial = async () => {
    try {
      const { data } = await deleteTutorial({ tutorialId: _id });
      setKnowledgeData(data.data);
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    console.log("play", play);
  }, [play]);

  return (
    <StyledCard>
      <div
        onClick={() => {
          setShowModal(true);
          setPlay(true);
        }}
      >
        {/* <ReactPlayer
          light="https://fixthephoto.com/blog/UserFiles/landscape-photo-editing-sample.jpg"
          previewTabIndex={5}
          // url={url}
          width={"100%"}
          height={300}
        /> */}
        <div className="video-thumbnail" style={{ backgroundImage: `url(${Logo})` }}>
          <BsYoutube className="icon" />
        </div>
        <section className="section">
          <div
            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <h1 className="title">{title}</h1>
            <Tooltip title="Delete" placement="bottom">
              <div
                className="delete-icon-div"
                onClick={isLoading ? () => {} : handledeleteTutorial}
              >
                {isLoading ? (
                  <LoadingOutlined className="delete-icon" />
                ) : (
                  <BiTrash className="delete-icon" />
                )}
              </div>
            </Tooltip>
          </div>
          <div className="description">{description}</div>
        </section>
      </div>

      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          setPlay(false);
        }}
        centered
        footer={null}
        bodyStyle={{ height: 450, width: 600, padding: 0 }}
      >
        <ReactPlayer
          volume={play ? 1 : 0}
          controls
          url={url}
          width={"100%"}
          height={"100%"}
          playing={play}
        />
      </Modal>
    </StyledCard>
  );
};

export default VideoCard;

const StyledCard = styled.div`
  height: 400px;
  z-index: 100;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.03), -2px -2px 3px rgba(0, 0, 0, 0.03),
    -2px 2px 3px rgba(0, 0, 0, 0.03), 2px -2px 3px rgba(0, 0, 0, 0.03);

  .section {
    padding: 16px 24px;
  }

  .delete-icon-div {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    &:hover {
      transition: 0.3s ease;
      background-color: rgb(244, 246, 251);
    }
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .delete-icon {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .title {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .video-thumbnail {
    height: 305px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    .icon {
      transition: 0.3s ease;
      font-size: 80px;
      color: rgba(0, 0, 0.5);
    }
    &:hover {
      transition: 0.3s ease;
      opacity: 0.8;
      .icon {
        transition: 0.3s ease;
        color: #ff0000;
      }
    }
  }
`;
