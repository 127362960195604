import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Tooltip, Form } from "antd";
import { ALL_MARKETING, ALL_QUOTES, ALL_SALES } from "redux/constants";
import styled from "styled-components";
import DemoDetailModal from "components/modals/DemoDetailModal";
import QuoteReasonModal from "components/modals/QuoteReasonModal";
import { ImStarFull, ImStarEmpty } from "react-icons/im";
import { MdOutlineAssignment } from "react-icons/md";
import KPIsetModal from "components/modals/KPIsetModal";
import { useHistory } from "react-router-dom";
import KPIsetForMarketingModal from "components/modals/KPIsetForMarketingModal";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .statsics {
    background-color: #fde5e9;
    color: #f55050;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
`;

const MarketingPersons = () => {
  const salesRef = useRef();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allMarketing } = useSelector((state) => state.marketing);
  const { data } = useSelector((state) => state.admin);

  const [copyLink, setCopyLink] = useState(false);
  const [quoteRef, setQuoteRef] = useState("");
  const [leadDetailModal, toggleLeadDetailModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [KPIModal, setKPIModal] = useState(false);

  const { mutateAsync: getAllMarketing, isLoading: getAllMarketingLoading } = useMutation(
    Queries.getAllMarketing
  );

  const handleSearch = (val) => {
    const filteredOptions = allMarketing.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  const { mutateAsync: updateSaleById, isLoading: changeStatusLoading } = useMutation(
    Mutations.updateSaleById
  );
  const changeUserStatus = async (status, saleId) => {
    try {
      await updateSaleById({ saleId, autoAssignTasks: status });
      message.success("Updated successfully");
      dispatch({ type: ALL_MARKETING.CHANGE_STATUS, payload: { saleId, status } });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    setTableData(allMarketing);
  }, [allMarketing]);

  useEffect(() => {
    getAllMarketing()
      .then(({ data }) => {
        dispatch({ type: ALL_MARKETING.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopyLink(true);
      setTimeout(() => {
        setCopyLink(false);
      }, 2000);
    } catch (err) {
      message.error("Failed to copy!");
    }
  };

  return (
    <StyledTable>
      <DemoDetailModal
        quotes={true}
        demoDetails={true}
        title="Quotation details"
        visible={leadDetailModal}
        onCancel={() => {
          toggleLeadDetailModal(false);
        }}
        data={quoteRef}
        showForm={true}
        copyToClipBoard={copyToClipBoard}
        copyLink={copyLink}
        width={750}
      />

      {KPIModal ? (
        <KPIsetForMarketingModal
          title={`Set KPI (${salesRef.current.fullName})`}
          visible={KPIModal}
          onCancel={() => setKPIModal(false)}
          data={salesRef.current}
          closeParent={() => setKPIModal(false)}
        />
      ) : null}

      <div>
        <ContentHeader
          title="Marketing-person"
          count={allMarketing?.length}
          tooltip="Create your own Lead"
          onSearch={(e) => {
            handleSearch(e);
          }}
          // onSelectChange={(e) => setFilterByStatus(e)}
          // dropDownOptions={[
          //   { label: "View All", value: "viewAll" },
          //   { label: "Pending", value: 0 },
          //   { label: "Approved", value: 1 },
          //   { label: "Not Approved", value: 2 },
          // ]}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getAllMarketingLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              Cell: ({ original }) => <div>{original.phoneNumber}</div>,
              sortable: false,
            },
            {
              Header: "Inquiry",
              Cell: ({ original }) => (
                <div style={{ width: 50, display: "flex", justifyContent: "center" }}>
                  {" "}
                  <strong style={{ textAlign: "center" }}>{original.inquiryCount}</strong>
                </div>
              ),
              sortable: false,
            },

            {
              Header: "Report",
              Cell: ({ original }) => {
                let getFullName = original.fullName.replaceAll(" ", "-");
                return (
                  <TableButton
                    onClick={() => {
                      history.push(`/report/${getFullName}/${original._id}`);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "KPI",
              Cell: ({ original }) => {
                return (
                  <div className="delete-icon">
                    <MdOutlineAssignment
                      onClick={() => {
                        salesRef.current = original;
                        setKPIModal(true);
                      }}
                      title="Set KPI's"
                      style={{ fontSize: 20, color: "#135bed" }}
                    />
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default MarketingPersons;
