import { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import DonutPlot from "components/charts/donutPlot";
import ContentHeader from "components/header/contentHeader";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import moment from "moment";
import SaleDashboardCard from "components/cards/saleDashboardCard";
import { useSelector } from "react-redux";
import InquiryIcon from "images/inquiry-F8CBA6.png";
import SaleColumnPlot from "components/charts/SaleColumnPlot";
import MarketingDashboardCard from "components/cards/MarketingDashboardCard";

const initialState = {
  totalLeads: [],
  facebookLeads: 0,
  linkedInLeads: 0,
  instagramLeads: 0,
  twitterLeads: 0,
  whatsappLeads: 0,
};

const Dashboard = () => {
  const { data } = useSelector((state) => state.admin);
  const [type, setType] = useState("month");
  const [selectedDate, setSelectedDate] = useState("");

  const [statsData, setStatsData] = useState("");
  const [filteredStatsData, setFilteredStatsData] = useState({ ...initialState });
  const [graphsData, setGraphsData] = useState({ ...initialState });
  const { mutateAsync, isLoading } = useMutation(Queries.getMarketingDashboard, {
    onSuccess: ({ data }) => {
      setStatsData(data.data);
      setGraphsData({
        ...graphsData,
      });
    },
    onError: (err) => {
      message.error(errorHandler(err));
    },
  });

  const checkCompareDate = (createdAt) => {
    let endWeekDate = moment(selectedDate).endOf(type).format("YYYY-MM-DD");
    let getCompareDate = moment(createdAt).isBetween(selectedDate, endWeekDate);
    return getCompareDate;
  };

  // =============FILTER BY DATES=============
  useEffect(() => {
    if (statsData) {
      if (type == "all") {
        const facebookLeads = statsData?.totalLeads?.filter((item) => {
          if (item.leadFrom.socialSite == "Facebook") {
            return item;
          }
        });

        const linkedInLeads = statsData?.totalLeads?.filter((item) => {
          if (item.leadFrom.socialSite == "LinkedIn") {
            return item;
          }
        });

        const instagramLeads = statsData?.totalLeads?.filter((item) => {
          if (item.leadFrom.socialSite == "Instagram") {
            return item;
          }
        });

        const twitterLeads = statsData?.totalLeads?.filter((item) => {
          if (item.leadFrom.socialSite == "Twitter") {
            return item;
          }
        });

        const whatsappLeads = statsData?.totalLeads?.filter((item) => {
          if (item.leadFrom.socialSite == "Whatsapp") {
            return item;
          }
        });

        setFilteredStatsData({
          ...filteredStatsData,
          facebookLeads,
          linkedInLeads,
          instagramLeads,
          twitterLeads,
          whatsappLeads,
          totalLeads: statsData?.totalLeads,
        });
      } else if (type == "week") {
        const getFullWeekData = statsData.totalLeads?.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        const facebookLeads = getFullWeekData?.filter((item) => {
          if (item.leadFrom.socialSite == "Facebook") {
            return item;
          }
        });

        const linkedInLeads = getFullWeekData?.filter((item) => {
          if (item.leadFrom.socialSite == "LinkedIn") {
            return item;
          }
        });

        const instagramLeads = getFullWeekData?.filter((item) => {
          if (item.leadFrom.socialSite == "Instagram") {
            return item;
          }
        });

        const twitterLeads = getFullWeekData?.filter((item) => {
          if (item.leadFrom.socialSite == "Twitter") {
            return item;
          }
        });

        const whatsappLeads = getFullWeekData?.filter((item) => {
          if (item.leadFrom.socialSite == "Whatsapp") {
            return item;
          }
        });

        setFilteredStatsData({
          ...filteredStatsData,
          facebookLeads,
          linkedInLeads,
          instagramLeads,
          twitterLeads,
          whatsappLeads,
          totalLeads: getFullWeekData,
        });
      } else if (type == "month") {
        const getFullMonthData = statsData.totalLeads?.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        const facebookLeads = getFullMonthData?.filter((item) => {
          if (item.leadFrom.socialSite == "Facebook") {
            return item;
          }
        });

        const linkedInLeads = getFullMonthData?.filter((item) => {
          if (item.leadFrom.socialSite == "LinkedIn") {
            return item;
          }
        });

        const instagramLeads = getFullMonthData?.filter((item) => {
          if (item.leadFrom.socialSite == "Instagram") {
            return item;
          }
        });

        const twitterLeads = getFullMonthData?.filter((item) => {
          if (item.leadFrom.socialSite == "Twitter") {
            return item;
          }
        });

        const whatsappLeads = getFullMonthData?.filter((item) => {
          if (item.leadFrom.socialSite == "Whatsapp") {
            return item;
          }
        });

        setFilteredStatsData({
          ...filteredStatsData,
          facebookLeads,
          linkedInLeads,
          instagramLeads,
          twitterLeads,
          whatsappLeads,
          totalLeads: getFullMonthData,
        });
      } else if (type == "year") {
        const getFullYearData = statsData.totalLeads?.filter((item) =>
          checkCompareDate(item.createdAt)
        );
        const facebookLeads = getFullYearData?.filter((item) => {
          if (item.leadFrom.socialSite == "Facebook") {
            return item;
          }
        });

        const linkedInLeads = getFullYearData?.filter((item) => {
          if (item.leadFrom.socialSite == "LinkedIn") {
            return item;
          }
        });

        const instagramLeads = getFullYearData?.filter((item) => {
          if (item.leadFrom.socialSite == "Instagram") {
            return item;
          }
        });

        const twitterLeads = getFullYearData?.filter((item) => {
          if (item.leadFrom.socialSite == "Twitter") {
            return item;
          }
        });

        const whatsappLeads = getFullYearData?.filter((item) => {
          if (item.leadFrom.socialSite == "Whatsapp") {
            return item;
          }
        });

        setFilteredStatsData({
          ...filteredStatsData,
          facebookLeads,
          linkedInLeads,
          instagramLeads,
          twitterLeads,
          whatsappLeads,
          totalLeads: getFullYearData,
        });
      }
    }
  }, [statsData, selectedDate, type]);

  useEffect(() => {
    mutateAsync();
  }, []);

  return (
    <div>
      <ContentHeader title="Dashboard" selectWidth={200} />

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <MarketingDashboardCard
            switchableRangePickerState={(e) => {}}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            type={type}
            setType={setType}
            statsData={filteredStatsData}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;

const mapData = (data) => {
  const dataObject = data.reduce((initial, user) => {
    const createdAt = moment(user.createdAt).format("YYYY-MM-DD");

    if (initial[createdAt]) {
      initial[createdAt] += 1;
    } else {
      initial[createdAt] = 1;
    }

    return initial;
  }, {});

  let graphData = [];

  for (let key in dataObject) {
    graphData.push({
      month: key,
      value: dataObject[key],
    });
  }
  graphData.sort((a, b) => (a.month > b.month ? 1 : -1));
  return graphData;
};

const saleOverview = (data) => {
  let saleOveriewGraphData = [];
  let saleOveriewData = [];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let key in months) {
    let getFilterByMonth = data?.filter(
      (item) => moment(item.createdAt).format("MMM") == months[key]
    );

    saleOveriewData.push({ [months[key]]: getFilterByMonth });
  }

  saleOveriewData.map((item, index) => {
    saleOveriewGraphData.push({
      name: "Achieved",
      month: months[index],
      value: item[months[index]]?.length,
    });
  });
  return saleOveriewGraphData;
};
