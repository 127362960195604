import { ALL_DEMOS } from "redux/constants";

const initialState = { allDemos: [] };

const allDemosReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_DEMOS.STORE: {
      const { payload } = action;
      return { ...state, allDemos: payload };
    }

    case ALL_DEMOS.CHANGE_STATUS: {
      const { adminId, status } = action.payload;
      const currentAdmin = state.allDemos.find((admin) => admin._id === adminId);
      currentAdmin.status = status;
      return { ...state };
    }

    case ALL_DEMOS.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allDemos.findIndex((user) => user._id === payload._id);
      state.allDemos.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_DEMOS.ADD_NEW: {
      const { payload } = action;

      return { ...state, allDemos: [payload, ...state.allDemos] };
    }

    default:
      return state;
  }
};

export default allDemosReducer;
