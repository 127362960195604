import { useEffect, useRef, useState } from "react";
import { Row, Col, Divider, Modal, message, Image, Select } from "antd";
import moment from "moment";
import { Tabs } from "antd";
import { FiCheck, FiEdit } from "react-icons/fi";
import { ButtonWrapper } from "components/buttons";
import { countryList } from "helpers/countries";
import { SelectWrapper } from "components/input";

const { TabPane } = Tabs;
const initialState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  message: "",
  country: "",
  companyWebsite: "",
  stage: "",
};

const InquiryDetailModal = (props) => {
  const inputRef = useRef();
  const { title, visible, onCancel, data, updateDetail, isLoading, modalFrom } = props;
  const [editAbleFields, setEditAbleFields] = useState(initialState);
  const [editMode, toggleEditMode] = useState(false);

  const userKey = [
    { key: "fullName", label: "Full Name", editAble: true },
    { key: "email", label: "Email", editAble: true },
    { key: "phoneNumber", label: "Phone", editAble: true },
    { key: "country", label: "Country", editAble: true },
    { key: "companyWebsite", label: "Company Website", editAble: true },
    { key: "stage", label: "Stage", editAble: true },
    { key: "createdAt", label: "Created On" },
    { key: "message", label: "Message", editAble: true },
    { key: "referenceText", label: "Referece Text" },
    { key: "inquiryFrom", label: "Inquiry Created From" },
    {
      key: "reason",
      label: `Reason (${data.status == 1 ? "Interested" : "Not Interested"})`,
    },
  ];

  useEffect(() => {
    if (visible) {
      toggleEditMode("");
      setEditAbleFields({
        fullName: data.fullName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        message: data.message,
        country: data.country,
        companyWebsite: data.companyWebsite,
        stage: data.stage,
      });
    }
  }, [visible]);

  const countryNames = countryList
    .map((item) => {
      return Object.keys(item).map((country) => {
        return { label: country, value: country };
      });
    })
    .flat();

  const keyList = userKey;
  return (
    <>
      <Modal title={title} visible={visible} onCancel={onCancel} footer={null} width={650}>
        <Row justify="space-between" gutter={[16, 0]}>
          <Tabs defaultActiveKey="1" type="card" size={"large"} centered>
            <TabPane tab={`Profile`} key="1">
              <Col span={24}>
                <Row>
                  {keyList.map((item) =>
                    (data.status == 0 && item.key == "reason") ||
                    ((item.key == "country" ||
                      item.key == "companyWebsite" ||
                      item.key == "stage") &&
                      !modalFrom) ||
                    (item.key == "inquiryFrom" && modalFrom) ? null : (
                      <Col key={item.key} span={24}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div className="label" style={{ width: 200 }}>
                            {item.label}
                          </div>
                          <div className="values" style={{ width: "100%", marginLeft: "5%" }}>
                            {item.key == "createdAt" ? (
                              moment(data[item.key]).format("DD MMMM YYYY")
                            ) : item.key == "inquiryFrom" ? (
                              data["inquiryFrom"]?.website ? (
                                "Website"
                              ) : (
                                data["inquiryFrom"]?.inquiryCreatedBy
                              )
                            ) : item.key == "country" ? (
                              <SelectWrapper
                                showSearch={true}
                                bordered={false}
                                options={countryNames}
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  padding: 0,
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  height: 26,
                                  width: "100%",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e,
                                  })
                                }
                              />
                            ) : item.key == "stage" ? (
                              <Select
                                bordered={false}
                                options={[
                                  { label: "Prospect", value: "Prospect" },
                                  { label: "First Contact", value: "First Contact" },
                                  { label: "Demonstration ", value: "Demonstration " },
                                  { label: "Interested", value: "Interested" },
                                  { label: "Future Opportunity", value: "Future Opportunity" },
                                ]}
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  padding: 0,
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  height: 26,
                                  width: "100%",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e,
                                  })
                                }
                              />
                            ) : item.editAble == true ? (
                              <input
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                                disabled={editMode == item.key ? false : true}
                                // maxLength={30}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              data[item.key]
                            )}
                          </div>
                          {item.editAble && data.status == 0 ? (
                            <div style={{ margin: "4px 0 0 auto" }}>
                              {editMode == item.key ? (
                                <FiCheck
                                  style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                                  onClick={() => toggleEditMode("")}
                                />
                              ) : (
                                <FiEdit
                                  style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                                  onClick={() => toggleEditMode(item.key)}
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                        <Divider style={{ margin: "2px 0" }} />
                      </Col>
                    )
                  )}

                  <Image.PreviewGroup>
                    {data.referenceImages?.map((item) => {
                      return (
                        <div style={{ margin: "10px 7px", height: 100, width: 100 }}>
                          <Image
                            width={"100%"}
                            height={"100%"}
                            src={item.url}
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      );
                    })}
                  </Image.PreviewGroup>

                  <Col span={24} style={{ margin: "8px 0" }} />
                  {data.status == 0 ? (
                    <Col offset={16} span={8}>
                      <ButtonWrapper
                        loading={isLoading}
                        onClick={() => {
                          if (modalFrom) {
                            updateDetail({
                              opportunityId: data._id,
                              fullName: editAbleFields.fullName,
                              email: editAbleFields.email,
                              phoneNumber: editAbleFields.phoneNumber,
                              message: editAbleFields.message,
                              companyWebsite: editAbleFields.companyWebsite,
                              country: editAbleFields.country,
                              stage: editAbleFields.stage,
                            });
                          } else {
                            updateDetail({
                              inquiryId: data._id,
                              fullName: editAbleFields.fullName,
                              email: editAbleFields.email,
                              phoneNumber: editAbleFields.phoneNumber,
                              message: editAbleFields.message,
                            });
                          }
                        }}
                      >
                        Update
                      </ButtonWrapper>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </TabPane>
          </Tabs>
        </Row>
      </Modal>
    </>
  );
};

InquiryDetailModal.defaultProps = {
  data: {},
  showForm: false,
};

export default InquiryDetailModal;
