import { DatePicker, Row, Select, Space, TimePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import SelectWrapper from "./selectWrapper";

const { Option } = Select;

const PickerWithType = ({ type, onChange, selectedDate }) => {
  console.log("selectedDate", selectedDate);
  return (
    <DatePicker
      allowClear={true}
      disabled={type == "all" ? true : false}
      picker={type}
      format={type == "month" ? "YYYY-MM" : ""}
      value={selectedDate == "" ? "" : moment(selectedDate)}
      defaultValue={selectedDate == "" ? "" : moment(selectedDate)}
      onChange={onChange}
    />
  );
};

const SwitchableRangePicker = (props) => {
  const { setSelectedDate, type, setType, selectedDate } = props;
  return (
    <Row justify={"space-between"} style={{ width: 300 }}>
      <SelectWrapper
        size="small"
        width={100}
        value={type}
        placeholder="Select"
        onChange={setType}
        options={[
          { label: "View All", value: "all" },
          { label: "Week", value: "week" },
          { label: "Month", value: "month" },
          { label: "Year", value: "year" },
        ]}
      />

      <PickerWithType
        type={type}
        selectedDate={selectedDate}
        onChange={(date, dateString) => {
          if (type == "week") {
            setSelectedDate(
              moment().day("Sunday").week(moment(date).week()).format("YYYY-MM-DD")
            );
          } else if (type == "month") {
            setSelectedDate(moment(date).startOf("month").format("YYYY-MM-DD"));
          } else if (type == "year") {
            setSelectedDate(moment(date).startOf("year").format("YYYY"));
          }
        }}
      />
    </Row>
  );
};
export default SwitchableRangePicker;
