import { useEffect, useRef, useState } from "react";
import { Row, Col, Divider, Modal, message } from "antd";
import { ButtonWrapper } from "components/buttons";
import moment from "moment";
import ApproveModal from "./approveModal";
import { Tabs } from "antd";
import { FiEdit, FiCheck } from "react-icons/fi";
import { LoadingOutlined } from "@ant-design/icons";
import { useMutation, errorHandler, Mutations } from "apis/config";

const { TabPane } = Tabs;

const RegistrationDetailModal = (props) => {
  const { title, visible, onCancel, data, showForm } = props;
  const [editMode, toggleEditMode] = useState(false);
  const inputRef = useRef();
  const [approveModal, toggleApproveModal] = useState(false);
  const keyList = data.userRole == 1 ? userKey : companyKey;

  const showMore = data.userRole == 1 && data.companyAssosiated !== "";
  // console.log("showMore------>", showMore);

  const { mutateAsync, isLoading } = useMutation(Mutations.updateIpAddress);

  const handleIpUpdate = async () => {
    try {
      await mutateAsync({
        companyId: data._id,
        ipAddress: inputRef.current.value,
      });
      data.ipAddress = inputRef.current.value;
      toggleEditMode(false);
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    if (visible && data.userRole == 2) {
      inputRef.current.value = data.ipAddress;
    }
  }, [visible]);

  return (
    <>
      <ApproveModal
        title="Approve"
        visible={approveModal}
        onCancel={() => toggleApproveModal(false)}
        user={data}
        closeParent={onCancel}
      />

      <Modal
        title={title}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        // width={showMore ? 900 : 600}
      >
        <Row justify="space-between" gutter={[16, 0]}>
          <Tabs defaultActiveKey="1" type="card" size={"large"} centered>
            <TabPane tab={`Profile`} key="1">
              <Col span={24}>
                <Row>
                  {keyList.map((item) => (
                    <Col key={item.key} span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: 190 }}>
                          {item.label}:
                        </div>
                        <div className="value">
                          {item.key === "dob"
                            ? moment(data[item.key]).format("DD MMMM YYYY")
                            : data[item.key]}
                        </div>
                      </div>
                      <Divider style={{ margin: "2px 0" }} />
                    </Col>
                  ))}
                  {data.userRole == 2 ? (
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div className="label" style={{ width: 190 }}>
                          IP address:
                        </div>
                        <div className="value">
                          <input
                            ref={inputRef}
                            defaultValue={data.ipAddress}
                            style={{ boxShadow: "none", border: "none", outline: "none" }}
                            disabled={!editMode}
                            maxLength={15}
                          />
                        </div>
                        <div style={{ margin: "4px 0 0 auto" }}>
                          {editMode && isLoading ? (
                            <LoadingOutlined
                              style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                            />
                          ) : editMode ? (
                            <FiCheck
                              style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                              onClick={handleIpUpdate}
                            />
                          ) : (
                            <FiEdit
                              style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                              onClick={() => toggleEditMode(true)}
                            />
                          )}
                        </div>
                      </div>
                      <Divider style={{ margin: "2px 0" }} />
                    </Col>
                  ) : null}

                  <Col span={24} style={{ margin: "8px 0" }} />

                  {showForm ? (
                    <Col offset={16} span={8}>
                      <ButtonWrapper onClick={() => toggleApproveModal(true)}>
                        Approve
                      </ButtonWrapper>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </TabPane>

            {/* {showMore ? (
            <TabPane tab="Hotel Details" key="2">
 
                  <Row>
                    {hotelKey.map((item) => (
                      <Col key={item.key} span={24}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div className="label" style={{ width: 190 }}>
                            {item.label}:
                          </div>
                          <div className="value">
                            {item.type === "date"
                              ? moment(data.hotelDetails?.[item.key]).format("DD MMMM YYYY")
                              : data.hotelDetails?.[item.key]}
                          </div>
                        </div>
                        <Divider style={{ margin: "2px 0" }} />
                      </Col>
                    ))}
                  </Row>

 
                  <Row>
                    {flightKey.map((item) => (
                      <Col key={item.key} span={24}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div className="label" style={{ width: 190 }}>
                            {item.label}:
                          </div>
                          <div className="value">
                            {item.type === "date"
                              ? moment(data.flightDetails?.[item.key]).format("DD MMMM YYYY")
                              : data.flightDetails?.[item.key]}
                          </div>
                        </div>
                        <Divider style={{ margin: "2px 0" }} />
                      </Col>
                    ))}
                  </Row>
            ) : null}
   */}

            {showMore ? (
              <>
                <TabPane tab="Hotel Details" key="2">
                  <Col span={24}>
                    <Row>
                      {hotelKey.map((item) => (
                        <Col key={item.key} span={24}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <div className="label" style={{ width: 190 }}>
                              {item.label}:
                            </div>
                            <div className="value">
                              {item.type === "date"
                                ? moment(data.hotelDetails?.[item.key]).format("DD MMMM YYYY")
                                : data.hotelDetails?.[item.key]}
                            </div>
                          </div>
                          <Divider style={{ margin: "2px 0" }} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </TabPane>

                <TabPane tab="Flight Details" key="3">
                  <Col span={24}>
                    <Row>
                      {flightKey.map((item) => (
                        <Col key={item.key} span={24}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <div className="label" style={{ width: 190 }}>
                              {item.label}:
                            </div>
                            <div className="value">
                              {item.type === "date"
                                ? moment(data.flightDetails?.[item.key]).format("DD MMMM YYYY")
                                : data.flightDetails?.[item.key]}
                            </div>
                          </div>
                          <Divider style={{ margin: "2px 0" }} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </TabPane>
              </>
            ) : null}
          </Tabs>
        </Row>
      </Modal>
    </>
  );
};

RegistrationDetailModal.defaultProps = {
  data: {},
  showForm: false,
};

export default RegistrationDetailModal;

const companyKey = [
  // {key:"createdAt", label: "Registered at"}
  { key: "fullName", label: "Full Name" },
  { key: "companyName", label: "Company name" },
  // { key: "lastName", label: "Last Name" },
  { key: "email", label: "Email" },
  { key: "phoneNumber", label: "Phone" },
  // { key: "gender", label: "Gender" },
  // { key: "dob", label: "Date of birth" },
  { key: "city", label: "City" },
  { key: "country", label: "Country" },
  { key: "trainingType", label: "Training type" },
  { key: "totalLicense", label: "Total User license" },
  { key: "totalInstructorLicense", label: "Total Instructor license" },
  { key: "address", label: "Address" },
  { key: "medicalHistory", label: "Medical history" },
  { key: "passportNumber", label: "Passport number" },
  { key: "visaNumber", label: "Visa" },
  { key: "bloodType", label: "Blood type" },
  // { key: "ipAddress", label: "", type: 1 },
  // Related to company
];

const userKey = [
  { key: "fullName", label: "Full Name" },
  // { key: "lastName", label: "Last Name" },
  { key: "email", label: "Email" },
  { key: "phoneNumber", label: "Phone" },
  { key: "gender", label: "Gender" },
  { key: "dob", label: "Date of birth" },
  { key: "age", label: "Age" },
  { key: "city", label: "City" },
  { key: "country", label: "Country" },
  { key: "trainingType", label: "Training type" },
  { key: "address", label: "Address" },
  { key: "lastLicenseUsed", label: "Last License used" },
  { key: "lastLogin", label: "Last Login" },
  { key: "medicalHistory", label: "Medical history" },
  { key: "passportNumber", label: "Passport number" },
  { key: "visaNumber", label: "Visa" },
  { key: "bloodType", label: "Blood type" },
  // Kin information
  { key: "kinName", label: "Kin name" },
  { key: "kinRelation", label: "Kin relation" },
  { key: "kinPhoneNumber", label: "Kin phone number" },
];

const hotelKey = [
  { key: "arrivalDateMakkah", label: "Arrival date (Makkah)", type: "date" },
  { key: "hotelAddressMakkah", label: "Hotel address (Makkah)" },
  { key: "arrivalDateMadina", label: "Arrival date (Madina)", type: "date" },
  { key: "hotelAddressMadina", label: "Hotel address (Madina)" },
  { key: "minnaTentNumber", label: "Minna tent number" },
];

const flightKey = [
  { key: "flightNumber", label: "Flight number" },
  { key: "departureDate", label: "Departure date", type: "date" },
  { key: "arrivalDate", label: "Arrival date", type: "date" },
  { key: "flyingFrom", label: "Flying from" },
  { key: "flyingTo", label: "Flying to" },
];
