import { ALL_LEADS } from "redux/constants";

const initialState = { allLeads: [] };

const allLeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_LEADS.STORE: {
      const { payload } = action;
      return { ...state, allLeads: payload };
    }

    case ALL_LEADS.CHANGE_STATUS: {
      const { adminId, status } = action.payload;
      const currentAdmin = state.allLeads.find((admin) => admin._id === adminId);
      currentAdmin.status = status;
      return { ...state };
    }

    case ALL_LEADS.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allLeads.findIndex((user) => user._id === payload._id);
      state.allLeads.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_LEADS.ADD_NEW: {
      const { payload } = action;

      return { ...state, allLeads: [payload, ...state.allLeads] };
    }

    default:
      return state;
  }
};

export default allLeadsReducer;
