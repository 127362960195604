import { useEffect, useState } from "react";
import { Row, Col, message, Skeleton } from "antd";
import styled from "styled-components";
import { ReactComponent as DollarIcon } from "images/dollar.svg";
import { ReactComponent as InvoiceIcon } from "images/invoice.svg";
import { ReactComponent as UsersIcon } from "images/users.svg";
import { VscOrganization } from "react-icons/vsc";
import Icon from "@ant-design/icons";
import { Queries, useMutation, errorHandler } from "apis/config";
import { useDispatch } from "react-redux";

const initialState = {
  totalUsers: "",
  totalLicense: "",
  totalCompanies: "",
  totalPayments: "",
};

const DashboardCard = (props) => {
  const dispatch = useDispatch();
  const [stats, setStats] = useState({ ...initialState });

  const { mutateAsync, isLoading } = useMutation(Queries.getDashboardStats, {
    onSuccess: ({ data }) => {
      console.log("Data: ", data.data);
      setStats(data.data);
    },
    onError: (err) => {
      message.error(errorHandler(err, dispatch));
    },
  });

  useEffect(() => {
    mutateAsync();
  }, []);

  return (
    <StyledCard>
      <Row justify="space-between" align="middle">
        <Col>
          <h3>Statistics</h3>
        </Col>
        {/* <Col>
          <span className="statsics">
            <ArrowUpOutlined className="statsics-direction" /> 2.65%
          </span>{" "}
          Since last month
        </Col> */}
      </Row>
      <br />
      {isLoading ? (
        <Skeleton avatar paragraph={{ rows: 1 }} />
      ) : (
        <Row justify="space-between" align="middle">
          <Col>
            <Row justify="space-around" align="middle">
              <Col>
                <Icon
                  component={UsersIcon}
                  className="card-image"
                  style={{ backgroundColor: "#DEE7F5", color: "#002866" }}
                />
              </Col>
              <Col>
                <div>Total users</div>
                <div>
                  <b>{stats.totalUsers}</b>
                </div>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row justify="space-around" align="middle">
              <Col>
                <Icon
                  component={VscOrganization}
                  className="card-image"
                  style={{ backgroundColor: "#FEEAEA", color: "#F86368" }}
                />
              </Col>
              <Col>
                <div>Total companies</div>
                <div>
                  <b>{stats.totalCompanies}</b>
                </div>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row justify="space-around" align="middle">
              <Col>
                <Icon
                  component={InvoiceIcon}
                  className="card-image"
                  style={{ backgroundColor: "#DCF9FC", color: "#00DAEA" }}
                />
              </Col>
              <Col>
                <div>Total license</div>
                <div>
                  <b>{stats.totalLicense}</b>
                </div>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row justify="space-around" align="middle">
              <Col>
                <Icon
                  component={DollarIcon}
                  className="card-image"
                  style={{ backgroundColor: "#E3F8EE", color: "#00C576" }}
                />
              </Col>
              <Col>
                <div>Total amount</div>
                <div>
                  <b>{stats.totalPayments}</b>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </StyledCard>
  );
};

export default DashboardCard;

const StyledCard = styled.div`
  background-color: #fff;
  padding: 16px 32px 32px 32px;
  border-radius: 12px;
  box-shadow: 1px 1px solid red;

  .statsics {
    background-color: #d4f4e2;
    color: #28c76f;
    margin-right: 8px;
    padding: 2px 8px;
    border-radius: 4px;
  }

  .card-image {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 50%;
    background-color: #d4f4e2;
    margin-right: 12px;
  }
`;
