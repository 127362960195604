import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, Radio } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper, TextAreaWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ALL_INQUIRY, REGISTRATIONS, SUB_ADMIN } from "redux/constants";
import { approveUser } from "redux/actions";
import { PhoneInputWrapper } from "components/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { RadioGroup } from "components/input";
import ImageUploader from "components/uploader/ImageUploader";

const initialState = {
  fullName: "",
  email: "",
  phoneNo: "",
  phoneError: "",
  message: "",
  inquiryFrom: "",
  referenceImages: [],
  referenceText: "",
};

const CreateInquiryModal = (props) => {
  const { title, visible, onCancel } = props;
  const { data } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [referenceValue, setReferenceValue] = useState(1);

  const { isLoading, mutateAsync } = useMutation(Mutations.createInquiry);

  const handleAddSubAdmin = async () => {
    const getUserRole = (userRole) => {
      let userRoles = [
        { label: "Admin", value: 1 },
        { label: "Manager", value: 2 },
        { label: "Sales", value: 3 },
        { label: "Marketing", value: 4 },
      ].find((i) => i.value == userRole);
      return userRoles?.label;
    };

    const variables = {
      fullName: state.fullName,
      email: state.email,
      phoneNumber: state.phoneNo,
      message: state.message,
      // inquiryFrom: `${getUserRole(data.userRole)} (${data.email})`,
      // inquiryFrom: data._id,
      referenceText: state.referenceText,
      referenceImages: fileList,
    };
    if (state.phoneError == "") {
      try {
        let getResponse = await mutateAsync(variables);
        dispatch({ type: ALL_INQUIRY.ADD_NEW, payload: getResponse.data.data });
        message.success("Created successfully");
        onClose();
      } catch (err) {
        message.error(errorHandler(err));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={500}
    >
      <Form form={form} onFinish={handleAddSubAdmin} validateTrigger="onFinish">
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={12}>
            <div className="label">Full Name*</div>
            <Form.Item
              name="Full Name"
              rules={[{ required: true, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="fullName"
                placeholder="Full Name"
                value={state.fullName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="label">Phone Number</div>
            <Form.Item
              name="Phone Number"
              rules={[{ required: false, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <PhoneInputWrapper
                name="phoneNo"
                autoComplete="new-password"
                value={state.phoneNo}
                placeholder="Phone Number"
                onChange={handleChange}
                error={state.phoneError}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Email</div>
            <Form.Item
              name="Email"
              rules={[{ required: false, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <InputWrapper
                name="email"
                placeholder="Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="label">Message</div>
            <Form.Item
              name="Message"
              rules={[{ required: false, message: "Required" }]}
              style={{ margin: 0 }}
            >
              <TextAreaWrapper
                rows={5}
                name="message"
                value={state.message}
                placeholder="Client's Message"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="label">Reference</div>

            <RadioGroup
              onChange={(e) => setReferenceValue(e.target.value)}
              value={referenceValue}
            >
              <Radio value={1}>Text</Radio>
              <Radio value={2}>Image</Radio>
            </RadioGroup>
            <div style={{ marginTop: 10 }}>
              {referenceValue == 1 ? (
                <Form.Item
                  name="reference"
                  rules={[{ required: false, message: "Required" }]}
                  style={{ margin: 0 }}
                >
                  <TextAreaWrapper
                    rows={3}
                    name="referenceText"
                    value={state.referenceText}
                    placeholder="Provide a Reference here.."
                    onChange={handleChange}
                  />
                </Form.Item>
              ) : (
                <ImageUploader
                  uploadLength={5}
                  uploadPreset={"Inquiry_references"}
                  loading={loading}
                  setLoading={setLoading}
                  fileList={fileList}
                  setFileList={setFileList}
                />
              )}
            </div>
          </Col>

          <Col span={24}>
            <ButtonWrapper
              style={{ width: "100%" }}
              htmlType="submit"
              disabled={isLoading || loading}
              loading={isLoading}
              onClick={() => {
                if (state.phoneNo !== "" && !isValidPhoneNumber(state.phoneNo)) {
                  setState({
                    ...state,
                    phoneError: "Invalid phone number",
                  });
                } else {
                  setState({
                    ...state,
                    phoneError: "",
                  });
                }
              }}
            >
              Create
            </ButtonWrapper>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default CreateInquiryModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
