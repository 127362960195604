import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Form, message, Button } from "antd";
import { InputWrapper } from "../input";
import { Mutations } from "../../apis/mutations";
import { useMutation } from "react-query";
import { errorHandler } from "../../helpers/errorHandler";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import SelectWrapper from "../input/selectWrapper";
import { BsPlusSquare } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { HiCheck, HiX } from "react-icons/hi";
import { ALL_PACKAGES } from "redux/constants";
import ImageUploader from "components/uploader/ImageUploader";
import BannerImageUploader from "../uploader/BannerImageUpload";

const StyledContent = styled.div`
  .form-label {
    text-align: left;
    font-size: 16px;
  }

  .ant-form-item {
    margin: 0px;
  }

  .add-btn {
    background-color: #135bed;
    color: #fff;
    padding: 6px 16px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
  }

  .description-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    background-color: #d1d1d126;
    padding: 0px 10px;
    margin: 6px 6px;
    border-radius: 4px;
    border: 1px solid #91848414;
    min-width: 80px;
    border-radius: 50px;
    max-width: max-content;
  }

  .description {
    font-size: 12px;
    color: #2a2a2a;
  }
  .delete-description {
    font-size: 14px;
    color: #2a2a2a;
    cursor: pointer;
    margin-left: 4px;
  }
  .description-container {
    max-height: 180px;
    overflow: auto;
    flex-wrap: wrap;
    display: flex;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d1d1d1ef;
      border-radius: 10px;
    }
  }
`;

const initialState = {
  packageName: "",
  packageType: "",
  packageDuration: "",
  packageDetail: "",
  price: "",
  image: "",
  packageIncludes: [],
};

const packageTypeOptions = [
  { value: "Hajj", label: "Hajj" },
  { value: "Umrah", label: "Umrah" },
];

const ViewPackage = (props) => {
  const { visible, onCancel, currentPackage, mode, onOk } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const descriptionRef = useRef();

  const [state, setState] = useState({ ...initialState });
  const [newDescription, setNewDescription] = useState("");
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    /**
     * Set all feilds value if editing the coupon
     */
    if (mode?.current == "View Package" && visible) {
      setState(currentPackage);
      setFileList([{ url: currentPackage.image, id: 1 }]);
      form.setFieldsValue({
        "Package Name": currentPackage.packageName,
        Duration: currentPackage.packageDuration,
        "Package Type": currentPackage.packageType,
        Price: currentPackage.price,
        "Package Detail": currentPackage.packageDetail,
      });
    } else {
      setState({ ...initialState, packageIncludes: [] });
      setFileList([]);
    }
  }, [mode, visible]);

  /**
   * Mutation and handler for adding new coupon
   */
  // const [addPackageMutation, { loading: addPackageLoading }] = useMutation(
  //   Mutations.ADD_NEW_PACKAGE
  // );

  //   const { isLoading: addPackageLoading, mutateAsync: addPackageMutation } = useMutation(
  //     Mutations.addPackageMutation
  //   );

  //   const handleAddNewPackage = async () => {
  //     try {
  //       let getResponse = await addPackageMutation({
  //         packageName: state.packageName,
  //         packageType: state.packageType,
  //         packageDuration: state.packageDuration,
  //         price: state.price,
  //         packageIncludes: state.packageIncludes,
  //         packageDetail: state.packageDetail,
  //         image: fileList[0]?.url,
  //       });
  //       dispatch({ type: ALL_PACKAGES.ADD_NEW, payload: getResponse.data.data });

  //       // props.getCouponData();
  //       closeModal();
  //     } catch (err) {
  //       message.error(errorHandler(err));
  //     }
  //   };

  // /**
  //  * Mutation and handler for updating coupon
  //  */
  // const [updatePackageMutation, { loading: updatePackageLoading }] = useMutation(
  //   Mutations.UPDATE_PACKAGE_BY_ID
  // );

  const handleViewPackage = async () => {
    // try {
    //   await updatePackageMutation({
    //     variables: {
    //       packageId: currentPackage._id,
    //       packageType: state.packageType,
    //       orderType: state.orderType,
    //       description: state.description,
    //       rushDeliveryCharges: state.rushDeliveryCharges,
    //       originalPrice: state.originalPrice,
    //       price: state.price,
    //       discount: `${Math.floor(100 - (state.price * 100) / state.originalPrice)}`,
    //       slug: state.slug,
    //     },
    //   });
    //   window.location.reload();
    //   closeModal();
    // } catch (err) {
    //   message.error(errorHandler(err));
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setState({ ...state, [name]: value });
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const closeModal = () => {
    setFileList([]);
    setState({ ...initialState }); // Reset State
    form.resetFields(); // Empty form feilds
    onCancel(); // Close the modal
  };

  return (
    <Modal
      title={mode?.current == "View Package" ? "Package Details" : "Add Package"}
      visible={visible}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <StyledContent>
        <Form form={form} onFinish={handleViewPackage} validateTrigger="onFinish">
          <Row justify="space-between" gutter={[24, 24]}>
            <Col span={24}>
              <h4 className="form-label">*Package Banner Image</h4>
              <Form.Item name="Package Name" rules={[{ required: true }]}>
                <BannerImageUploader
                  uploadLength={1}
                  uploadPreset={"travel_agent_packages"}
                  loading={loading}
                  setLoading={setLoading}
                  fileList={fileList}
                  setFileList={setFileList}
                  viewDeleteIcon={mode?.current}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <h4 className="form-label">*Package Name</h4>
              <Form.Item name="Package Name" rules={[{ required: true }]}>
                <InputWrapper
                  readOnly={mode.current == "View Package" ? true : false}
                  maxLength={20}
                  name="packageName"
                  value={state.packageName}
                  placeholder="Package Name"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <h4 className="form-label">*Package Type</h4>
              <Form.Item name="Package Type" rules={[{ required: true }]}>
                <SelectWrapper
                  style={{
                    pointerEvents: mode.current == "View Package" ? "none" : "auto",
                  }}
                  name="packageType"
                  options={packageTypeOptions}
                  onChange={(val) => {
                    setState({ ...state, packageType: val });
                  }}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <h4 className="form-label">*Duration</h4>
              <Form.Item name="Duration" rules={[{ required: true }]}>
                <InputWrapper
                  readOnly={mode.current == "View Package" ? true : false}
                  maxLength={20}
                  name="packageDuration"
                  value={state.packageDuration}
                  placeholder="40 Days"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <h4 className="form-label">*Amount</h4>
              <Form.Item name="Price" rules={[{ required: true }]}>
                <InputWrapper
                  readOnly={mode.current == "View Package" ? true : false}
                  maxLength={20}
                  name="price"
                  value={state.price}
                  placeholder="Rs 600,000/-"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <h4 className="form-label">*Package Description</h4>
              <Form.Item name="Package Detail" rules={[{ required: true }]}>
                <InputWrapper.TextArea
                  rows={4}
                  readOnly={mode.current == "View Package" ? true : false}
                  maxLength={1000}
                  name="packageDetail"
                  value={state.packageDetail}
                  placeholder="Package Description"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <h4 className="form-label">*Package Includes</h4>

              <div className="description-container" ref={descriptionRef}>
                {state.packageIncludes?.map((item, index) => (
                  <div
                    className="description-wrapper"
                    style={{
                      justifyContent:
                        mode.current == "View Package" ? "center" : "space-between",
                    }}
                  >
                    <div key={index} className="description">
                      {item}
                    </div>
                    {/* <div
                      title="Remove"
                      onClick={() => {
                        const i = state.description.indexOf(item);
                        console.log("Index", i);
                        state.description.splice(i, 1);
                        setState({ ...state });
                      }}
                      className="delete-description"
                    > */}
                    <HiX
                      className="delete-description"
                      title="Remove"
                      onClick={() => {
                        const i = state.packageIncludes.indexOf(item);
                        console.log("Index", i);
                        state.packageIncludes.splice(i, 1);
                        setState({ ...state });
                      }}
                      style={{
                        display: mode.current == "View Package" ? "none" : "flex",
                      }}
                    />
                    {/* </div> */}
                  </div>
                ))}
              </div>

              <InputWrapper
                style={{
                  marginTop: 10,
                  display: mode.current == "View Package" ? "none" : "flex",
                }}
                placeholder="Tickets"
                value={newDescription}
                suffix={
                  <div
                    className="add-btn"
                    title="Add"
                    onClick={() => {
                      if (newDescription !== "") {
                        console.log("newDescription", newDescription);
                        state.packageIncludes.push(newDescription);
                        setState({ ...state });
                        descriptionRef.current.scrollTo({
                          top: (state.packageIncludes.length + 1) * 48,
                          behavior: "smooth",
                        });
                        setNewDescription("");
                      }
                    }}
                  >
                    Add
                  </div>
                }
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setNewDescription(e.target.value);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value !== "") {
                      e.preventDefault();
                      state.packageIncludes.push(e.target.value);
                      console.log("state---->", state);
                      setState({ ...state });
                      descriptionRef.current.scrollTo({
                        top: (state.packageIncludes.length + 1) * 48,
                        behavior: "smooth",
                      });
                      e.target.value = "";
                      setNewDescription("");
                    }
                  }
                }}
              />
            </Col>
          </Row>

          <br />

          <Row
            justify="end"
            align="middle"
            style={{ display: mode.current == "View Package" ? "none" : "flex" }}
          >
            <Col span={7} style={{ paddingLeft: "10px" }}>
              <Button
                style={{
                  outline: "none",
                  border: "1px solid #ffbe04",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
                block
                shape="round"
                type="danger"
                size="default"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </Col>
            <Col span={7} style={{ paddingLeft: "10px" }}>
              <Button
                block
                shape="round"
                type="primary"
                size="default"
                style={{ outline: "none", border: "none", backgroundColor: "#ffbe04" }}
                htmlType="submit"
                onClick={onOk}
                // loading={addPackageLoading}
              >
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </StyledContent>
    </Modal>
  );
};

ViewPackage.defaultProps = {
  onCancel: () => {},
  currentPackage: { _id: null },
  onOk: () => {},
  mode: "Add Package",
};

export default ViewPackage;
