import styled from "styled-components";

const ChartContainer = (props) => {
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

export default ChartContainer;

const StyledContainer = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : null)};
  /* height: 500px; */

  padding: 32px;
  border-radius: 12px;
  box-shadow: 1px 1px solid red;
  .card-image {
    padding: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 50%;
    background-color: #d4f4e2;
    margin-right: 12px;
  }
  .skeleton-wrap {
    transform: rotate(-90deg);
    &.ant-skeleton {
      width: 32% !important;
    }
  }
`;
