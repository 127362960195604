import { ALL_SALES } from "redux/constants";

const initialState = { allSales: [] };

const allSalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_SALES.STORE: {
      const { payload } = action;
      return { ...state, allSales: payload };
    }

    case ALL_SALES.CHANGE_STATUS: {
      const { saleId, status } = action.payload;
      const currentAdmin = state.allSales.find((admin) => admin._id === saleId);
      currentAdmin.autoAssignTasks = status;
      return { ...state };
    }

    case ALL_SALES.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allSales.findIndex((user) => user._id === payload._id);
      state.allSales.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case ALL_SALES.ADD_NEW: {
      const { payload } = action;

      return { ...state, allSales: [payload, ...state.allSales] };
    }

    default:
      return state;
  }
};

export default allSalesReducer;
