import { useEffect, useRef, useState } from "react";
import { Row, Col, Divider, Modal, message, Image, Button, Select } from "antd";
import moment from "moment";
import { Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FiCheck, FiEdit } from "react-icons/fi";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper } from "components/input";
import { Mutations } from "apis/mutations";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { ALL_LEADS } from "redux/constants";
import { errorHandler } from "helpers/errorHandler";
import { countryList } from "helpers/countries";

const { TabPane } = Tabs;

const initialState = {
  address: "",
  country: "",
  city: "",
  gender: "",
  companyName: "",
  companyEmail: "",
  companyPhone: "",
  companyWebsite: "",
  trainingType: "",
  userType: "",
};

const LeadDetailModal = (props) => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [editAbleFields, setEditAbleFields] = useState(initialState);
  const { title, visible, onCancel, data, leadEditable } = props;
  const [editMode, toggleEditMode] = useState(false);

  const userKey = [
    { key: "fullName", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "phoneNumber", label: "Phone" },
    { key: "trainingType", label: "Training Type", editAble: true },
    { key: "userType", label: "Lead Type", editAble: true },
    { key: "companyName", label: "Company Name", editAble: true },
    { key: "companyEmail", label: "Company Email", editAble: true },
    { key: "companyPhone", label: "Company Phone", editAble: true },
    { key: "companyWebsite", label: "Company Website (URL)", editAble: true },
    { key: "address", label: "Address", editAble: true },
    { key: "gender", label: "Gender", editAble: true },
    { key: "country", label: "Country", editAble: true },
    { key: "city", label: "City", editAble: true },
    { key: "message", label: "Message" },
    { key: "referenceText", label: "Referece Text" },
    { key: "leadFrom", label: "Lead Created From" },
    { key: "createdAt", label: "Created On" },
    {
      key: "reason",
      label: `Reason (${data.status == 1 ? "Approved" : "Not Approved"})`,
    },
  ];
  const keyList = userKey;

  const { mutateAsync, isLoading } = useMutation(Mutations.updateLead);

  const updateLeadsDetail = async () => {
    try {
      let getResponse = await mutateAsync({
        leadId: data._id,
        address: editAbleFields.address,
        country: editAbleFields.country,
        city: editAbleFields.city,
        gender: editAbleFields.gender,
        companyName: editAbleFields.companyName,
        companyPhone: editAbleFields.companyPhone,
        companyWebsite: editAbleFields.companyWebsite,
        companyEmail: editAbleFields.companyEmail,
        trainingType: editAbleFields.trainingType,
        userType: editAbleFields.userType,
      });
      dispatch({ type: ALL_LEADS.EDIT_DATA, payload: getResponse.data.data });
      message.success("Updated successfully");
      onCancel();
      toggleEditMode("");
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    if (visible) {
      toggleEditMode("");
      setEditAbleFields({
        address: data.address,
        country: data.country,
        city: data.city,
        gender: data.gender,
        companyName: data.companyName,
        companyPhone: data.companyPhone,
        companyWebsite: data.companyWebsite,
        companyEmail: data.companyEmail,
        trainingType: data.trainingType,
        userType: data.userType,
      });
    }
  }, [visible]);

  const countryNames = countryList
    .map((item) => {
      return Object.keys(item).map((country) => {
        return { label: country, value: country };
      });
    })
    .flat();

  let cityNames = [];
  if (editAbleFields.country !== "") {
    cityNames = countryList
      .map((item) => {
        return item[editAbleFields?.country]?.map((city) => {
          return { label: city, value: city };
        });
      })
      .flat();
  }
  console.log("cityNames------->", cityNames);

  return (
    <>
      <Modal title={title} visible={visible} onCancel={onCancel} footer={null} width={650}>
        <Row justify="space-between" gutter={[16, 0]}>
          <Tabs defaultActiveKey="1" type="card" size={"large"} centered>
            <TabPane tab={`Profile`} key="1">
              <Col span={24}>
                <Row>
                  {keyList.map((item) =>
                    (data.status == 0 && item.key == "reason") ||
                    (editAbleFields.userType == "Individual User" &&
                      (item.key == "companyName" ||
                        item.key == "companyEmail" ||
                        item.key == "companyWebsite" ||
                        item.key == "companyPhone")) ? null : (
                      <Col key={item.key} span={24}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div className="label" style={{ width: "50%" }}>
                            {item.label}:
                          </div>
                          <div className="values" style={{ width: "100%" }}>
                            {item.key == "createdAt" ? (
                              moment(data[item.key]).format("DD MMMM YYYY")
                            ) : item.key == "leadFrom" ? (
                              data["leadFrom"]?.website ? (
                                "Website/Application"
                              ) : (
                                data["leadFrom"]?.leadCreatedBy
                              )
                            ) : item.key == "trainingType" ? (
                              <Select
                                bordered={false}
                                options={[
                                  { label: "Hajj", value: "Hajj" },
                                  { label: "Umrah", value: "Umrah" },
                                  { label: "Both", value: "Both" },
                                ]}
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  padding: 0,
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  height: 26,
                                  width: "100%",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e,
                                  })
                                }
                              />
                            ) : item.key == "userType" ? (
                              <Select
                                bordered={false}
                                options={[
                                  { label: "Individual User", value: "Individual User" },
                                  {
                                    label: "Company/Travel Agent",
                                    value: "Company/Travel Agent",
                                  },
                                ]}
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  padding: 0,
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  height: 26,
                                  width: "100%",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e,
                                  })
                                }
                              />
                            ) : item.key == "country" ? (
                              <Select
                                showSearch={true}
                                bordered={false}
                                options={countryNames}
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  padding: 0,
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  height: 26,
                                  width: "100%",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e,
                                  })
                                }
                              />
                            ) : item.key == "city" ? (
                              <Select
                                showSearch={true}
                                bordered={false}
                                options={cityNames}
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  padding: 0,
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  height: 26,
                                  width: "100%",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={15}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e,
                                  })
                                }
                              />
                            ) : item.editAble == true ? (
                              <input
                                ref={inputRef}
                                value={editAbleFields[item.key]}
                                style={{
                                  boxShadow: "none",
                                  border: "none",
                                  outline: "none",
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                                disabled={editMode == item.key ? false : true}
                                maxLength={30}
                                onChange={(e) =>
                                  setEditAbleFields({
                                    ...editAbleFields,
                                    [editMode]: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              data[item.key]
                            )}
                          </div>
                          {item.editAble && data.status == 0 && leadEditable ? (
                            <div style={{ margin: "4px 0 0 auto" }}>
                              {editMode == item.key ? (
                                <FiCheck
                                  style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                                  onClick={() => toggleEditMode("")}
                                />
                              ) : (
                                <FiEdit
                                  style={{ fontSize: 16, color: "#135bed", cursor: "pointer" }}
                                  onClick={() => toggleEditMode(item.key)}
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                        <Divider style={{ margin: "2px 0" }} />
                      </Col>
                    )
                  )}

                  <Image.PreviewGroup>
                    {data.referenceImages?.map((item) => {
                      return (
                        <div style={{ margin: "10px 7px", height: 100, width: 100 }}>
                          <Image
                            width={"100%"}
                            height={"100%"}
                            src={item.url}
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      );
                    })}
                  </Image.PreviewGroup>

                  <Col span={24} style={{ margin: "8px 0" }} />
                  {data.status == 0 && leadEditable ? (
                    <Col offset={16} span={8}>
                      <ButtonWrapper
                        loading={isLoading}
                        onClick={() => {
                          if (
                            !/^(ftp|http|https):\/\/[^ "]+$/.test(
                              editAbleFields.companyWebsite
                            ) &&
                            editAbleFields.userType == "Company/Travel Agent"
                          ) {
                            message.warning("Company website is not valid");
                          } else {
                            updateLeadsDetail();
                          }
                        }}
                      >
                        Update
                      </ButtonWrapper>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </TabPane>
          </Tabs>
        </Row>
      </Modal>
    </>
  );
};

LeadDetailModal.defaultProps = {
  data: {},
  showForm: false,
};

export default LeadDetailModal;
