import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Modal, Row, Col, Tooltip, Form } from "antd";
import { ALL_QUOTES, ALL_RESALE } from "redux/constants";
import styled from "styled-components";
import { HiCheck, HiX } from "react-icons/hi";
import DemoDetailModal from "components/modals/DemoDetailModal";
import { BiCopy } from "react-icons/bi";
import QuoteReasonModal from "components/modals/QuoteReasonModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import InquiryReasonModal from "components/modals/inquiryReasonModal";
import ResaleReasonModal from "components/modals/resaleReasonModal";
import { filter } from "lodash";
import moment from "moment";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .statsics {
    background-color: #fde5e9;
    color: #f55050;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
`;

const Quotes = () => {
  const filterRef = useRef("all");
  const filterSpecificRef = useRef(4);

  const resaleRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allResale } = useSelector((state) => state.resale);
  const { data } = useSelector((state) => state.admin);

  console.log("Allresale", allResale);
  const [copyLink, setCopyLink] = useState(false);
  const [copyLinkState, setCopyLinkState] = useState("");
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [quoteRef, setQuoteRef] = useState("");
  const [leadDetailModal, toggleLeadDetailModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("viewAll");
  const [resaleReasonModal, setResaleReasonModal] = useState({
    visible: false,
    title: "",
  });
  const [quoteUpdateModal, setquoteUpdateModal] = useState({
    visible: false,
    title: "",
  });

  const { mutateAsync: getAllResale, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllResale
  );

  const handleSearch = (val) => {
    const filteredOptions = allResale.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.country.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.gender.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  const closeModal = () => {
    setAddLinkModal(false);
    form.resetFields();
  };
  useEffect(() => {
    const filteredOptions = allResale.filter((option) => {
      if (option.status == filterByStatus) {
        return option;
      } else if (filterByStatus == "viewAll") {
        return allResale;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  const handleFilter = (value) => {
    if (filterRef?.current == "all") {
      setTableData(allResale);
    } else {
      const filteredData = allResale.filter((item) => {
        if (item.status == filterSpecificRef.current || item.status == filterRef.current) {
          return item;
        } else if (item.status == filterRef.current) {
          return item;
        }
      });
      setTableData(filteredData);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [allResale, leadDetailModal, quoteUpdateModal.visible]);

  useEffect(() => {
    getAllResale()
      .then(({ data }) => {
        dispatch({ type: ALL_RESALE.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));

    dispatch({ type: "RESALE", payload: null });
  }, []);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopyLink(true);
      setTimeout(() => {
        setCopyLink(false);
      }, 2000);
    } catch (err) {
      message.error("Failed to copy!");
    }
  };

  const paymentStatus = (status) => {
    if (status == "succeeded") {
      return <span className="succeeded">{status}</span>;
    } else if (status == "pending") {
      return <span className="pending">{status}</span>;
    } else {
      return <span className="statsics">{status}</span>;
    }
  };

  const checkQuotes = (status) => {
    if (status == "all" || status == 2) {
      return false;
    }
    return true;
  };

  const conditionalTablRender = () => {
    if (filterRef.current == "all") {
      return [
        {
          Header: "Created At",
          Cell: ({ original }) => <div>{moment(original.createdAt).format("DD-MMM-YYYY")}</div>,
          sortable: false,
        },
        {
          Header: "Full Name",
          Cell: ({ original }) => <div>{original.userId.fullName}</div>,
          sortable: false,
        },
        {
          Header: "Company Name",
          Cell: ({ original }) => <div>{original.userId.companyName}</div>,
          sortable: false,
        },
        {
          Header: "Email",
          Cell: ({ original }) => <div>{original.userId.email}</div>,
          sortable: false,
        },
        {
          Header: "Phone",
          Cell: ({ original }) => <div>{original.userId.phoneNumber}</div>,
          sortable: false,
        },
        {
          Header: "Details",
          Cell: ({ original }) => {
            return (
              <TableButton
                onClick={() => {
                  resaleRef.current = original;
                  setQuoteRef({
                    ...original.userId,
                    quoteStatus: original.status,
                    quotes: {
                      amount: original.amount,
                      totalUserLicense: original.totalUserLicense,
                      totalInstructorLicense: original.totalInstructorLicense,
                      resaleId: original._id,
                      paymentLink: original.paymentLink,
                      paymentScreenshot: original.paymentScreenshot,
                      reason: original.reason,
                    },
                  });
                  toggleLeadDetailModal(true);
                }}
              >
                view
              </TableButton>
            );
          },
          sortable: false,
        },
        {
          Header: "Actions",
          Cell: ({ original }) => {
            console.log("original", original);
            return (
              <div>
                {original.status == 0 ? (
                  <div style={{ width: 70, display: "flex", justifyContent: "space-between" }}>
                    <div
                      className="delete-icon"
                      onClick={() => {
                        resaleRef.current = original;
                        setResaleReasonModal({ visible: true, title: "Interested" });
                      }}
                    >
                      <Tooltip title="Interested" placement="bottom">
                        <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                      </Tooltip>
                    </div>
                    <div
                      className="delete-icon"
                      onClick={() => {
                        resaleRef.current = original;
                        setResaleReasonModal({ visible: true, title: "Not Interested" });
                      }}
                    >
                      <Tooltip title="Not Interested" placement="bottom">
                        <HiX style={{ fontSize: 20, color: "#f73838" }} />
                      </Tooltip>
                    </div>
                  </div>
                ) : original.status == 1 ? (
                  <div>
                    <strong style={{ margin: 0, color: "#4fc06b" }}>Interested</strong>
                  </div>
                ) : original.status == 2 ? (
                  <div>
                    <strong style={{ margin: 0, color: "#dc7e24" }}>Not Interested</strong>
                  </div>
                ) : original.status == 3 ? (
                  <div>
                    <strong style={{ margin: 0, color: "#135bed" }}>Completed</strong>
                  </div>
                ) : (
                  <div>
                    <strong style={{ margin: 0, color: "#f73838" }}>Not Approved</strong>
                  </div>
                )}
              </div>
            );
          },
          sortable: false,
        },
      ];
    } else if (filterRef.current == 1) {
      return [
        {
          Header: "Created At",
          Cell: ({ original }) => <div>{moment(original.createdAt).format("DD-MMM-YYYY")}</div>,
          sortable: false,
        },
        {
          Header: "Full Name",
          Cell: ({ original }) => <div>{original.userId.fullName}</div>,
          sortable: false,
        },
        {
          Header: "Email",
          Cell: ({ original }) => <div>{original.userId.email}</div>,
          sortable: false,
        },
        {
          Header: "Phone",
          Cell: ({ original }) => <div>{original.userId.phoneNumber}</div>,
          sortable: false,
        },

        {
          Header: "Total License",
          Cell: ({ original }) => <div>{original.totalUserLicense}</div>,
          sortable: false,
        },
        {
          Header: "Amount",
          Cell: ({ original }) => <div>${original.amount}</div>,
          sortable: false,
        },
        {
          Header: "Payment Link",
          Cell: ({ original }) => (
            <div
              style={{
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
              onClick={() => {
                if (original.amount < 1) {
                  message.warning("Please set an amount first");
                } else if (original.totalUserLicense < 1) {
                  message.warning("Please assign licenses to this user first");
                } else if (original.totalInstructorLicense < 1) {
                  message.warning("Please assign licenses to this user first");
                } else {
                  setCopyLinkState(original._id);
                  copyToClipBoard(original.paymentLink);
                }
              }}
            >
              {copyLink && copyLinkState == original._id ? (
                <Tooltip
                  defaultOpen={true}
                  mouseLeaveDelay={1.85}
                  placement="right"
                  title={"Copied"}
                >
                  <HiCheck color="green" size={25} />
                </Tooltip>
              ) : (
                <Tooltip placement="right" title={"Click to Copy"}>
                  <BiCopy color="#135bed" size={25} />
                </Tooltip>
              )}
            </div>
          ),

          sortable: false,
        },
        {
          Header: "Details",
          Cell: ({ original }) => {
            return (
              <TableButton
                onClick={() => {
                  resaleRef.current = original;
                  setQuoteRef({
                    ...original.userId,
                    quoteStatus: original.status,
                    paymentStatus: original.paymentStatus,
                    quotes: {
                      amount: original.amount,
                      totalUserLicense: original.totalUserLicense,
                      totalInstructorLicense: original.totalInstructorLicense,
                      resaleId: original._id,
                      paymentLink: original.paymentLink,
                      paymentScreenshot: original.paymentScreenshot,
                      reason: original.reason,
                    },
                  });
                  toggleLeadDetailModal(true);
                }}
              >
                view
              </TableButton>
            );
          },
          sortable: false,
        },
        {
          Header: "Payment Status",
          Cell: ({ original }) => <div>{paymentStatus(original.paymentStatus)}</div>,
          sortable: false,
        },
        {
          Header: "Actions",
          Cell: ({ original }) => {
            return (
              <div>
                {original.status == 1 ? (
                  <div style={{ width: 70, display: "flex", justifyContent: "space-between" }}>
                    <div
                      className="delete-icon"
                      onClick={() => {
                        // quoteRef.current = original;
                        let { amount, totalUserLicense, totalInstructorLicense } = original;
                        if (
                          original.userId.userType == "Company/Travel Agent" &&
                          (amount < 1 || totalUserLicense < 1 || totalInstructorLicense < 1)
                        ) {
                          message.warning(
                            "Please fill this candidate's information before approving"
                          );
                        } else if (
                          original.userId.userType == "Individual User" &&
                          amount < 1
                        ) {
                          message.warning(
                            "Please fill this candidate's information before approving"
                          );
                        } else if (original.paymentStatus !== "succeeded") {
                          message.warning("Payment has not been made yet");
                        } else {
                          setQuoteRef(original);
                          setquoteUpdateModal({ visible: true, title: "Approving" });
                        }
                      }}
                    >
                      <Tooltip title="Approving" placement="bottom">
                        <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                      </Tooltip>
                    </div>
                    <div
                      className="delete-icon"
                      onClick={() => {
                        // quoteRef.current = original;
                        setQuoteRef(original);
                        setquoteUpdateModal({ visible: true, title: "Not Approving" });
                      }}
                    >
                      <Tooltip title="Not Approving" placement="bottom">
                        <HiX style={{ fontSize: 20, color: "#f73838" }} />
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  <div>
                    <strong style={{ margin: 0, color: "#f73838" }}>Not Approved</strong>
                  </div>
                )}
              </div>
            );
          },
          sortable: false,
        },
      ];
    } else if (filterRef.current == 2) {
      return [
        {
          Header: "Created At",
          Cell: ({ original }) => <div>{moment(original.createdAt).format("DD-MMM-YYYY")}</div>,
          sortable: false,
        },
        {
          Header: "Full Name",
          Cell: ({ original }) => <div>{original.userId.fullName}</div>,
          sortable: false,
        },
        {
          Header: "Company Name",
          Cell: ({ original }) => <div>{original.userId.companyName}</div>,
          sortable: false,
        },
        {
          Header: "Email",
          Cell: ({ original }) => <div>{original.userId.email}</div>,
          sortable: false,
        },
        {
          Header: "Phone",
          Cell: ({ original }) => <div>{original.userId.phoneNumber}</div>,
          sortable: false,
        },
        {
          Header: "Details",
          Cell: ({ original }) => {
            return (
              <TableButton
                onClick={() => {
                  resaleRef.current = original;
                  setQuoteRef({
                    ...original.userId,
                    quoteStatus: original.status,
                    quotes: {
                      amount: original.amount,
                      totalUserLicense: original.totalUserLicense,
                      totalInstructorLicense: original.totalInstructorLicense,
                      quoteId: original._id,
                      paymentLink: original.paymentLink,
                      paymentScreenshot: original.paymentScreenshot,
                      reason: original.reason,
                    },
                  });
                  toggleLeadDetailModal(true);
                }}
              >
                view
              </TableButton>
            );
          },
          sortable: false,
        },
      ];
    } else if (filterRef.current == 3) {
      return [
        {
          Header: "Created At",
          Cell: ({ original }) => <div>{moment(original.createdAt).format("DD-MMM-YYYY")}</div>,
          sortable: false,
        },
        {
          Header: "Full Name",
          Cell: ({ original }) => <div>{original.userId.fullName}</div>,
          sortable: false,
        },
        {
          Header: "Company Name",
          Cell: ({ original }) => <div>{original.userId.companyName}</div>,
          sortable: false,
        },
        {
          Header: "Email",
          Cell: ({ original }) => <div>{original.userId.email}</div>,
          sortable: false,
        },
        {
          Header: "Phone",
          Cell: ({ original }) => <div>{original.userId.phoneNumber}</div>,
          sortable: false,
        },

        {
          Header: "Total License",
          Cell: ({ original }) => (
            <div style={{ width: 75, textAlign: "center" }}>{original.totalUserLicense}</div>
          ),
          sortable: false,
        },
        {
          Header: "Amount",
          Cell: ({ original }) => (
            <div style={{ width: 50, textAlign: "center" }}>${original.amount}</div>
          ),
          sortable: false,
        },
        {
          Header: "Details",
          Cell: ({ original }) => {
            return (
              <TableButton
                onClick={() => {
                  resaleRef.current = original;
                  setQuoteRef({
                    ...original.userId,
                    quoteStatus: original.status,
                    quotes: {
                      amount: original.amount,
                      totalUserLicense: original.totalUserLicense,
                      totalInstructorLicense: original.totalInstructorLicense,
                      quoteId: original._id,
                      paymentLink: original.paymentLink,
                      paymentScreenshot: original.paymentScreenshot,
                      reason: original.reason,
                    },
                  });
                  toggleLeadDetailModal(true);
                }}
              >
                view
              </TableButton>
            );
          },
          sortable: false,
        },
        {
          Header: "Payment Status",
          Cell: ({ original }) => <div>{paymentStatus(original.paymentStatus)}</div>,
          sortable: false,
        },
      ];
    }
  };

  return (
    <StyledTable>
      <DemoDetailModal
        quotes={checkQuotes(filterRef?.current)}
        demoDetails={false}
        updateResale={true}
        title="Quotation details"
        visible={leadDetailModal}
        onCancel={() => {
          toggleLeadDetailModal(false);
        }}
        data={quoteRef}
        showForm={true}
        copyToClipBoard={copyToClipBoard}
        copyLink={copyLink}
        width={750}
      />

      {quoteUpdateModal.visible && (
        <QuoteReasonModal
          title={`Reason for ${quoteUpdateModal.title}`}
          fromResale={true}
          visible={quoteUpdateModal.visible}
          onCancel={() => {
            setquoteUpdateModal(false);
          }}
          quoteUpdateModal={quoteUpdateModal}
          data={quoteRef}
          showForm={true}
        />
      )}

      {resaleReasonModal.visible && (
        <ResaleReasonModal
          title={`Reason (${resaleReasonModal.title})`}
          visible={resaleReasonModal}
          onCancel={() => {
            setResaleReasonModal(false);
          }}
          inquiryReasonModal={resaleReasonModal}
          data={resaleRef.current}
          showForm={true}
        />
      )}

      <div>
        <ContentHeader
          title="Resale"
          count={allResale?.length}
          tooltip="Create your own Lead"
          onSearch={(e) => {
            handleSearch(e);
          }}
          // onSelectChange={(e) => setFilterByStatus(e)}
          // dropDownOptions={[
          //   { label: "View All", value: "viewAll" },
          //   { label: "Pending", value: 0 },
          //   { label: "Approved", value: 1 },
          //   { label: "Not Approved", value: 2 },
          // ]}
        />

        <MultiSwitchWrapper
          options={[
            { label: "All", value: "all" },
            { label: "Interested", value: 1 },
            { label: "Not Interested", value: 2 },
            { label: "Completed", value: 3 },
          ]}
          onChange={(val) => {
            filterRef.current = val;
            filterSpecificRef.current = val == 1 ? 4 : null;
            handleFilter(val);
          }}
          style={{ margin: "12px 0" }}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={conditionalTablRender()}
        />
      </div>
    </StyledTable>
  );
};

export default Quotes;
