import { INQUIRY_IN_MANAGER } from "redux/constants";

const initialState = { allInquiries: [] };

const inquiriesInmanagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INQUIRY_IN_MANAGER.STORE: {
      const { payload } = action;
      return { ...state, allInquiries: payload };
    }

    case INQUIRY_IN_MANAGER.CHANGE_STATUS: {
      const { adminId, status } = action.payload;
      const currentAdmin = state.allInquiries.find((admin) => admin._id === adminId);
      currentAdmin.status = status;
      return { ...state };
    }

    case INQUIRY_IN_MANAGER.EDIT_DATA: {
      const { payload } = action;
      const currentAdminIndex = state.allInquiries.findIndex(
        (user) => user._id === payload._id
      );
      state.allInquiries.splice(currentAdminIndex, 1, payload);

      return { ...state };
    }

    case INQUIRY_IN_MANAGER.ADD_NEW: {
      const { payload } = action;

      return { ...state, allInquiries: [payload, ...state.allInquiries] };
    }

    default:
      return state;
  }
};

export default inquiriesInmanagerReducer;
