import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, Radio, DatePicker, Tooltip, InputNumber } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, TextAreaWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { ALL_QUOTES, ALL_RESALE } from "redux/constants";
import ImageUploader from "components/uploader/ImageUploader";
import { hanldeMessage } from "Routes";

const initialState = {
  status: false,
  reason: "",
  trailDate: "",
};

const QuoteReasonModal = (props) => {
  const { title, visible, onCancel, data, quoteUpdateModal, fromResale } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({ ...initialState });
  const { isLoading, mutateAsync } = useMutation(Mutations.updateQuote);

  const handleAddReasonInquiry = async () => {
    const variables = {
      reason: state.reason,
      trailDate: state.trailDate,
      trailTime: parseInt(state.trailDate),
      paymentScreenshot: fileList,
      status: quoteUpdateModal.title == "Approving" ? 1 : 2,
      quoteId: data._id,
    };
    try {
      let getResponse = await mutateAsync(variables);
      dispatch({ type: ALL_QUOTES.EDIT_DATA, payload: getResponse.data.data });
      // message.success("Updated Successfully");
      let notificationData = {
        notification: {
          title: "Updated Successfully",
          body: "Please check the details in sales tab",
        },
        data: { notificationType: "Sales" },
      };
      hanldeMessage(notificationData, dispatch);
      onClose();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  // ============UPDATE RESALE API===================
  const { isLoading: updateResaleByIdLoad, mutateAsync: updateResale } = useMutation(
    Mutations.updateResaleById
  );

  const handleUpdateResaleById = async () => {
    const variables = {
      reason: state.reason,
      paymentScreenshot: fileList,
      status: quoteUpdateModal.title == "Approving" ? 3 : 4,
      resaleId: data._id,
    };
    try {
      let getResponse = await updateResale(variables);
      dispatch({ type: ALL_RESALE.EDIT_DATA, payload: getResponse.data.data });
      message.success("Updated Successfully");
      onCancel();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    console.log("state", state);
  }, [state]);

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={500}
    >
      <Form
        form={form}
        onFinish={fromResale ? handleUpdateResaleById : handleAddReasonInquiry}
        validateTrigger="onFinish"
      >
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={24}>
            {/* <p>
              <strong>Note:</strong> Please provide a detailed reason for{" "}
              {quoteUpdateModal.title} this candidate
            </p> */}

            <label>Please set a time duration (in hour) before his quota expires*</label>
            <Form.Item
              name="hours"
              rules={[
                {
                  required: true,
                  // message: "Required",
                  min: 1,
                  max: 100000,
                  type: "number",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%", borderRadius: 6, border: "1px solid #d1d1d1" }}
                name="trailDate"
                // min={1}
                // max={999999}
                value={state.trailDate}
                placeholder="Hours"
                onChange={(e) => {
                  setState({ ...state, trailDate: e });
                }}
              />
            </Form.Item>

            <label>Provide a detailed reason for Approving this candidate*</label>
            <Form.Item name="reason" rules={[{ required: true, message: "Required" }]}>
              <TextAreaWrapper
                rows={3}
                name="reason"
                value={state.reason}
                placeholder="Reason"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          {quoteUpdateModal.title == "Not Approving" ? null : (
            <Col span={24}>
              <div className="label">Payment reciept image* (Evidence)</div>
              <ImageUploader
                uploadLength={1}
                uploadPreset={"payment_receipt"}
                loading={loading}
                setLoading={setLoading}
                fileList={fileList}
                setFileList={setFileList}
              />
            </Col>
          )}
          <Col span={24}>
            <ButtonWrapper
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading || updateResaleByIdLoad}
            >
              Save
            </ButtonWrapper>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default QuoteReasonModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
`;
