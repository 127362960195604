import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import { useMutation, errorHandler, Queries } from "apis/config";
import { message, Tooltip, Form } from "antd";
import { ALL_QUOTES } from "redux/constants";
import styled from "styled-components";
import { HiCheck, HiX } from "react-icons/hi";
import DemoDetailModal from "components/modals/DemoDetailModal";
import { BiCopy } from "react-icons/bi";
import QuoteReasonModal from "components/modals/QuoteReasonModal";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .statsics {
    background-color: #fde5e9;
    color: #f55050;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
`;

const MySales = () => {
  const filterRef = useRef(2);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allQuotes } = useSelector((state) => state.quotes);
  const { data } = useSelector((state) => state.admin);

  const [copyLink, setCopyLink] = useState(false);
  const [copyLinkState, setCopyLinkState] = useState("");
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [quoteRef, setQuoteRef] = useState("");
  const [leadDetailModal, toggleLeadDetailModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("viewAll");
  const [quoteUpdateModal, setquoteUpdateModal] = useState({
    visible: false,
    title: "",
  });

  const { mutateAsync: getAllQuotes, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getAllQuotes
  );

  const handleSearch = (val) => {
    const filteredOptions = allQuotes.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.country.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.gender.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  const closeModal = () => {
    setAddLinkModal(false);
    form.resetFields();
  };

  useEffect(() => {
    const filteredOptions = allQuotes.filter((option) => {
      if (option.status == filterByStatus) {
        return option;
      } else if (filterByStatus == "viewAll") {
        return allQuotes;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allQuotes);
  }, [allQuotes]);

  useEffect(() => {
    getAllQuotes()
      .then(({ data }) => {
        let getSucceded = data.data.filter(
          (item) => item.paymentStatus == "succeeded" && item.status == 1
        );
        dispatch({ type: ALL_QUOTES.STORE, payload: getSucceded });
      })
      .catch((err) => message.error(errorHandler(err)));
    dispatch({ type: "SALES", payload: null });
  }, []);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopyLink(true);
      setTimeout(() => {
        setCopyLink(false);
      }, 2000);
    } catch (err) {
      message.error("Failed to copy!");
    }
  };

  const paymentStatus = (status) => {
    if (status == "succeeded") {
      return <span className="succeeded">{status}</span>;
    } else if (status == "pending") {
      return <span className="pending">{status}</span>;
    } else {
      return <span className="statsics">{status}</span>;
    }
  };

  return (
    <StyledTable>
      <DemoDetailModal
        quotes={true}
        title="Quotation details"
        visible={leadDetailModal}
        onCancel={() => {
          toggleLeadDetailModal(false);
        }}
        data={quoteRef}
        showForm={true}
        copyToClipBoard={copyToClipBoard}
        copyLink={copyLink}
        width={750}
      />

      {quoteUpdateModal.visible && (
        <QuoteReasonModal
          title={`Reason for ${quoteUpdateModal.title}`}
          visible={quoteUpdateModal.visible}
          onCancel={() => {
            setquoteUpdateModal(false);
          }}
          quoteUpdateModal={quoteUpdateModal}
          data={quoteRef}
          showForm={true}
        />
      )}

      <div>
        <ContentHeader
          title="Sales"
          count={tableData?.length}
          tooltip="Create your own Lead"
          onSearch={(e) => {
            handleSearch(e);
          }}
          // onSelectChange={(e) => setFilterByStatus(e)}
          // dropDownOptions={[
          //   { label: "View All", value: "viewAll" },
          //   { label: "Pending", value: 0 },
          //   { label: "Approved", value: 1 },
          //   { label: "Not Approved", value: 2 },
          // ]}
        />

        <TableWrapper
          tableData={tableData}
          totalCount={tableData?.length}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => <div>{original.demoId.fullName}</div>,
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => <div>{original.demoId.email}</div>,
              sortable: false,
            },
            {
              Header: "Phone",
              Cell: ({ original }) => <div>{original.demoId.phoneNumber}</div>,
              sortable: false,
            },

            {
              Header: "Total License",
              Cell: ({ original }) => (
                <div style={{ width: 75, textAlign: "center" }}>
                  {original.totalUserLicense}
                </div>
              ),
              sortable: false,
            },
            {
              Header: "Amount",
              Cell: ({ original }) => (
                <div style={{ width: 50, textAlign: "center" }}>${original.amount}</div>
              ),
              sortable: false,
            },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      // quoteRef.current = original;
                      setQuoteRef({
                        ...original.demoId,
                        quoteStatus: original.status,
                        quotes: {
                          amount: original.amount,
                          totalUserLicense: original.totalUserLicense,
                          totalInstructorLicense: original.totalInstructorLicense,
                          quoteId: original._id,
                          paymentLink: original.paymentLink,
                          paymentScreenshot: original.paymentScreenshot,
                          reason: original.reason,
                        },
                      });
                      toggleLeadDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Payment Status",
              Cell: ({ original }) => <div>{paymentStatus(original.paymentStatus)}</div>,
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default MySales;
