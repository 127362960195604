import React, { useEffect, useState } from "react";
import { Badge, Menu, message } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import InquirySidebar from "../../images/sidebar/inquiry-sidebar.png";
import LeadSidebar from "../../images/sidebar/lead-sidebar.png";
import DemoSidebar from "../../images/sidebar/demo-sidebar.png";
import QuoteSidebar from "../../images/sidebar/quotation-sidebar.png";
import SalesSidebar from "../../images/sidebar/sales-sidebar.png";
import MonitoringSidebar from "../../images/sidebar/monitoring-sidebar.png";
import DashboardSidebar from "../../images/sidebar/dashboard-sidebar.png";
import InactiveUserSidebar from "../../images/sidebar/inactiveUsers-sidebar.png";
import ResaleSidebar from "../../images/sidebar/resale-sidebar.png";
import { logoutAction } from "redux/actions";

import Icon from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Queries } from "apis/queries";
import { errorHandler } from "helpers/errorHandler";
import { useMutation } from "react-query";

const SidebarContent = ({ collapsed }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [allSales, setAllSales] = useState([]);
  const [allInactiveUser, setAllInactiveUser] = useState([]);
  const [allActiveUser, setAllActiveUser] = useState([]);

  const { inquiry, lead, demo, quotation, sales, resale } = useSelector(
    (state) => state.notificationCounter
  );
  const { allLeads } = useSelector((state) => state.leads);
  const { allDemos } = useSelector((state) => state.demos);
  const { allQuotes } = useSelector((state) => state.quotes);
  const { allUsers } = useSelector((state) => state.users);
  const { allResale } = useSelector((state) => state.resale);

  useEffect(() => {
    let getSucceded = allQuotes?.filter(
      (item) => item.paymentStatus == "succeeded" && item.status == 1
    );
    setAllSales(getSucceded);
  }, [allQuotes]);

  const { mutateAsync } = useMutation(Queries.getDashboardStats, {
    onError: (err) => {
      if (err.response.data.status == 403) {
        dispatch(logoutAction());
        message.info("Session expired. Please Login again");
      } else {
        message.error(errorHandler(err));
      }
    },
  });

  useEffect(() => {
    mutateAsync();
  }, [location.pathname]);

  useEffect(() => {
    const filteredActiveData = allUsers.filter((registration) => {
      return registration.status == 1;
    });
    setAllActiveUser(filteredActiveData);

    const filteredInActiveData = allUsers.filter((registration) => {
      return registration.status == 2;
    });
    setAllInactiveUser(filteredInActiveData);
  }, [allUsers]);

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      selectedKeys={location.pathname}
    >
      <Menu.Item
        key="/"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={18}
                src={DashboardSidebar}
              />
            )}
          />
        }
      >
        <Link to="/" style={{ color: "#fff" }}>
          Dashboard
        </Link>
      </Menu.Item>
      <Menu.Item
        key="/opportunity"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter:
                    location.pathname == "/opportunity" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={ResaleSidebar}
              />
            )}
          />
        }
      >
        <Link to="/opportunity" style={{ color: "#fff" }}>
          Opportunity{" "}
          <Badge
            className="sidebar-badge"
            size="small"
            count={resale}
            style={{
              background: "#f92020",
              boxShadow: "none",
              fontSize: 8,
              color: "#fff",
            }}
          />
        </Link>
      </Menu.Item>
      <Menu.Item
        key="/inquiries"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter:
                    location.pathname == "/inquiries" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={InquirySidebar}
              />
            )}
          />
        }
      >
        <Link to="/inquiries" style={{ color: "#fff" }}>
          Inquiry
          <Badge
            className="sidebar-badge"
            count={inquiry}
            size={"small"}
            style={{
              background: "#f92020",
              boxShadow: "none",
              fontSize: 10,
              color: "#fff",
            }}
          />
        </Link>
      </Menu.Item>
      <Menu.Item
        key="/leads"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/leads" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={LeadSidebar}
              />
            )}
          />
        }
      >
        <Link to="/leads" style={{ color: "#fff" }}>
          Lead{" "}
          <Badge
            className="sidebar-badge"
            count={lead}
            size={"small"}
            style={{
              background: "#f92020",
              boxShadow: "none",
              fontSize: 10,
              color: "#fff",
            }}
          />
        </Link>
      </Menu.Item>
      <Menu.Item
        key="/demos"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/demos" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={20}
                src={DemoSidebar}
              />
            )}
          />
        }
      >
        <Link to="/demos" style={{ color: "#fff" }}>
          Demo{" "}
          <Badge
            className="sidebar-badge"
            count={demo}
            size={"small"}
            style={{
              background: "#f92020",
              boxShadow: "none",
              fontSize: 10,
              color: "#fff",
            }}
          />
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="/upload-application" icon={<Icon component={MdOutlineUploadFile} />}>
        <Link to="/upload-application" style={{ color: "#fff" }}>
          Upload applications
        </Link>
      </Menu.Item> */}
      <Menu.Item
        key="/quotes"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/quotes" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={QuoteSidebar}
              />
            )}
          />
        }
      >
        <Link to="/quotes" style={{ color: "#fff" }}>
          Quotation{" "}
          <Badge
            className="sidebar-badge"
            count={quotation}
            size={"small"}
            style={{
              background: "#f92020",
              boxShadow: "none",
              fontSize: 10,
              color: "#fff",
            }}
          />
        </Link>
      </Menu.Item>

      <Menu.Item
        key="/sales"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/sales" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={SalesSidebar}
              />
            )}
          />
        }
      >
        <Link to="/sales" style={{ color: "#fff" }}>
          Sales{" "}
          <Badge
            className="sidebar-badge"
            count={sales}
            size={"small"}
            style={{
              background: "#f92020",
              boxShadow: "none",
              fontSize: 10,
              color: "#fff",
            }}
          />
        </Link>
      </Menu.Item>

      <Menu.Item
        key="/monitoring"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter:
                    location.pathname == "/monitoring" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={MonitoringSidebar}
              />
            )}
          />
        }
      >
        <Link to="/monitoring" style={{ color: "#fff" }}>
          Monitoring{" "}
          <Badge
            className="sidebar-badge"
            count={allActiveUser?.length}
            style={{
              background: "#012169",
              boxShadow: "none",
              fontSize: 10,
              color: location.pathname == "/monitoring" ? "#fff" : "#fff",
            }}
          />
        </Link>
      </Menu.Item>

      <Menu.Item
        key="/inactive-clients"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter:
                    location.pathname == "/inactive-clients"
                      ? "brightness(1)"
                      : "brightness(0.65)",
                }}
                width={22}
                src={InactiveUserSidebar}
              />
            )}
          />
        }
      >
        <Link to="/inactive-clients" style={{ color: "#fff" }}>
          Inactive Clients{" "}
          <Badge
            className="sidebar-badge"
            count={allInactiveUser?.length}
            style={{
              background: "#012169",
              boxShadow: "none",
              fontSize: 10,
              color: location.pathname == "/inactive-clients" ? "#fff" : "#7787a0",
            }}
          />
        </Link>
      </Menu.Item>

      <Menu.Item
        key="/resale"
        icon={
          <Icon
            component={() => (
              <img
                style={{
                  filter: location.pathname == "/resale" ? "brightness(1)" : "brightness(0.65)",
                }}
                width={22}
                src={ResaleSidebar}
              />
            )}
          />
        }
      >
        <Link to="/resale" style={{ color: "#fff" }}>
          Resale{" "}
          <Badge
            className="sidebar-badge"
            size="small"
            count={resale}
            style={{
              background: "#f92020",
              boxShadow: "none",
              fontSize: 8,
              color: "#fff",
            }}
          />
        </Link>
      </Menu.Item>
    </Menu>
  );
};
export default SidebarContent;
