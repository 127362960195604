import React, { useState, useEffect, useRef } from "react";
import TableWrapper from "../../components/table/reactTable";
import { ButtonWrapper, TableButton } from "../../components/buttons";
import { message, Modal, Tooltip, Form } from "antd";
import ContentHeader from "../../components/header/contentHeader";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { useHistory } from "react-router-dom";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import {
  MdOutlineAddLink,
  MdOutlineLinkOff,
  MdOutlineFileDownload,
  MdOutlineFileUpload,
} from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import { RiFileDownloadLine } from "react-icons/ri";
import { LoadingOutlined } from "@ant-design/icons";
import { InputWrapper } from "components/input";
import ApplicationModal from "components/modals/applicationModal";
import styled from "styled-components";
import { CgTrash } from "react-icons/cg";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;

const Companies = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [meetingLinkState, setMetingLinkState] = useState("");
  const [tableData, setTableData] = useState([]);
  const [detailModal, toggleDetailModal] = useState(false);
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);
  const [confimationDeleteModal, setConfimationDeleteModal] = useState({
    visible: false,
    userId: "",
  });
  const user = useRef({});
  /**
   * Mutation and handler for changing single sub-admin status
   */
  const { mutateAsync: updateMeetingLink, isLoading: meetingLinkLoading } = useMutation(
    Mutations.updateMeetingLink
  );

  const { mutateAsync: getAllCompanies, isLoading: getCompaniesLoading } = useMutation(
    Queries.getAllCompanies
  );

  useEffect(() => {
    getAllCompanies()
      .then(({ data }) => {
        setTableData(data.data);
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  const updateTableData = (companyId, data) => {
    // console.log("Current Index", companyId, data);
    user.current = data;
    const currentIndex = tableData.findIndex((company) => company._id === companyId);
    tableData.splice(currentIndex, 1, data);
    setTableData([...tableData]);
  };

  const editMeetingLink = async () => {
    // console.log("User", user.current);
    try {
      const companyId = user.current._id;
      const { data } = await updateMeetingLink({
        companyId,
        meetingLink: meetingLinkState,
      });
      updateTableData(companyId, data.data);
      closeModal();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const closeModal = () => {
    setMetingLinkState("");
    setAddLinkModal(false);
    form.resetFields();
  };

  const { mutateAsync: deleteCompany, isLoading: deleteCompanyLoading } = useMutation(
    Mutations.deleteCompany
  );

  const handleDeleteCompany = async () => {
    try {
      const { data } = await deleteCompany({
        companyId: confimationDeleteModal.userId,
      });
      setConfimationDeleteModal({ visible: false });
      setTableData(data.data);
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledTable>
      <Modal
        visible={confimationDeleteModal.visible}
        title={`Are you sure?`}
        footer={
          <ButtonWrapper
            style={{ width: "50%" }}
            onClick={handleDeleteCompany}
            loading={deleteCompanyLoading}
          >
            Delete
          </ButtonWrapper>
        }
        onCancel={() => setConfimationDeleteModal({ visible: false })}
      >
        <div style={{ display: "grid", textAlign: "center" }}>
          <ExclamationCircleOutlined
            style={{ fontSize: 54, color: "#ffbe04", marginBottom: 22 }}
          />
          <p style={{ margin: 0 }}>
            <strong>Note:</strong> All the history of this company will be deleted!
          </p>
        </div>
      </Modal>

      <RegistrationDetailModal
        title="Company details"
        visible={detailModal}
        onCancel={() => toggleDetailModal(false)}
        data={user.current}
      />

      <ApplicationModal
        visible={applicationModal}
        onCancel={() => {
          setApplicationModal(false);
        }}
        company={user.current}
        onComplete={updateTableData}
      />

      <Modal
        visible={addLinkModal}
        title="Add Training Link"
        footer={null}
        onCancel={closeModal}
      >
        <Form form={form} onFinish={editMeetingLink} validateTrigger="onFinish">
          <Form.Item
            name="Training Link"
            rules={[{ required: true, message: "Enter a valid URL", type: "url" }]}
          >
            <InputWrapper
              name="meetingLink"
              suffix={
                <Tooltip title="Make sure to add Https or Http before the link">
                  <BsInfoCircleFill style={{ color: "blue" }} />
                </Tooltip>
              }
              placeholder="https://zoom.us/"
              onChange={(e) => {
                setMetingLinkState(e.target.value);
              }}
            />
          </Form.Item>
          <ButtonWrapper
            htmlType="submit"
            loading={meetingLinkLoading}
            style={{ marginTop: 32 }}
          >
            Add
          </ButtonWrapper>
        </Form>
      </Modal>

      <div>
        <ContentHeader title="Companies" />

        <TableWrapper
          tableData={tableData}
          loading={getCompaniesLoading}
          columns={[
            // {
            //   Header: "Company Name",
            //   accessor: "companyName",
            //   sortable: false,
            // },
            {
              Header: () => <div>Company Name</div>,
              Cell: ({ original }) => (
                <div
                  style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  title={original.companyName}
                >
                  {original.companyName}
                </div>
              ),
              sortable: false,
            },
            {
              Header: () => (
                <div style={{ minWidth: 140, display: "flex", justifyContent: "center" }}>
                  Email
                </div>
              ),
              Cell: ({ original }) => (
                <div
                  style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  title={original.email}
                >
                  {original.email}
                </div>
              ),
              sortable: false,
            },
            // {
            //   Header: "Phone",
            //   Cell: ({ original }) => <div>{original.phoneNumber}</div>,
            //   sortable: false,
            // },

            {
              Header: () => (
                <div
                  style={{ minWidth: 120, display: "flex", justifyContent: "center" }}
                  title="Total / Remaining"
                >
                  License
                </div>
              ), // total / remaining
              Cell: ({ original }) => (
                <div
                  style={{ minWidth: 120, display: "flex", justifyContent: "center" }}
                  title="Total / Remaining"
                >
                  {original.totalLicense} / {original.remainingLicense}
                </div>
              ),
              sortable: false,
            },

            {
              Header: () => <div>Training Link</div>,
              Cell: ({ original }) => (
                <div style={{ minWidth: 80, textAlign: "center" }}>
                  {original.meetingLink !== "" && original.meetingLink !== null ? (
                    meetingLinkLoading && user.current._id === original._id ? (
                      <LoadingOutlined style={{ fontSize: 22, color: "blue" }} />
                    ) : (
                      <MdOutlineLinkOff
                        onClick={() => {
                          user.current = original;
                          editMeetingLink();
                        }}
                        style={{ fontSize: 22, color: "blue", cursor: "pointer" }}
                      />
                    )
                  ) : (
                    <MdOutlineAddLink
                      onClick={() => {
                        user.current = original;
                        setAddLinkModal(true);
                      }}
                      style={{ fontSize: 22, color: "blue", cursor: "pointer" }}
                    />
                  )}
                </div>
              ),
              sortable: false,
            },

            {
              Header: () => <div>Applications</div>,
              Cell: ({ original }) => (
                <div style={{ minWidth: 80, textAlign: "center" }}>
                  <RiFileDownloadLine
                    onClick={() => {
                      user.current = original;
                      setApplicationModal(true);
                    }}
                    style={{ fontSize: 22, color: "blue", cursor: "pointer" }}
                  />
                </div>
              ),
              sortable: false,
            },

            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      user.current = original;
                      toggleDetailModal(true);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },

            {
              Header: "Users",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      const companyName = original.companyName.replaceAll(" ", "-");
                      history.push(`/${companyName}/${original._id}/users`);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Instructors",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      const companyName = original.companyName.replaceAll(" ", "-");
                      history.push(`/${companyName}/${original._id}/instructors`);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Packages",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      const companyName = original.companyName.replaceAll(" ", "-");
                      history.push(`/${companyName}/${original._id}/packages`);
                    }}
                  >
                    view
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Delete",
              Cell: ({ original }) => {
                return (
                  <div style={{ width: 45, display: "flex", justifyContent: "center" }}>
                    <div
                      className="delete-icon"
                      title="Delete"
                      onClick={() => {
                        setConfimationDeleteModal({ visible: true, userId: original._id });
                      }}
                    >
                      <CgTrash style={{ fontSize: 18, color: "#135bed" }} />
                    </div>
                  </div>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </StyledTable>
  );
};

export default Companies;
