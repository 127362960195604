import { useState, useRef } from "react";
import { Modal, message } from "antd";
import styled from "styled-components";
import { useMutation, Mutations, errorHandler } from "apis/config";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";
import UploadAppModal from "./uploadAppModal";

const ApplicationModal = (props) => {
  const { visible, onCancel, company } = props;
  const [addModal, setAddModal] = useState({ visible: false, type: "" });
  const idRef = useRef("");
  const { mutateAsync: deleteApp, isLoading: deleteAppLoading } = useMutation(
    Mutations.deleteApplication
  );
  const handleDelete = async (type) => {
    try {
      await deleteApp({ applicationId: idRef.current });
      props.onComplete(company._id, { ...company, [type]: "" });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const closeModal = () => {
    onCancel();
  };

  return (
    <>
      <UploadAppModal
        title="Upload Application"
        visible={addModal.visible}
        onCancel={() => setAddModal({ visible: false, type: "" })}
        company={company}
        fileData={addModal}
        onComplete={props.onComplete}
      />
      <Modal
        visible={visible}
        title="Applications"
        footer={null}
        onCancel={closeModal}
        width={600}
      >
        <StyledContent>
          <div
            className={`application-box ${
              company.multiplayerApplicationPath === "" ? "empty" : ""
            }`}
          >
            {company.multiplayerApplicationPath !== "" ? (
              <div
                className="delete-btn"
                style={{ pointerEvents: deleteAppLoading ? "none" : "all" }}
                onClick={() => {
                  idRef.current = company.multiplayerApplicationId;
                  handleDelete("multiplayerApplicationPath");
                }}
              >
                {deleteAppLoading && idRef.current === company.multiplayerApplicationId ? (
                  <LoadingOutlined className="delete-icon" />
                ) : (
                  <BiTrash className="delete-icon" />
                )}
              </div>
            ) : null}
            <p style={{ fontSize: 14, marginTop: 12 }}>Multiplayer application</p>
            {company.multiplayerApplicationPath !== "" ? (
              <div title="Download">
                <a
                  href={`${process.env.REACT_APP_BACKEND_ADMIN_URL}${company.multiplayerApplicationPath}`}
                  target={"_blank"}
                >
                  <MdOutlineFileDownload
                    style={{ fontSize: 32, color: "#fff", cursor: "pointer" }}
                  />
                </a>
              </div>
            ) : (
              <div
                title="Upload Application"
                onClick={() => {
                  setAddModal({
                    visible: true,
                    type: "multiplayerApplicationPath",
                    id: "multiplayerApplicationId",
                    fileType: 2,
                  });
                  closeModal();
                }}
              >
                <MdOutlineFileUpload
                  style={{ fontSize: 32, color: "#135bed", cursor: "pointer" }}
                />
              </div>
            )}
          </div>

          <div
            className={`application-box ${
              company.instructorApplicationPath === "" ? "empty" : ""
            }`}
          >
            {company.instructorApplicationPath !== "" ? (
              <div
                className="delete-btn"
                style={{ pointerEvents: deleteAppLoading ? "none" : "all" }}
                onClick={() => {
                  idRef.current = company.instructorApplicationId;
                  handleDelete("instructorApplicationPath");
                }}
              >
                {deleteAppLoading && idRef.current === company.instructorApplicationId ? (
                  <LoadingOutlined className="delete-icon" />
                ) : (
                  <BiTrash className="delete-icon" />
                )}
              </div>
            ) : null}
            <p style={{ fontSize: 14, marginTop: 12 }}>Guide application</p>
            {company.instructorApplicationPath !== "" ? (
              <a
                href={`${process.env.REACT_APP_BACKEND_ADMIN_URL}${company.instructorApplicationPath}`}
                target={"_blank"}
                title="Download"
              >
                <MdOutlineFileDownload
                  style={{ fontSize: 32, color: "#fff", cursor: "pointer" }}
                />
              </a>
            ) : (
              <div
                title="Upload Application"
                onClick={() => {
                  setAddModal({
                    visible: true,
                    type: "instructorApplicationPath",
                    id: "instructorApplicationId",
                    fileType: 3,
                  });
                  closeModal();
                }}
              >
                <MdOutlineFileUpload
                  style={{ fontSize: 32, color: "#135bed", cursor: "pointer" }}
                />
              </div>
            )}
          </div>
        </StyledContent>
      </Modal>{" "}
    </>
  );
};

export default ApplicationModal;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .application-box {
    text-align: center;
    background-color: #135bed;
    border-radius: 6px;
    width: 100%;
    padding: 24px;
    margin-left: 4px;
    position: relative;
    color: #fff;
    letter-spacing: 2px;
    font-weight: 600;

    &.empty {
      opacity: 0.7;
      border: 1px solid #135bed;
      background-color: #fff;
      color: #135bed;
    }
  }

  .delete-btn {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 6px;
    top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    color: #fff;
    transition: 0.3s;
    :hover {
      background-color: #fff;
      color: #135bed;
    }
  }

  .button {
    padding: 12px;
    :hover {
      background-color: #fff;
      color: #135bed;
    }
  }
`;
