import { useState, useRef, useEffect } from "react";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableButton } from "components/buttons";
import { Queries, useMutation, errorHandler, Mutations } from "apis/config";
import { message, Popover, Tooltip } from "antd";
import moment from "moment";
import styled from "styled-components";
import { TiThumbsUp } from "react-icons/ti";
import { HiCheck } from "react-icons/hi";
import { ALL_COMPLAINTS } from "redux/constants";

const Feedback = ({ loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterByStatus, setFilterByStatus] = useState(null);
  const [tableData, setTableData] = useState([]);
  const { allComplaints } = useSelector((state) => state.complaints);

  const { mutateAsync: getAllComplaints, isLoading: getInstructorLoading } = useMutation(
    Queries.getAllComplaints
  );

  const { mutateAsync: updateComplaintById, isLoading: changeStatusLoading } = useMutation(
    Mutations.updateComplaintById
  );

  const updateHandler = async (status, complaintId) => {
    try {
      await updateComplaintById({ complaintId, status });
      dispatch({ type: ALL_COMPLAINTS.CHANGE_STATUS, payload: { id: complaintId, status } });
      message.success("Updated successfully");
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  useEffect(() => {
    const filteredOptions = allComplaints.filter((option) => {
      if (filterByStatus == 1) {
        return allComplaints;
      } else if (option.status == filterByStatus) {
        return option;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allComplaints);
  }, [allComplaints]);

  useEffect(() => {
    getAllComplaints()
      .then(({ data }) => {
        dispatch({ type: ALL_COMPLAINTS.STORE, payload: data.data });
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  return (
    <StyleWrapper>
      <ContentHeader
        title="Feedback / Complaints"
        onSelectChange={(e) => setFilterByStatus(e)}
        dropDownOptions={[
          { label: "View All", value: 1 },
          { label: "Resolved", value: 2 },
          { label: "Appriciated", value: 3 },
        ]}
      />
      <TableWrapper
        data={tableData}
        totalCount={tableData?.length}
        loading={getInstructorLoading || changeStatusLoading}
        columns={[
          {
            Header: "Subject",
            Cell: ({ original }) => {
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "40%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Popover
                      style={{ width: "200px" }}
                      content={original.message}
                      title={original.subject}
                      trigger="hover"
                    >
                      {original.subject}
                    </Popover>
                  </div>
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "Message",
            Cell: ({ original }) => {
              return (
                <div
                  style={{
                    textAlign: "center",
                    width: "80%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Popover
                    style={{ width: "200px" }}
                    content={original.message}
                    title={original.subject}
                    trigger="hover"
                  >
                    {original.message}
                  </Popover>
                </div>
              );
            },
            sortable: false,
          },
          {
            Header: "Created",
            Cell: ({ original }) => {
              return (
                <div style={{ textAlign: "start", width: "100%" }}>
                  {moment(original.createdAt).format("DD MMM YYYY")}
                </div>
              );
            },
            sortable: false,
          },

          {
            Header: "Status",
            Cell: ({ original }) => {
              if (original.status == 1) {
                return (
                  <div style={{ width: 70, display: "flex", justifyContent: "space-between" }}>
                    <div className="delete-icon" onClick={() => updateHandler(3, original._id)}>
                      <Tooltip title="Appriciate" placement="bottom">
                        <TiThumbsUp style={{ fontSize: 20, color: "#135bed" }} />
                      </Tooltip>
                    </div>
                    <div className="delete-icon" onClick={() => updateHandler(2, original._id)}>
                      <Tooltip title="Resolved" placement="bottom">
                        <HiCheck style={{ fontSize: 20, color: "#4fc06b" }} />
                      </Tooltip>
                    </div>
                  </div>
                );
              } else if (original.status == 2) {
                return (
                  <div className="succeeded">
                    <strong style={{ margin: 0 }}>Resolved</strong>
                  </div>
                );
              } else if (original.status == 3) {
                return (
                  <div className="succeeded">
                    <strong style={{ margin: 0 }}>Appriciated</strong>
                  </div>
                );
              }
            },
            sortable: false,
          },
        ]}
      />
    </StyleWrapper>
  );
};

export default Feedback;

const StyleWrapper = styled.div`
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }

  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
`;
