import Cookies from "js-cookie";
import { message } from "antd";
import { io } from "socket.io-client";
import { wsModule } from "apis/config";

const token = Cookies.get("token");
export let getUserData = () => {
  let userData = Cookies.get("_W_U_DATA_");
  userData = JSON.parse(userData);
  return userData._id;
};

const adminSocketRequest = (type, cb, messageId, message) => {
  const ws = new WebSocket(wsModule);
  ws.onopen = (event) => {
    console.log("connection established from client.", event);
    ws.send(
      JSON.stringify({
        token: token,
        type: type,
        myId: getUserData(),
        messageId: messageId,
        message: message,
      })
    );
  };
  ws.onmessage = (event) => {
    console.log("Message from server recieved", event);
    if (event.data) {
      let data = JSON.parse(event.data);
      console.log("Data from server recieved", data);
      if (data.error) {
        message.error(`${data.error}`);
      } else {
        if (cb) {
          cb(data);
        }
      }
    }
  };
};

export default adminSocketRequest;
