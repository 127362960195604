import { Row, Col, Tooltip, Badge } from "antd";
import styled from "styled-components";
import SelectWrapper from "../input/selectWrapper";
import { InputWrapper } from "../input";
import { DownloadOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import RangePickerWrapper from "components/input/rangePicker";
import { IoIosAddCircleOutline } from "react-icons/io";
import SwitchableRangePicker from "components/input/SwitchableRangePicker";
import { AddButton, ButtonWrapper } from "components/buttons";

const StyledContent = styled.section`
  /* margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "24px")}; */
  .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .heading {
    font-size: 32px;
    color: #135bed;
    font-weight: 600;
    margin: 0 0 8px 0;
  }
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #135bed;
    margin: 0 12px;
  }
`;

const ContentHeader = (props) => {
  const {
    title,
    count,
    onSearch,
    showRangeSelect,
    onSelectChange,
    onAdd,
    switchableRangePickerState,
    setSelectedDate,
    type,
    setType,
  } = props;
  return (
    <StyledContent>
      <Row
        style={{ marginBottom: props.marginBottom ? props.marginBottom : 24 }}
        justify="space-between"
        align="middle"
      >
        <Col>
          <div className="heading-wrapper">
            <h1 className="heading">{title}</h1>
            {count ? (
              <>
                <div className="dot" />
                <h1 className="heading">({count})</h1>
              </>
            ) : null}
          </div>
        </Col>

        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            {onSearch ? (
              <InputWrapper
                prefix={<SearchOutlined />}
                placeholder="Search"
                onChange={onSearch}
              />
            ) : null}

            {/* <div style={{ width: 32 }} /> */}
            {showRangeSelect ? (
              <RangePickerWrapper style={{ height: 40, borderColor: "#d1d1d1" }} />
            ) : null}
            {/* <div style={{ width: 32 }} /> */}

            {switchableRangePickerState ? (
              <SwitchableRangePicker
                setSelectedDate={setSelectedDate}
                type={type}
                setType={setType}
              />
            ) : null}

            <div style={{ margin: "0px 10px" }}>
              {onSelectChange ? (
                <SelectWrapper
                  width={props.selectWidth}
                  size="large"
                  defaultValue="View All"
                  options={
                    props.dropDownOptions
                      ? props.dropDownOptions
                      : [
                          { label: "View All", value: "all" },
                          { label: "Active", value: "active" },
                          { label: "Inactive", value: "inactive" },
                        ]
                  }
                  onChange={onSelectChange}
                />
              ) : null}
            </div>

            <div style={{ width: 50 }}>
              {onAdd ? (
                <Tooltip title={props.tooltip ? props.tooltip : "Add new"}>
                  <IoIosAddCircleOutline
                    onClick={onAdd}
                    style={{
                      cursor: "pointer",
                      color: "#135bed",
                      fontSize: 48,
                      height: "auto",
                      marginLeft: 12,
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>

            {props.onExport ? (
              <div>
                <ButtonWrapper onClick={props.onExport}>
                  <DownloadOutlined style={{ marginRight: 6, color: "#000" }} />
                  Download
                </ButtonWrapper>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </StyledContent>
  );
};

ContentHeader.defaultProps = {
  title: "",
  count: null,
  onSearch: false,
  onFilter: false,
  showRangeSelect: false,
  onSelectChange: false,
  onAdd: false,
};

export default ContentHeader;
