import { useEffect, useState } from "react";
import { Row, Col, message, Form, Modal } from "antd";
import VideoCard from "components/cards/videoCard";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import ContentHeader from "components/header/contentHeader";
import { InputWrapper, TextAreaWrapper } from "components/input";
import { ButtonWrapper } from "components/buttons";
import VideoCardSkeleton from "components/cards/videoCardSkeleton";
import styled from "styled-components";

const StyledDiv = styled.div`
  .white-background {
    background-color: #fff;
    padding: 60px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) !important;
    min-height: 60vh;
    border-radius: 6px;
  }
`;

const KnowledgeCenter = (props) => {
  const [form] = Form.useForm();
  const { mutateAsync, isLoading: getAllTutorialLoading } = useMutation(
    Queries.getAllTutorials
  );
  const { mutateAsync: addTutorial, isLoading: addTutorialLoading } = useMutation(
    Mutations.addTutorial
  );
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [addVideoModal, toggleVideoModal] = useState(false);
  const [videoData, setVideoData] = useState({ url: "", title: "", description: "" });

  useEffect(() => {
    mutateAsync()
      .then(({ data }) => {
        setKnowledgeData(data.data);
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  const closeModal = () => {
    toggleVideoModal(false);
    form.resetFields();
    setVideoData({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVideoData({ ...videoData, [name]: value });
  };

  const handleAddTutorial = async () => {
    try {
      const { data } = await addTutorial(videoData);
      setKnowledgeData(data.data);
      closeModal();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  return (
    <StyledDiv>
      <Modal visible={addVideoModal} title="Add video" onCancel={closeModal} footer={null}>
        <Form form={form} onFinish={handleAddTutorial}>
          <div className="form-label">Video URL</div>
          <Form.Item name="URL" rules={[{ required: true }]}>
            <InputWrapper
              name="url"
              placeholder="http://yotube/com/watch/..."
              value={videoData.url}
              onChange={handleChange}
            />
          </Form.Item>

          <div className="form-label">Title</div>
          <Form.Item name="Title" rules={[{ required: true }]}>
            <InputWrapper
              name="title"
              placeholder="Title"
              value={videoData.title}
              onChange={handleChange}
            />
          </Form.Item>

          <div className="form-label">Description</div>
          <Form.Item name="Description">
            <TextAreaWrapper
              name="description"
              placeholder="description"
              value={videoData.description}
              onChange={handleChange}
              rows={5}
            />
          </Form.Item>
          <ButtonWrapper htmlType="submit" loading={addTutorialLoading}>
            Add
          </ButtonWrapper>
        </Form>
      </Modal>

      <ContentHeader title="Knowledge Center" onAdd={() => toggleVideoModal(true)} />
      <div className="white-background">
        <Row gutter={[24, 24]}>
          {getAllTutorialLoading
            ? [0, 1, 2, 3, 4, 5].map((item) => (
                <Col key={item} sm={12} md={12} lg={8}>
                  <VideoCardSkeleton />
                </Col>
              ))
            : knowledgeData.map((item) => (
                <Col key={item._id} sm={12} md={12} lg={8}>
                  <VideoCard {...item} setKnowledgeData={setKnowledgeData} />
                </Col>
              ))}
        </Row>
      </div>
    </StyledDiv>
  );
};

export default KnowledgeCenter;
