import { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import DonutPlot from "components/charts/donutPlot";
import ContentHeader from "components/header/contentHeader";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import moment from "moment";
import SaleDashboardCard from "components/cards/saleDashboardCard";
import { useSelector } from "react-redux";
import InquiryIcon from "images/inquiry-F8CBA6.png";
import SaleColumnPlot from "components/charts/SaleColumnPlot";

const initialState = {
  totalInquiries: [],
  totalLeads: [],
  totalDemos: [],
  totalQuotes: [],
  salesAchievedData: [],
  salesTargetData: [],
  totalTravelAgents: [],
  totalUserLicense: 0,
  totalPayments: 0,
};

const initialGraphState = {
  demoTarget: 0,
  paymentTarget: 0,
  saleTarget: 0,
  achievedSaleData: 0,
};

const Dashboard = () => {
  const { data } = useSelector((state) => state.admin);
  const [type, setType] = useState("month");
  const [selectedDate, setSelectedDate] = useState("");

  const [statsData, setStatsData] = useState("");
  const [filteredStatsData, setFilteredStatsData] = useState({ ...initialState });
  const [graphsData, setGraphsData] = useState({ ...initialState });

  const [targetGraphValues, setTargetGraphValues] = useState({ ...initialGraphState });
  const [targetData, setTargetData] = useState("");

  const { mutateAsync, isLoading } = useMutation(Queries.getSalesDashboard, {
    onSuccess: ({ data }) => {
      setStatsData(data.data);

      const totalInquiries = mapData(data.data.totalInquiries);
      const totalLeads = mapData(data.data.totalLeads);
      const totalDemos = mapData(data.data.totalDemos);
      const totalQuotes = mapData(data.data.totalQuotes);
      const totalUserLicense = [];
      setGraphsData({
        ...graphsData,
        totalInquiries,
        totalLeads,
        totalDemos,
        totalQuotes,
        totalUserLicense,
      });
    },
    onError: (err) => {
      message.error(errorHandler(err));
    },
  });

  const { mutateAsync: getKPIData, isLoading: KPIDataLoading } = useMutation(Queries.getMyKPI, {
    onSuccess: ({ data }) => {
      // const demoTarget = data?.data?.target?.reduce(function (acc, obj) {
      //   return acc + obj.demoTarget;
      // }, 0);
      // const saleTarget = data?.data?.target?.reduce(function (acc, obj) {
      //   return acc + obj.saleTarget;
      // }, 0);
      // const paymentTarget = data?.data?.target?.reduce(function (acc, obj) {
      //   return acc + obj.paymentTarget;
      // }, 0);
      // setTargetGraphValues({ ...targetGraphValues, saleTarget, demoTarget, paymentTarget });
      setTargetData(data.data);
    },
    onError: (err) => {
      message.error(errorHandler(err));
    },
  });

  const checkCompareDate = (createdAt) => {
    let endDate = moment(selectedDate).endOf(type).format("YYYY-MM-DD");
    let getCompareDate = moment(createdAt).isBetween(selectedDate, endDate);
    console.log("createdAt", createdAt);
    console.log("endDate", endDate);

    return getCompareDate;
  };

  // =============FILTER GRAPHS DATA=============
  const filterGraphData = () => {
    //For demoTarget

    let filterData = targetData?.target?.filter((item) => {
      if (type == "week") {
        let getWeek = moment(selectedDate).format("MMMM-YYYY");
        if (item.month == getWeek) {
          return item;
        }
      }
      if (type == "month") {
        let getMonth = moment(selectedDate).format("MMMM-YYYY");
        if (item.month == getMonth) {
          return item;
        }
      } else if (type == "year") {
        let getYear = moment(selectedDate).format("YYYY");
        if (moment(item.month).format("YYYY") == getYear) {
          return item;
        }
      }
    });

    if (type == "all") {
      const demoTarget = targetData?.target?.reduce(function (acc, obj) {
        return acc + obj.demoTarget;
      }, 0);
      const saleTarget = targetData?.target?.reduce(function (acc, obj) {
        return acc + obj.saleTarget;
      }, 0);
      const paymentTarget = targetData?.target?.reduce(function (acc, obj) {
        return acc + obj.paymentTarget;
      }, 0);

      const achievedSaleData = statsData?.totalQuotes?.filter((item) => {
        if (item.paymentStatus == "succeeded" && item.status == 1) {
          return item;
        }
      });

      setTargetGraphValues({
        ...targetGraphValues,
        saleTarget,
        demoTarget,
        paymentTarget,
        achievedSaleData: achievedSaleData?.length,
      });
    } else {
      const demoTarget = filterData?.reduce(function (acc, obj) {
        return acc + obj.demoTarget;
      }, 0);
      const saleTarget = filterData?.reduce(function (acc, obj) {
        return acc + obj.saleTarget;
      }, 0);
      const paymentTarget = filterData?.reduce(function (acc, obj) {
        return acc + obj.paymentTarget;
      }, 0);

      const achievedSaleData = statsData?.totalQuotes?.filter((item) => {
        if (
          item.paymentStatus == "succeeded" &&
          item.status == 1 &&
          checkCompareDate(item.createdAt)
        ) {
          return item;
        }
      });

      setTargetGraphValues({
        ...targetGraphValues,
        saleTarget,
        demoTarget,
        paymentTarget,
        achievedSaleData: achievedSaleData?.length,
      });
    }
  };
  // =============FILTER BY DATES=============

  useEffect(() => {
    if (type == "week") {
      setSelectedDate(moment().day("Sunday").week(moment().week()).format("YYYY-MM-DD"));
    } else if (type == "month") {
      setSelectedDate(moment().format("YYYY-MM"));
    } else if (type == "year") {
      setSelectedDate(moment().format("YYYY"));
    }
  }, [type]);

  useEffect(() => {
    if (statsData) {
      const filteredData = statsData?.totalQuotes?.filter((item) => {
        if (item.paymentStatus == "succeeded" && item.status == 1) {
          return item;
        }
      });
      if (type == "week") {
        //For Inquiry
        const totalInquiries = statsData.totalInquiries.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalLeads
        const totalLeads = statsData.totalLeads.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalDemos
        const totalDemos = statsData.totalDemos.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalQuotes
        const totalQuotes = statsData.totalQuotes.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        // //For TOTAL PYAMENTS
        const totalPayments = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.amount;
          } else return 0;
        }, 0);

        // //For totalUserLicense
        const totalUserLicense = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.totalUserLicense;
          } else return 0;
        }, 0);

        // ==============GRAPH DATA FILTERED===========
        filterGraphData();

        setFilteredStatsData({
          ...filteredStatsData,
          totalInquiries,
          totalLeads,
          totalDemos,
          totalQuotes,
          saleOverviewData: [],
          totalUserLicense,
          totalPayments,
        });
      } else if (type == "month") {
        //For Inquiry
        const totalInquiries = statsData.totalInquiries.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalLeads
        const totalLeads = statsData.totalLeads.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalDemos
        const totalDemos = statsData.totalDemos.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalQuotes
        const totalQuotes = statsData.totalQuotes.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        // //For TOTAL PYAMENTS
        const totalPayments = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.amount;
          } else return 0;
        }, 0);

        // //For totalUserLicense
        const totalUserLicense = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.totalUserLicense;
          } else return 0;
        }, 0);

        // ==============GRAPH DATA FILTERED===========
        filterGraphData();

        let saleOverviewData = saleOverview(filteredData);
        let salesTargetData = saleTargetData(targetData);
        setFilteredStatsData({
          ...filteredStatsData,
          totalInquiries,
          totalLeads,
          totalDemos,
          totalQuotes,
          salesAchievedData: saleOverviewData,
          salesTargetData,
          totalUserLicense,
          totalPayments,
        });
      } else if (type == "year") {
        // setSelectedDate(moment().format("YYYY-MM"));
        //For Inquiry
        const totalInquiries = statsData.totalInquiries.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalLeads
        const totalLeads = statsData.totalLeads.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalDemos
        const totalDemos = statsData.totalDemos.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        //For totalQuotes
        const totalQuotes = statsData.totalQuotes.filter((item) =>
          checkCompareDate(item.createdAt)
        );

        // //For TOTAL PYAMENTS
        const totalPayments = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.amount;
          } else return 0;
        }, 0);

        // //For totalUserLicense
        const totalUserLicense = totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.totalUserLicense;
          } else return 0;
        }, 0);

        // ==============GRAPH DATA FILTERED===========
        filterGraphData();

        let saleOverviewData = saleOverview(filteredData);
        let salesTargetData = saleTargetData(targetData);
        setFilteredStatsData({
          ...filteredStatsData,
          totalInquiries,
          totalLeads,
          totalDemos,
          totalQuotes,
          salesAchievedData: saleOverviewData,
          salesTargetData,
          totalUserLicense,
          totalPayments,
        });
      } else if (type == "all") {
        // //For TOTAL PYAMENTS
        const totalPayments = statsData?.totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.amount;
          } else return 0;
        }, 0);

        // //For totalUserLicense
        const totalUserLicense = statsData.totalQuotes?.reduce(function (acc, obj) {
          if (obj.paymentStatus == "succeeded" && obj.status == 1) {
            return acc + obj.totalUserLicense;
          } else return 0;
        }, 0);

        // ==============GRAPH DATA FILTERED===========
        filterGraphData();

        // setTargetGraphValues({ ...targetGraphValues, achievedSaleData: filteredData?.length });

        let saleOverviewData = saleOverview(filteredData);
        let salesTargetData = saleTargetData(targetData);
        setFilteredStatsData({
          ...filteredStatsData,
          totalInquiries: statsData.totalInquiries,
          totalLeads: statsData.totalLeads,
          totalDemos: statsData.totalDemos,
          totalQuotes: statsData.totalQuotes,
          salesAchievedData: saleOverviewData,
          salesTargetData,
          totalUserLicense,
          totalPayments,
        });
      }
    }
  }, [statsData, selectedDate, type, targetData]);

  useEffect(() => {
    mutateAsync();
    getKPIData();
  }, []);

  return (
    <div>
      <ContentHeader
        title="Dashboard"
        // onSelectChange={(e) => {
        //   setSelectValue(e);
        // }}
        // dropDownOptions={[
        //   { label: "View All", value: 0 },
        //   { label: "View (created by me)", value: data._id },
        // ]}
        selectWidth={200}
      />

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <SaleDashboardCard
            switchableRangePickerState={(e) => {}}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            type={type}
            setType={setType}
            statsData={filteredStatsData}
            isLoading={isLoading}
          />
        </Col>
      </Row>

      {/* <div style={{ margin: "32px 0" }}>
        <RangePickerWrapper size="large" />
      </div> */}

      <div style={{ margin: "32px 0" }}>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <DonutPlot
              chartContainerStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              isLoading={KPIDataLoading || isLoading}
              heading={` Demos Progress`}
              xField="year"
              yField="value"
              achievedColor="#60d1e0"
              targetColor="#caf8fe"
              percentage={
                targetGraphValues.demoTarget > 0
                  ? Math.round(
                      (filteredStatsData?.totalDemos?.length / targetGraphValues.demoTarget) *
                        100
                    )
                  : 0
              }
              data={[
                {
                  type: "Achieved",
                  value: filteredStatsData?.totalDemos?.length,
                },
                {
                  type: "Target(Monthly)",
                  value: targetGraphValues.demoTarget,
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <DonutPlot
              chartContainerStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              isLoading={KPIDataLoading || isLoading}
              heading={` Amount Progress `}
              xField="year"
              yField="value"
              achievedColor="#4e31aa"
              targetColor="#cabcf7"
              percentage={
                targetGraphValues.paymentTarget > 0
                  ? Math.round(
                      (filteredStatsData?.totalPayments / targetGraphValues.paymentTarget) * 100
                    )
                  : 0
              }
              data={[
                {
                  type: "Achieved",
                  value: filteredStatsData?.totalPayments,
                },
                {
                  type: "Target(Monthly)",
                  value: targetGraphValues.paymentTarget,
                },
              ]}
            />
          </Col>

          <Col span={8}>
            <DonutPlot
              chartContainerStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              isLoading={KPIDataLoading || isLoading}
              heading={` Sales Progress`}
              xField="year"
              yField="value"
              color={"#FF7D7D"}
              achievedColor="#28C76F"
              targetColor="#D4F4E2"
              percentage={
                targetGraphValues.saleTarget > 0
                  ? Math.round(
                      (targetGraphValues.achievedSaleData / targetGraphValues.saleTarget) * 100
                    )
                  : 0
              }
              data={[
                {
                  type: "Achieved",
                  value: targetGraphValues?.achievedSaleData,
                },
                {
                  type: "Target(Monthly)",
                  value: targetGraphValues.saleTarget,
                },
              ]}
            />
          </Col>

          <Col span={24}>
            <SaleColumnPlot
              heading={`Sales Overview`}
              color={"#fb933e"}
              icon={InquiryIcon}
              achievedData={filteredStatsData.salesAchievedData}
              targetData={filteredStatsData.salesTargetData}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;

const mapData = (data) => {
  const dataObject = data.reduce((initial, user) => {
    const createdAt = moment(user.createdAt).format("YYYY-MM-DD");

    if (initial[createdAt]) {
      initial[createdAt] += 1;
    } else {
      initial[createdAt] = 1;
    }

    return initial;
  }, {});

  let graphData = [];

  for (let key in dataObject) {
    graphData.push({
      month: key,
      value: dataObject[key],
    });
  }
  graphData.sort((a, b) => (a.month > b.month ? 1 : -1));
  return graphData;
};

const saleOverview = (data) => {
  let saleOveriewGraphData = [];
  let saleOveriewData = [];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let key in months) {
    let getFilterByMonth = data?.filter(
      (item) => moment(item.createdAt).format("MMM") == months[key]
    );

    saleOveriewData.push({ [months[key]]: getFilterByMonth });
  }

  saleOveriewData.map((item, index) => {
    saleOveriewGraphData.push({
      name: "Achieved",
      month: months[index],
      value: item[months[index]]?.length,
    });
  });
  return saleOveriewGraphData;
};

const saleTargetData = (data) => {
  let saleOveriewData = [];

  let saleOverviewGraph = [
    {
      name: "Target",
      month: "Jan",
      value: 0,
    },
    {
      name: "Target",
      month: "Feb",
      value: 0,
    },
    {
      name: "Target",
      month: "Mar",
      value: 0,
    },
    {
      name: "Target",
      month: "Apr",
      value: 0,
    },
    {
      name: "Target",
      month: "May",
      value: 0,
    },
    {
      name: "Target",
      month: "Jun",
      value: 0,
    },
    {
      name: "Target",
      month: "Jul",
      value: 0,
    },
    {
      name: "Target",
      month: "Aug",
      value: 0,
    },
    {
      name: "Target",
      month: "Sep",
      value: 0,
    },
    {
      name: "Target",
      month: "Oct",
      value: 0,
    },
    {
      name: "Target",
      month: "Nov",
      value: 0,
    },
    {
      name: "Target",
      month: "Dec",
      value: 0,
    },
  ];

  for (let key in saleOverviewGraph) {
    data?.target?.filter((item) => {
      if (moment(item.month).format("MMM") == saleOverviewGraph[key]?.month) {
        console.log("if", item);
        saleOveriewData.push(item);
      }
    });
  }

  saleOverviewGraph.map((item, index) => {
    saleOveriewData.map((item2, index2) => {
      if (
        saleOverviewGraph[index].month == moment(saleOveriewData[index2].month).format("MMM")
      ) {
        saleOverviewGraph[index].value = item2.saleTarget;
      }
    });
  });

  return saleOverviewGraph;
};
