import { useEffect, useState } from "react";
import { Row, Col, Modal, message, Form, Radio, Space } from "antd";
import { ButtonWrapper } from "components/buttons";
import { InputWrapper, SelectWrapper, TextAreaWrapper } from "components/input";
import { useMutation, Mutations, errorHandler } from "apis/config";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ALL_INQUIRY, ALL_LEADS, INQUIRY_IN_MANAGER } from "redux/constants";
import { SearchOutlined } from "@ant-design/icons";
import { FiCheckCircle } from "react-icons/fi";

const initialState = {
  assignValue: "",
};

const AssignToSalepersonModal = (props) => {
  const { title, visible, onCancel, data, inquiryReasonModal, from, idKey } = props;
  const { allSales } = useSelector((state) => state.sales);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({ ...initialState });
  const [tableData, setTableData] = useState([]);

  const { isLoading, mutateAsync } = useMutation(Mutations.assignInquiryManually);

  const handleAssignInquiry = async () => {
    const variables = {
      [idKey]: data._id,
      saleId: state.assignValue,
    };
    try {
      let getResponse = await mutateAsync(variables);
      dispatch({ type: INQUIRY_IN_MANAGER.EDIT_DATA, payload: getResponse.data.data });
      dispatch({ type: ALL_LEADS.EDIT_DATA, payload: getResponse.data.data });

      message.success("Updated Successfully");
      onClose();
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const onClose = () => {
    setState({ ...initialState });
    form.resetFields();
    onCancel();
  };

  const handleSearch = (val) => {
    const filteredOptions = allSales.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  useEffect(() => {
    setTableData(allSales);
    if (allSales && data[from]?.adminId !== "") {
      let getAssignedInquiry = allSales.find((item) => item._id == data[from]?.adminId);
      setState({ ...state, assignValue: getAssignedInquiry._id });
    }
  }, [allSales]);

  return (
    <StyledModal
      title={title}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={700}
    >
      <InputWrapper
        prefix={<SearchOutlined />}
        placeholder="Search saleperson"
        onChange={handleSearch}
      />
      <div className="header">
        <Row justify={"space-between"}>
          <Col span={8}>Full Name</Col>
          <Col span={10}>Email</Col>
          <Col span={4}>Inquiry</Col>
        </Row>
      </div>
      <div className="salesPersonWrapper" span={24}>
        {tableData?.map((item, index) => {
          return (
            <SalesPersonListWrapper
              key={index}
              style={{
                border:
                  state.assignValue == item._id ? "0.5px solid #bdbdfd " : "0.5px solid #fff ",
              }}
            >
              <Row justify={"space-between"}>
                <Col span={8}>
                  <Radio.Group
                    onChange={() => setState({ ...state, assignValue: item._id })}
                    value={state.assignValue}
                  >
                    <Radio value={item._id}>{item.fullName}</Radio>
                  </Radio.Group>
                </Col>
                <Col span={10}>{item.email}</Col>
                <Col span={4}>
                  {item.inquiryCount}{" "}
                  {item._id == data[from].adminId && (
                    <FiCheckCircle
                      title="Already assigned"
                      style={{
                        fontSize: 20,
                        color: "#28c76f",
                        position: "absolute",
                        right: 0,
                      }}
                    />
                  )}
                </Col>
              </Row>
            </SalesPersonListWrapper>
          );
        })}
      </div>

      <ButtonWrapper
        disabled={isLoading || state.assignValue == ""}
        style={{ width: "20%" }}
        htmlType="submit"
        loading={isLoading}
        onClick={() => handleAssignInquiry()}
      >
        Assign
      </ButtonWrapper>
    </StyledModal>
  );
};

export default AssignToSalepersonModal;

const StyledModal = styled(Modal)`
  border-radius: 10;
  .label {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }
  .header {
    margin-top: 20px;
    background-color: #135bed;
    padding: 10px;
    color: #fff;
  }
  .salesPersonWrapper {
    min-height: 300px;
    max-height: 400px;
    overflow: auto;
  }
`;

const SalesPersonListWrapper = styled.div`
  width: 100%;
  background-color: aliceblue;
  padding: 8px;
  margin: 10px 0;
`;
