import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import { useMutation, errorHandler, Queries, Mutations } from "apis/config";
import { message, Tooltip, Form } from "antd";
import { ALL_QUOTES, USER } from "redux/constants";
import styled from "styled-components";
import { HiCheck, HiX } from "react-icons/hi";
import DemoDetailModal from "components/modals/DemoDetailModal";
import { BiCopy } from "react-icons/bi";
import QuoteReasonModal from "components/modals/QuoteReasonModal";
import MultiSwitchWrapper from "components/input/multiSwitchWrapper";
import RegistrationDetailModal from "components/modals/registrationDetailModal";
import { SwitchWrapper } from "components/input";
import { Mutation } from "react-query";

const StyledTable = styled.div`
  .delete-icon {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
  .succeeded {
    background-color: #d4f4e2;
    color: #28c76f;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .pending {
    background-color: #ffecd0;
    color: #ff9a00;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
  .statsics {
    background-color: #fde5e9;
    color: #f55050;
    padding: 5px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 16;
    font-weight: bold;
  }
`;

const DeadLeads = () => {
  const filterRef = useRef(2);
  const monitoringRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.admin);
  const { allUsers } = useSelector((state) => state.users);

  const [registrationModal, toggleRegistrationModal] = useState(false);
  const [quoteRef, setQuoteRef] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState("viewAll");
  const [quoteUpdateModal, setquoteUpdateModal] = useState({
    visible: false,
    title: "",
  });

  // ==================GET ALL USERS==================
  const { mutateAsync: getSaleAllUsers, isLoading: getRegistrationsLoading } = useMutation(
    Queries.getSaleAllUsers
  );
  useEffect(() => {
    getSaleAllUsers()
      .then(({ data }) => {
        dispatch({ type: USER.STORE, payload: data.data });
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  // ===============UPDATE USER STATUS===================
  const { mutateAsync: updateUserById, isLoading: changeStatusLoading } = useMutation(
    Mutations.updateUserById
  );
  const changeUserStatus = async (status, userId) => {
    try {
      await updateUserById({ userId, status });
      message.success("Updated successfully");
      dispatch({ type: USER.CHANGE_STATUS, payload: { userId, status } });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  // ==============FILTER BY KEYWORD==========
  const handleSearch = (val) => {
    const filteredOptions = allUsers.filter((option) => {
      return (
        option.fullName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.country.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.gender.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  // ==============FILTER BY STATUS==========
  useEffect(() => {
    const filteredOptions = allUsers.filter((option) => {
      if (option.status == filterByStatus) {
        return option;
      } else if (filterByStatus == "viewAll") {
        return allUsers;
      } else {
        return false;
      }
    });
    setTableData(filteredOptions);
  }, [filterByStatus]);

  useEffect(() => {
    setTableData(allUsers);
  }, [allUsers]);

  const handleFilter = (value) => {
    const filteredData = allUsers.filter((registration) => {
      return registration.userRole == value && registration.status == 2;
    });
    setTableData(filteredData);
  };

  useEffect(() => {
    handleFilter(filterRef.current);
  }, [allUsers]);

  return (
    <StyledTable>
      {quoteUpdateModal.visible && (
        <QuoteReasonModal
          title={`Reason for ${quoteUpdateModal.title}`}
          visible={quoteUpdateModal.visible}
          onCancel={() => {
            setquoteUpdateModal(false);
          }}
          quoteUpdateModal={quoteUpdateModal}
          data={quoteRef}
          showForm={true}
        />
      )}

      <RegistrationDetailModal
        title="Details"
        visible={registrationModal}
        onCancel={() => {
          toggleRegistrationModal(false);
        }}
        data={monitoringRef.current}
        showForm={false}
      />

      <div>
        <ContentHeader
          title="Inactive Clients"
          count={tableData?.length}
          onSearch={(e) => {
            handleSearch(e);
          }}
        />

        <MultiSwitchWrapper
          options={[
            { label: "Company", value: 2 },
            { label: "Individual User", value: 1 },
          ]}
          onChange={(val) => {
            handleFilter(val);
            filterRef.current = val;
          }}
          style={{ margin: "12px 0" }}
        />

        <TableWrapper
          tableData={tableData}
          loading={getRegistrationsLoading}
          showPagination={true}
          columns={
            filterRef.current == 2
              ? [
                  {
                    Header: "Full Name",
                    Cell: ({ original }) => <div>{original.fullName}</div>,
                    sortable: false,
                  },
                  {
                    Header: "Email",
                    Cell: ({ original }) => <div>{original.email}</div>,
                    sortable: false,
                  },
                  {
                    Header: "Phone",
                    Cell: ({ original }) => <div>{original.phoneNumber}</div>,
                    sortable: false,
                  },

                  {
                    Header: "Last Login",
                    Cell: ({ original }) => <div>{original.lastLogin}</div>,
                    sortable: false,
                  },
                  {
                    Header: "Last License Used",
                    Cell: ({ original }) => <div>{original.lastLicenseUsed}</div>,
                    sortable: false,
                  },
                  {
                    Header: "License",
                    Cell: ({ original }) => (
                      <div
                        title="Remaining/Total License"
                        style={{ width: 50, textAlign: "center" }}
                      >
                        {original.remainingLicense}/{original.totalLicense}
                      </div>
                    ),
                    sortable: false,
                  },
                  {
                    Header: "Details",
                    Cell: ({ original }) => {
                      return (
                        <TableButton
                          onClick={() => {
                            monitoringRef.current = original;
                            toggleRegistrationModal(true);
                          }}
                        >
                          view
                        </TableButton>
                      );
                    },
                    sortable: false,
                  },
                  // {
                  //   Header: "Resale",
                  //   Cell: ({ original }) => {
                  //     return (
                  //       <TableButton
                  //         onClick={() => {
                  //           monitoringRef.current = original;
                  //           setConfirmationModalShow(true);
                  //         }}
                  //       >
                  //         Resale
                  //       </TableButton>
                  //     );
                  //   },
                  //   sortable: false,
                  // },
                  {
                    Header: "Actions",
                    Cell: ({ original }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <SwitchWrapper
                          key={original._id}
                          loading={
                            monitoringRef.current === original._id && changeStatusLoading
                          }
                          checked={original.status === 1 ? true : false}
                          style={{ marginRight: 10 }}
                          onChange={(e) => {
                            const status = e ? 1 : 2;
                            monitoringRef.current = original._id;
                            changeUserStatus(status, original._id);
                          }}
                        />
                        {original.status === 1 ? "Active" : "In-Active"}
                      </div>
                    ),
                    sortable: false,
                  },
                ]
              : [
                  {
                    Header: "Full Name",
                    Cell: ({ original }) => <div>{original.fullName}</div>,
                    sortable: false,
                  },
                  {
                    Header: "Email",
                    Cell: ({ original }) => <div>{original.email}</div>,
                    sortable: false,
                  },
                  {
                    Header: "Phone",
                    Cell: ({ original }) => <div>{original.phoneNumber}</div>,
                    sortable: false,
                  },

                  {
                    Header: "Last Login",
                    Cell: ({ original }) => <div>{original.lastLogin}</div>,
                    sortable: false,
                  },
                  {
                    Header: "Details",
                    Cell: ({ original }) => {
                      return (
                        <TableButton
                          onClick={() => {
                            monitoringRef.current = original;
                            toggleRegistrationModal(true);
                          }}
                        >
                          view
                        </TableButton>
                      );
                    },
                    sortable: false,
                  },

                  {
                    Header: "Actions",
                    Cell: ({ original }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <SwitchWrapper
                          key={original._id}
                          loading={
                            monitoringRef.current === original._id && changeStatusLoading
                          }
                          checked={original.status === 1 ? true : false}
                          style={{ marginRight: 10 }}
                          onChange={(e) => {
                            const status = e ? 1 : 2;
                            monitoringRef.current = original._id;
                            changeUserStatus(status, original._id);
                          }}
                        />
                        {original.status === 1 ? "Active" : "In-Active"}
                      </div>
                    ),
                    sortable: false,
                  },
                ]
          }
        />
      </div>
    </StyledTable>
  );
};

export default DeadLeads;
